// @ts-check 

import { fabric } from 'fabric-with-gestures' 
import CanvasService from './CanvasService';
import { MapLayer } from './EpicTourMap';

import { CANVAS_USER_EVENTS } from './utils/ETConstants';

/**
 * 
 * primarily looks at:
 * - object are moving/moved for internal(drawer) and external(redux) update
 * - tool subscription, one at a time. with also ability to pass drawing mode settings and such depending on which even tool register too. 
 * 
 */


export const ETCanvasEventEnum = {
    SINGLE_CLICK_TOUCH: 'SINGLE_CLICK_TOUCH',
    DRAWN_PATH: 'DRAWN_PATH',
    DRAWN_RECTANGLE: 'DRAWN_RECTANGLE',
    OBJECT_SELECT: 'OBJECT_SELECT',
    CLEARED: 'CLEARED'
}

export class ToolDelegateInfo{
    constructor(canvasEventType,canvasEventCallback,toolDrawingProperties,cursor){
        this.canvasEventType = canvasEventType;
        this.canvasEventCallback = canvasEventCallback;
        this.toolDrawingProperties = toolDrawingProperties;
        this.cursor = cursor ; //TODO https://stackoverflow.com/questions/41377281/change-drawing-cursor-on-canvas-with-fabric-js
    }
}


class CanvasEventBroadcaster extends CanvasService{
    constructor(fabricCanvas,canvasCoordinatesConverter,onCanvasDrivenMapObjectChange,onCanvasDrivenMapObjectChangeInternal) {
        super(fabricCanvas, canvasCoordinatesConverter) 
        this.onCanvasDrivenMapObjectChange = onCanvasDrivenMapObjectChange;
        this.onCanvasDrivenMapObjectChangeInternal = onCanvasDrivenMapObjectChangeInternal;

        this.toolDelegateInfo = null; 
        this.isObjectMoving = false;
        this.pointDownM = null; 

        this.setup();
    }

    addToolDelegate(toolDelegateInfo){
        this.toolDelegateInfo = toolDelegateInfo ; 
        if(this.toolDelegateInfo.canvasEventType === ETCanvasEventEnum.DRAWN_PATH){
            this.fabricCanvas.isDrawingMode = true;
            this.fabricCanvas.freeDrawingBrush = new fabric['PencilBrush'](this.fabricCanvas);
            Object.assign(this.fabricCanvas.freeDrawingBrush,this.toolDelegateInfo.toolDrawingProperties);
        }else
            this.fabricCanvas.isDrawingMode = false;
    }

    removeToolDelegate(){
        this.toolDelegateInfo = null; 
        this.fabricCanvas.isDrawingMode = false;
    }

    setup() {
        this.fabricCanvas.on({
            // 'selection:cleared': function (e) {
            //     console.log("CanvasEventBroadcaster object:cleared");
            //     for(const eventCallbackForThisEvent of this.getCallbacksForEventType(ETCanvasEventEnum.CLEARED))
            //         eventCallbackForThisEvent(e);
            // }.bind(this),
            'object:moving': function (e) {
                console.log("CanvasEventBroadcaster object:moving");
                this.movingObjectEvent = e;
                this.onCanvasObjectChange(this.movingObjectEvent,true);
             }.bind(this),  
             'mouse:down' : function(e) {
                if(e.button === CANVAS_USER_EVENTS.DESTOP_TOOL_BUTTON){
                    console.log("CanvasEventBroadcaster mouse:down");
                    this.pointDownM = this.canvasCoordinatesConverter.fpxUnitPointToMeterPoint(e.absolutePointer);
                }
             }.bind(this), 
             'path:created': function(e){
                if(this.toolDelegateInfo.canvasEventType === ETCanvasEventEnum.DRAWN_PATH){
                    //convert to meter but keep all the points, delegate can choose to filter 
                    var pathM = e.path.path.map(p=>this.canvasCoordinatesConverter.fpxUnitPointToMeterPoint( {x:p[1],y:p[2]} ));
                    var filteredPathM = pathM.filter( (el,i) =>  (i%10==0 || i == pathM.length-1) );
                    this.toolDelegateInfo.canvasEventCallback(e,filteredPathM);
                    //switch off drawing mode and remove the shape added 
                    this.fabricCanvas.isDrawingMode = false;
                    var objs = this.fabricCanvas.getObjects().filter(o=>o.type==="path" && !("id" in o));
                    //var objs = this.fabricCanvas.getObjects().filter(o=> !('mapCategory' in o) );
                    this.fabricCanvas.remove(...objs);
                    this.fabricCanvas.renderAll();     
                }
             } .bind(this),
            'mouse:up': function (e) {
                console.log("CanvasEventBroadcaster mouse:up move"+(this.movingObjectEvent!=null));
                if (this.movingObjectEvent){
                    //to avoid refreshing redux constontly while moving which itself refresh the drawing... we only inform at the end
                    this.onCanvasObjectChange(this.movingObjectEvent,false);
                    this.movingObjectEvent = null;
                    return ; 
                }else if (e.button === CANVAS_USER_EVENTS.DESTOP_TOOL_BUTTON) {
                    if(!this.toolDelegateInfo)
                        return ;
                    var pointM = this.canvasCoordinatesConverter.fpxUnitPointToMeterPoint(e.absolutePointer);
                    if(this.toolDelegateInfo.canvasEventType === ETCanvasEventEnum.SINGLE_CLICK_TOUCH)
                        this.toolDelegateInfo.canvasEventCallback(e,pointM);
                    else if(this.toolDelegateInfo.canvasEventType === ETCanvasEventEnum.DRAWN_RECTANGLE){
                        var minX = Math.min(pointM.x, this.pointDownM.x);
                        var minY = Math.min(pointM.y, this.pointDownM.y);
                        var width = Math.abs(this.pointDownM.x-pointM.x);
                        var height = Math.abs(this.pointDownM.y-pointM.y);
                        var areaM = {x:minX+width/2,y:minY+height/2,width:width,height:height}; 
                        this.toolDelegateInfo.canvasEventCallback(e,areaM);
                    }
                }
            }.bind(this),      
            'selection:updated': function(e) {
                console.log("CanvasEventBroadcaster selection:updated");
                if(this.toolDelegateInfo?.canvasEventType === ETCanvasEventEnum.OBJECT_SELECT)
                    this.handleSelectionEvent(e);
            }.bind(this), 
            'selection:created': function(e) {
                console.log("CanvasEventBroadcaster selection:created");
                if(this.toolDelegateInfo?.canvasEventType === ETCanvasEventEnum.OBJECT_SELECT)
                    this.handleSelectionEvent(e);
            }.bind(this),                        
        });
    }

    handleSelectionEvent(e){
        //take note of what was clicked but make sure it is not properly selected
        const selectedMapElementId = e.selected.length==1?e.selected[0].id:null; 
        this.fabricCanvas.discardActiveObject();
        this.fabricCanvas.renderAll();

        this.toolDelegateInfo.canvasEventCallback(e,selectedMapElementId);
    }    

    onCanvasObjectChange = (e,internal) => {
        const movedCanvasObject = e.target;
        const changes = {
            x: this.canvasCoordinatesConverter.fpx2m(movedCanvasObject.left),
            y: this.canvasCoordinatesConverter.fpx2m(movedCanvasObject.top)
        }
        let mapObject = this.map.getMapObjectForId(movedCanvasObject.id);
        //TODO where to put that mapLayer thing? we should know just from retrieving from the epicTourMap!
        const mapLayer = new MapLayer(movedCanvasObject.mapCategory,movedCanvasObject.mapSubType);
        const editedMapObject = Object.assign( JSON.parse(JSON.stringify(mapObject)), changes);
        //optimisation , actually game state stuff won't get in redux either
        if(internal)
            this.onCanvasDrivenMapObjectChangeInternal(mapLayer,editedMapObject);
        else
            this.onCanvasDrivenMapObjectChange(mapLayer,editedMapObject);
    }
}

export default CanvasEventBroadcaster;