import { useContext } from 'react';
import SettingsContext from 'src/context/SettingsContext';

//like all hooks, it is only for function based components 

export default function useSettings() {
  const context = useContext(SettingsContext);

  return context;
}
