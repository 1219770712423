//@ts-check

import React from "react";
import { TextField, FormControl, InputLabel, Select, Tooltip, IconButton, Typography, Button } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";

//TODO turn this class into function based components
//long list of args always bad, with component props, order etc doesn't matter, can always extend 
// it is urgent to rework those, start with the textfield

function getCommonStyle(stylingOverrides){
    return Object.assign({
        minWidth:300,
        margin:12
    },stylingOverrides);
}

function getCommonProps(value, changeCallback, refCallback, label){
    var props = {  }; 
    props.key = label ;//+ (value!=null? "CONTROLLED" : "UNCONTROLLED");
    props.onChange = changeCallback;
    props.inputRef = refCallback;
    if (value!=null)//https://stackoverflow.com/questions/31163693/how-do-i-conditionally-add-attributes-to-react-components
        props.value = value;    
    return props;
}

class ETToolUtils {

    static buildStaticToolPanelTitle = (title) => {
        return (<div><Typography variant="h3" gutterBottom>{title}</Typography><hr /></div>);
    }

    static buildDynamicToolPanelTitle = (selected,panelTopic) => {
        return (<div>
            <Typography variant="h3" gutterBottom>
            '{panelTopic}' form - {selected ? "(EDIT selected item)" : "(ADD new item)"} 
            </Typography><hr /></div>);
    }

    static buildButton = (label,clickCallback,stylingOverrides={}) => {
        return (<Button variant="contained" onClick={clickCallback}  style={getCommonStyle(stylingOverrides)}>{label}</Button>);
    }

    static buildIconButton = (icon,tooltipLabel,clickCallback,stylingOverrides={}) => {
        return (<Tooltip title={tooltipLabel}>
                    <IconButton onClick={clickCallback} >{icon}</IconButton>
                </Tooltip>);
    }

    static buildTextfield = (value, changeCallback, refCallback, label, disabled,stylingOverrides={},type="test",inputProps={}) => {
        var props = getCommonProps(value, changeCallback, refCallback, label);
        props.label = label ; 
        return (<TextField disabled={disabled} style={getCommonStyle(stylingOverrides)} variant="outlined" size="small" margin="dense"  {...props} 
            type={type} inputProps={inputProps}
        />); 
    }

    static buildAutocomplete = (value, changeCallback, refCallback, label, options,stylingOverrides={}) => {
        var props = getCommonProps(value, changeCallback, null, label);
        props.label = label ; 
        return (
            <Autocomplete
            {...props} 
            freeSolo            
            options={options}
            
            renderInput={(params) => (
              <TextField label={label} inputRef={refCallback} style={getCommonStyle(stylingOverrides)} {...params} variant="outlined" size="small" margin="dense"  />
            )}
          />            
        );
    }

    static buildOneLevelSelect = (value, changeCallback, refCallback, label, optionsMap,stylingOverrides={}) => {
        //could probably turn the select into a textfield with options group too: https://material-ui.com/components/text-fields/
        var props = getCommonProps(value, changeCallback, refCallback, label);
        return (
            <FormControl style={getCommonStyle(stylingOverrides)}>
                {label?<InputLabel>{label}</InputLabel>:null}
                <Select native {...props} >
                    {Object.keys(optionsMap).map(v=> <option key={v} value={v}>{optionsMap[v]}</option>  )    }
                </Select>
            </FormControl>
        );
    }    

    static buildTwoLevelSelect = (value, changeCallback, refCallback, label, optionsMap,stylingOverrides={}) => {
        var props = getCommonProps(value, changeCallback, refCallback, label);
        var optionGroups = [];
        for (const optionGroupLabel in optionsMap) {
            optionGroups.push((
                <optgroup label={optionGroupLabel} key={optionGroupLabel}>
                    {   Object.keys(optionsMap[optionGroupLabel]).map(v=> <option key={v} value={v}>{optionsMap[optionGroupLabel][v]}</option>  )    }
                </optgroup>
            ));
        }
        return (
            <div><FormControl style={getCommonStyle(stylingOverrides)} >
                {label?<InputLabel >{label}</InputLabel>:null}
                <Select native {...props} >
                    {optionGroups}
                </Select>
            </FormControl></div>
        );
    }

}



export default ETToolUtils;