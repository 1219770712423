import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    paddingTop: 200,
    width: "50%",
    margin: "auto",
    "text-align":"center"
  }
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography variant="body1">
      Copyright &copy;{' '} 2020 - &nbsp;
        <Link
          component="a"
          href="http;//www.optimosoft.com"
        >Optimosoft ltd.</Link>
      </Typography>
      <Typography variant="caption">
        Created with love by the EpicTourStudio.com team
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
