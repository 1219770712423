// @nots-check

import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';


const useStyles = makeStyles(() => ({
  root: {}
}));

function LoginFailureCallbackPage() {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="LoginFailureCallbackPage"
    >
      <div>LoginFailureCallbackPage</div>
    </Page>
  );
}

export default LoginFailureCallbackPage;
