// @ts-check

export default class Game {
    constructor(player){
        this.player = player;
    }

    draw() {
        this.player.draw();
    }

}