
// @ts-check

import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from 'src/actions/epicTourActions'


import { TextField, Typography } from '@material-ui/core';
import ETToolFormDeleteButton from 'src/components/epictour/tools/ETToolFormDeleteButton';
import ET2DPointForm from 'src/components/epictour/tools/ET2DPointForm';
import { MapLayer, EMap2DCategory } from 'src/components/epictour/EpicTourMap';
import { ETCanvasEventEnum, ToolDelegateInfo } from 'src/components/epictour/CanvasEventBroadcaster';
import ETUtils from 'src/components/epictour/utils/ETUtils';
import { MAP_CUSTOM_ITEMS } from 'src/constants'; //handle differently in lib 
import ETToolUtils from './ETToolUtils';



class ETSegueTool extends React.Component {

    constructor(props) {
        super(props);
        this.state = { clickedScene1id: null};
    }

    componentDidMount() {
        if(this.isInSelectionControlledMode())
            return;
        const toolDelegateInfo = new ToolDelegateInfo(ETCanvasEventEnum.OBJECT_SELECT,this.canvasClickCallback.bind(this),null,null);
        // @ts-ignore
        var canvasWrapper = window.epicTourComponent.canvasWrapper;
        canvasWrapper.stopListeningSelectionEvents();
        canvasWrapper.addToolDelegate(toolDelegateInfo);
    }

    componentWillUnmount(){
        if(this.isInSelectionControlledMode())
            return;
        // @ts-ignore
        var canvasWrapper = window.epicTourComponent.canvasWrapper;
        canvasWrapper.removeToolDelegate();
        canvasWrapper.startListeningSelectionEvents();
    }

    isInSelectionControlledMode(){
        return this.props.mapSelectedItem != null ;
    }    

    canvasClickCallback(e,sceneId) {
        if(this.isInSelectionControlledMode())
            return ;
        if(this.props.scenes.find(s=>s.id===sceneId)==null)//not an existing scene
            return ;
        if(sceneId === this.state.clickedScene1id)//destination can't be same as origin
            return ;            

        if(this.state.clickedScene1id == null){
            this.setState({ clickedScene1id: sceneId})
        } else {
            const newMapObject = {scene1id:this.state.clickedScene1id  , scene2id: sceneId};
            this.props.epicTourMapObjectAdd(this.props.mapLayer,newMapObject);
            this.setState({ clickedScene1id: null})
        }
    }

    deleteClickCallback = () => {
        if(this.isInSelectionControlledMode()){
            this.props.epicTourMapObjectRemove(this.props.mapLayer,this.props.mapSelectedItem.id);
        }
    }

    getLabelForScene(sceneId){
        if(sceneId==null)
            return "";
        const scene = this.props.scenes.find(s=>s.id===sceneId);
        return sceneId+" - "+scene.name; 
    }

    render(){
        const scene1id = this.isInSelectionControlledMode()?this.props.mapSelectedItem.scene1id:this.state.clickedScene1id ;
        const scene2id = this.isInSelectionControlledMode()?this.props.mapSelectedItem.scene2id:null;

        const scene1label = this.getLabelForScene(scene1id);
        const scene2label = this.getLabelForScene(scene2id);

        return (
            <div>
                {ETToolUtils.buildDynamicToolPanelTitle(this.isInSelectionControlledMode(),"Segue")}  
                {ETToolUtils.buildTextfield(scene1label, null, null, "Scene #1",true)}<br/>
                {ETToolUtils.buildTextfield(scene2label, null, null, "Scene #2",true)}<br/>
                {this.isInSelectionControlledMode()?<ETToolFormDeleteButton deleteClickCallback={this.deleteClickCallback}/>:null}
            </div>
        );
    }
}
const mapStateToProps = state => {
    return { 
        scenes: state.epicTour.map.scenes,
     };
}


const mapDispatchToProps = dispatch => {
    return {
        epicTourMapObjectAdd: (mapLayer,newObject) => dispatch(actions.epicTourMapObjectAdd(mapLayer,newObject)),
        epicTourMapObjectEdit: (mapLayer,editedObject) => dispatch(actions.epicTourMapObjectEdit(mapLayer,editedObject)),
        epicTourMapObjectRemove: (mapLayer,objectId) => dispatch(actions.epicTourMapObjectRemove(mapLayer,objectId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(ETSegueTool);
