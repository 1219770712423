// @ts-check

import _ from 'lodash';
import { APIS } from 'src/constants';

import AxiosMockAdapter from 'axios-mock-adapter';
import axios from '../utils/axios';



export class AccountMockInstaller {

  install() {
    const userInfo = {
      "sub": "56769957-d0a7-4e2b-aa22-12345",
      "email_verified": "true",
      "email": "pablo@gmail.com",
      "username": "pablo"
    };

    const mock = new AxiosMockAdapter(axios, { delayResponse: 1000 });

    mock.onGet(APIS.OAUTH2_USERINFO).reply((config) => {
      console.log("mocked /oauth2/userInfo")
      const { Authorization } = config.headers;
      if (!Authorization)
        return [401, { message: 'Authorization token missing' }];

      //here, on this part of the mock backend we could verify the token, that sort of things 

      return [200, userInfo];
    });
  }

}


