
import axios from 'axios';

const instance = axios.create();

export let initialSetup = (apiKey)=> {
    instance.defaults.headers.common["x-api-key"] = apiKey;
    console.log("axios.defaults.headers "+JSON.stringify(axios.defaults.headers));
}

export default instance;
