// @ts-check


import React from 'react'

import { AppBar, Toolbar, Button, withStyles, Menu, MenuItem, Typography, Divider } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'    

import { emptyMap } from 'src/components/epictour/EpicTourMap';
import ETUtils from 'src/components/epictour/utils/ETUtils';
import FileSaver from 'file-saver';

import * as actions from 'src/actions/epicTourActions'
import apiService from 'src/services/apiService';


const useStyles = (theme) => ({
    root: {
        //flexGrow: 1,
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      title: {
        //flexGrow: 1,
      },
});

/*

https://stackoverflow.com/questions/58630490/how-to-convert-functional-componenet-to-class-component-in-react-in-material


*/
class StudioMenuBar extends React.Component {
    state = {
        anchorEl: null,
        anchorEl2: null        
    }

    bridge() {
        // @ts-ignore
        return window.epicTourComponent.canvasWrapper.canvasToAframeBridge;
    }

    handleClick = event => this.setState({ anchorEl: event.currentTarget })
    handleClick2 = event => this.setState({ anchorEl2: event.currentTarget })
    handleClose = () => { 
        this.setState({ anchorEl: null,anchorEl2: null  });
    }
    handleCloseAndRun = (callback) => { 
        this.handleClose();
        callback();
    }

    filePush = () => {
        this.handleCloseAndRun( ( async ()=> {
            console.log("this.bridge().sendMessageSyncWorldBack");
            this.bridge().sendMessageSyncWorldBack( (async (world_items)=>{
                console.log(ETUtils.getClassName(this) + " saveFileClickCallback with world_items");
                var mapWithWorld = JSON.parse(JSON.stringify(this.props.map));
                mapWithWorld.world_items = world_items;
                const content = JSON.stringify(mapWithWorld, null, 2);

                const tourId = this.props.match.params.tourId;
                const success = await apiService.postTour(tourId,content);
                //we should also update the map with the world 
                await this.props.epicTourWorldChanged(world_items);
                alert("content pushed and synced succesfully");
            }));
        }).bind(this));
    }

    
    fileNew = () => {
        this.handleCloseAndRun( (()=> {
            const newMap = JSON.parse(JSON.stringify(emptyMap()));
            this.props.onEpicTourMapChanged(newMap,
                this.props.epicTourAppConfig.initialMode,
                this.props.epicTourAppConfig.getInitialVisibilityLayerTreeForMode(this.props.epicTourAppConfig.initialMode));
        }).bind(this));
    }

    fileOpen = () => {
        this.handleCloseAndRun( (()=> {
            this.refInOpenSave.onchange = this.openFileSelectedCallback ; 
            this.refInOpenSave.click();
        }).bind(this));
    }

    fileSave = () => {
        //once i had to close chrome for this to work again so nothing to do with our code...
        // var blob = new Blob(["{}"], {type: "application/json"});
        // FileSaver.saveAs(blob, "mymap.json");
        this.handleCloseAndRun( ( async ()=> {
            this.bridge().sendMessageSyncWorldBack(( async (world_items)=>{
                console.log(ETUtils.getClassName(this) + " saveFileClickCallback with world_items");
                var mapWithWorld = JSON.parse(JSON.stringify(this.props.map));
                mapWithWorld.world_items = world_items;
                const content = JSON.stringify(mapWithWorld, null, 2);
                var blob = new Blob([content], {type: "application/json"});
                FileSaver.saveAs(blob, "mymap.json");
            }));
        }).bind(this));
    }    

    openFileSelectedCallback = () => {
        const file = this.refInOpenSave.files[0];
        var reader = new FileReader();
        reader.onload = function (evt) {
            // @ts-ignore
            var jsonContent = JSON.parse(evt.target.result);
            this.props.onEpicTourMapChanged(jsonContent,
                this.props.epicTourAppConfig.initialMode,
                this.props.epicTourAppConfig.getInitialVisibilityLayerTreeForMode(this.props.epicTourAppConfig.initialMode));
        }.bind(this);
        reader.onerror = function (evt) {
            alert("error  file");
        }
        reader.readAsText(file, "UTF-8");        
    }




    callInspectorAccess = (methodName) => {
        this.handleCloseAndRun( (()=> {
            this.bridge().inspectorAccess.call(methodName); 
        }).bind(this));
    }
  
    importEntityGroup = () => {
        this.handleCloseAndRun( (()=> {
            this.refInOpenSave.onchange = this.openFileSelectedCallbackForInspector ; 
            this.refInOpenSave.click();
        }).bind(this));
    }    
    openFileSelectedCallbackForInspector = () => {
        const file = this.refInOpenSave.files[0];
        var reader = new FileReader();
        reader.onload = function (evt) {
            // @ts-ignore
            var jsonContent = JSON.parse(evt.target.result);
            this.bridge().inspectorAccess.call("importEntity",jsonContent); 
        }.bind(this);
        reader.onerror = function (evt) {
            alert("error  file");
        }
        reader.readAsText(file, "UTF-8");        
    }


    render() {
        const { classes } = this.props;
        const { anchorEl , anchorEl2} = this.state;
        const { location, history } = this.props
        return (
            <div className={classes.root}>
            <AppBar position="static">
                <Toolbar variant="dense">
                <Typography variant="h3" className={classes.title}>Epic Tour Studio - </Typography>

                    <Button color="inherit" onClick={this.handleClick}>File</Button>
                    <Menu
                        id="file-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={this.handleClose}
                    >
    <MenuItem onClick={this.filePush}>Push changes to cloud</MenuItem>
    <Divider />         
    <MenuItem onClick={this.fileOpen}>Import from local file...</MenuItem>
    <MenuItem onClick={this.fileSave}>Export to local file...</MenuItem>
    <Divider />         
    <MenuItem onClick={()=>history.push("/app/dashboard")}>Exit</MenuItem>

                    </Menu>
                    <Button color="inherit" onClick={this.handleClick2}>A-frame</Button>
                    <Menu
                        id="aframe-menu"
                        anchorEl={anchorEl2}
                        keepMounted
                        open={Boolean(anchorEl2)}
                        onClose={this.handleClose}
                    >
    <MenuItem onClick={ ()=>this.callInspectorAccess("openInspector") }>open A-F-Inspector</MenuItem>
    <MenuItem onClick={ ()=>this.callInspectorAccess("closeInspector") }>close A-F-Inspector</MenuItem>
    <Divider />                    
    <MenuItem onClick={ ()=>this.callInspectorAccess("setInsepectorCamToGameCam") }>AFI set game cam</MenuItem>
    <MenuItem onClick={ ()=>this.callInspectorAccess("selectCurrentSceneInInspector") }>AFI select scene</MenuItem>
    <MenuItem onClick={ ()=>this.callInspectorAccess("addNewEntityToCurrentInspectorSelectedTreeNode") }>AFI add new entity</MenuItem>
    <MenuItem onClick={ ()=>this.callInspectorAccess("cloneCurrentlySelectedEntity") }>AFI clone selected entity</MenuItem>
    <MenuItem onClick={ ()=>this.callInspectorAccess("showSelectedEntityState") }>AFI show selected entity state</MenuItem>
    <Divider />
    <MenuItem onClick={ ()=>this.callInspectorAccess("copyEntity") }>copy entity/group</MenuItem>
    <MenuItem onClick={ ()=>this.callInspectorAccess("pasteEntity") }>paste entity/group </MenuItem>
    <MenuItem onClick={ ()=>this.callInspectorAccess("exportEntity") }>export entity/group</MenuItem>
    <MenuItem onClick={ ()=>this.importEntityGroup() }>import entity/group </MenuItem>    
    <MenuItem onClick={ ()=>this.callInspectorAccess("showEntityClipboard") }>show entity/group clipboard</MenuItem>
    <Divider />
    <MenuItem onClick={ ()=>this.callInspectorAccess("copyComponent") }>copy component</MenuItem>
    <MenuItem onClick={ ()=>this.callInspectorAccess("pasteComponent") }>paste component </MenuItem>
    <MenuItem onClick={ ()=>this.callInspectorAccess("showComponentClipboard") }>show component clipboard</MenuItem>
    <Divider />    
    <MenuItem onClick={ ()=>this.callInspectorAccess("saveCurrentlySelectedMSAcoordinatesForCurrentScene") }>AFI MSA save</MenuItem>
    <MenuItem onClick={ ()=>this.callInspectorAccess("setCurrentlySelectedMSAcoordinatesToDefaults") }>AFI MSA init</MenuItem>
    <Divider />
    <MenuItem onClick={ ()=>this.callInspectorAccess("setGameCameraFovTo80") }>set game cam FOV to 80°</MenuItem>
    <MenuItem onClick={ ()=>this.callInspectorAccess("setGameCameraFovTo135") }>set game cam FOV to 135°</MenuItem>
    <MenuItem onClick={ ()=>this.callInspectorAccess("setGameCameraFovToX") }>set game cam FOV to...</MenuItem>
                    </Menu>

                </Toolbar>
            </AppBar>
            <input
                    style={{ display: 'none' }}
                    type="file"
                    ref={c => (this.refInOpenSave = c)}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        map: state.epicTour.map,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onEpicTourMapChanged: (newMap, newMode, allLayersVisibleState) => dispatch(actions.epicTourMapChanged(newMap, newMode, allLayersVisibleState)),  
        epicTourWorldChanged: (world_items) => dispatch(actions.epicTourWorldChanged(world_items)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(withRouter(StudioMenuBar)));