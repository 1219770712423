// @ts-check

import ETCanvasUtils from "../utils/ETCanvasUtils";
import ETUtils from "../utils/ETUtils";


export const EInteractivity= {
    NONE: 'NONE',
    CLICK: 'CLICK',
    SELECTION: 'SELECTION',
    POSITION: 'POSITION',
    ALL: 'ALL',
}


/**
 * contains standard behaviour for all entities such as access to canvas
 * handling of selectability/editability/visibility
 * 
 * 
 */
export default class MapEntity {

    constructor(entityType,entityId,contextAccess,zindex) {
        this.entityType = entityType;
        this.entityId = entityId;
        this.contextAccess = contextAccess;
        this.zindex = zindex; 

        //optional behaviour composition
        this.hoveringBehvaiour = null; 
        this.movingBehvaiour = null; 
    }

    //can be used for things such as visibility or position update because a scene is being moved
    getCanvasGroup(){
        this.contextAccess.canvasAccess.getGroupFor(this.entityType,this.entityId)
    }

    /**
     * contains common behaviour such as refreshing entities that depend on this instance
     * 
     */
    draw() {
        var newGroup = this.drawGroup();
        if(newGroup==null){
            //e.g.: player has no scene since there is none at first. 
            //but we still have to delete it if it goes from something to nothing (e.g.: deleted the player's location )
            this.contextAccess.canvasAccess.swapGroupFor(this.entityType,this.entityId,null);
            return ;
        }
        const cn = ETUtils.getClassName(this);
        const transientLayerConfig = this.contextAccess.config.transientLayersConfig[cn].modeConfig;
        ETCanvasUtils.updateEditabilityOneCanvasObject(newGroup,transientLayerConfig); 
        this.contextAccess.canvasAccess.swapGroupFor(this.entityType,this.entityId,newGroup)
    }
    drawGroup(){
        //to be overridden 
    }

    setVisibility(visible){
        // theorically accesible from outside, redux-induced changes etc. 
        // @ts-ignore
        this.getCanvasGroup().opacity = visible?1:0;
    }
}