// @ts-check

import React, { Fragment, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { setUserData, logout } from 'src/actions/accountActions';
import authService from 'src/services/authService';

/**
 * this 'page' is the post-aws-login page which capture the access-token from callback-url fragment, 
 * 'complete' the login by making a call to /profile, saving it all then redirecting to proper app page 
 */


function LoginSuccessCallbackPage() {
    //to trigger redux action from function-based component 
    const dispatch = useDispatch();
    const history = useHistory();

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        const completeAuthentication = async () => {
            try {
                //get accessToken from URL and save it/set it on interceptors
                var fragment = window.location.hash.substr(1);
                var accessToken = fragment.split("&")[1].split("=")[1];
                console.log("LoginSuccessCallbackPage for local you could use http://localhost:3000/login-success#a=1&access_token="+accessToken+" -");
                authService.setSession(accessToken);

                //use it to get the profile and add that profile to redux 
                console.log("LoginSuccessCallbackPage loginInWithToken ");
                const user = await authService.loginInWithToken();
                console.log("LoginSuccessCallbackPage setUserData ");
                await dispatch(setUserData(user));

                //finally redirect to the dashboard 
                console.log("LoginSuccessCallbackPage history.push('/app');");
                history.push('/app');
            } catch (error) {
                console.log("LoginSuccessCallbackPage history.push('/login-failure'); "+error);
                history.push('/login-failure');
            }
        };
        completeAuthentication();
    }, [dispatch]);

    return (
        <Fragment />
    );
}

export default LoginSuccessCallbackPage;
