// @ts-check 

import { fabric } from 'fabric-with-gestures'

import CanvasService from './CanvasService'
import ETDrawer from './drawers/ETDrawer'
import ETSceneDrawer from './drawers/ETSceneDrawer'
import ETGridDrawer from './drawers/ETGridDrawer'

import { EMap2DCategory, MapLayer } from './EpicTourMap'
import { ELayerEditability } from './EpicTourAppConfig'
import ETCanvasUtils from './utils/ETCanvasUtils'

/**
 * this class draws the map, including background and optional grid 
 * 
 * when something is added/removed, things are removed and drawn 
 * 
 * when the zoom/selection changes, things are updated for color/stroke changes 
 * 
 * might later contain some SceneDrawer etc, themselves taking a custom style and using utils
 * that are already compatible with zoomRedraw/selectionRedraw
 */

class CanvasMapDrawer extends CanvasService{
    constructor(fabricCanvas, canvasCoordinatesConverter,appConfig,canvasResourceManager) {
        super(fabricCanvas, canvasCoordinatesConverter) ;
        this.appConfig = appConfig;
        this.canvasResourceManager = canvasResourceManager ;
        this.currentMode = null;
        this.currentTool = null;
        this.currentImmutableGameState = null;
    }

    /**
     * @param {MapLayer} mapLayer - A layer of the map
     * @return {ETDrawer} - the corresponding drawer for this map layer
     */
    getDrawer(mapLayer) {
        var drawerClass = this.appConfig.getDrawerClassForLayer(mapLayer);
        return drawerClass==null?null:new drawerClass(this.canvasCoordinatesConverter,mapLayer.mapCategory,mapLayer.mapSubType,this.canvasResourceManager,this.currentImmutableGameState  );
    }

    /**
     * called everytime something is changed in the map
     * 
     * remove and re-add all
     */
    draw(currentMode,currentTool) {
        this.currentMode = currentMode;
        this.currentTool = currentTool;

        //this.fabricCanvas.clear();
        this.fabricCanvas.backgroundColor = "#000";

        var objs = this.fabricCanvas.getObjects();
        this.fabricCanvas.remove(...objs);

        //this.testDraw();
        if(!this.map)
            return;

        for(const mapLayer of this.map.getCurrentMapLayers())
            for (var mapObject of this.map.getMapObjectsForLayer(mapLayer))
                this.addNewCanvasObjectsForNewMapObject(mapLayer,mapObject);
    }

    redrawLayer(mapLayer){
        var cavasObjects = this.getCanvasObjectsForLayer(mapLayer.mapCategory,mapLayer.mapSubType)
        this.fabricCanvas.remove(...cavasObjects);
        for (var mapObject of this.map.getMapObjectsForLayer(mapLayer))
            this.addNewCanvasObjectsForNewMapObject(mapLayer,mapObject);
    }

    addNewCanvasObjectsForNewMapObject(mapLayer,mapObject){
        //get drawer and layer config for the layer this new object belong to
        const drawer = this.getDrawer(mapLayer);
        if(!drawer)
            return; 
        const layerModeConfig = this.appConfig.getModeConfigForLayerAndMode(mapLayer,this.currentMode);

        //draw the corresponding new canvas object 
        var newCanvasObjects = drawer.draw(mapObject,this.map.getRelatedMapObjects(mapObject));

        //make sure they are in line with current mode/tool and also apply layer-wide settings such as editability
        drawer.updateStylingOnContextChange(mapObject,newCanvasObjects,false,this.currentMode,this.currentTool);
        if(this.zoomLevel)
            drawer.updateOnZoomChange(mapObject,newCanvasObjects,this.zoomLevel,this.meterShown,this.fPx2canvasPx);

        //we need to respect zindex 
        const insertPosition = this.findInsertPosition(layerModeConfig.zindex);
        console.log("inserting ("+layerModeConfig.zindex+") "+mapLayer.mapCategory+"."+mapLayer.mapSubType+"."+mapObject.id+" at "+insertPosition+" / "+this.fabricCanvas.getObjects().length);
        for (let index = 0; index < newCanvasObjects.length; index++) {
            const newCanvasObject = newCanvasObjects[index];
            ETCanvasUtils.updateEditabilityOneCanvasObject(newCanvasObject,layerModeConfig);
            if(insertPosition==-1)
                this.fabricCanvas.add(newCanvasObject);
            else
                this.fabricCanvas.insertAt(newCanvasObject,insertPosition+index);
        }
    }

    findInsertPosition(zindex){
        var objects = this.fabricCanvas.getObjects();
        for (let index = 0; index < objects.length; index++) {
            if(objects[index].et_z_index > zindex)
                return index;
        }
        return -1;
    }

    updateOneMapLayerItemChange(mapObjectId,mapLayer,internalEditedMapObject){
        const mapObject = this.map.getMapObjectForId(mapObjectId);
        const canvasObjects = super.getCanvasObjectsForId(mapObjectId);

        if(mapObject==null)//deletion, just remove corresponding objects from Canvas
            this.fabricCanvas.remove(...canvasObjects);
        else if (canvasObjects.length ==0){//addition
            this.addNewCanvasObjectsForNewMapObject(mapLayer,mapObject);
        } else { //edition, trigger a redraw
            const drawer = this.getDrawer(mapLayer);

            //related objects, for examples a segue needs to know the location of the scenes it depends on 
            let relatedObjects = this.map.getRelatedMapObjects(mapObject);
            //note in case of internal-object-move-triggered-update, we must used the updated scene!
            if(internalEditedMapObject){
                for(const key in relatedObjects)
                    if(relatedObjects[key].id === internalEditedMapObject.id)
                        relatedObjects[key] = internalEditedMapObject ; 
            }
            drawer.redrawOnMapObjectChange(internalEditedMapObject?internalEditedMapObject:mapObject,relatedObjects,canvasObjects);
            if(this.zoomLevel)
                drawer.updateOnZoomChange(internalEditedMapObject?internalEditedMapObject:mapObject,canvasObjects,this.zoomLevel,this.meterShown,this.fPx2canvasPx);
            //also redraw objects that reference this one, for example segues associated with a scene
            const objectsWithReferences = this.map.getMapObjectsWithReferencesTo(mapObjectId);
            for(const objectWithReference of objectsWithReferences){
                console.log("cascade from "+mapObjectId+ ", also update "+objectWithReference.mapLayer.mapCategory +" "+objectWithReference.mapObject.id);
                if (!objectWithReference.mapObject.id)//don't update things like background for example 
                   continue;
                this.updateOneMapLayerItemChange(objectWithReference.mapObject.id,objectWithReference.mapLayer,internalEditedMapObject);
            }
        }
        this.fabricCanvas.renderAll();
    }


    updateAllMapLayersAfterModeOrToolChange(currentMode,currentTool){
        this.currentMode = currentMode;
        this.currentTool = currentTool;

        for(const mapLayer of this.map.getCurrentMapLayers()){
            const drawer = this.getDrawer(mapLayer);
            if(!drawer)
                continue; 
            var mapObjects = this.map.getMapObjectsForLayer(mapLayer);
            for (var mapObject of mapObjects){
                var canvasObjects = null;
                if("id" in mapObject)
                    canvasObjects=super.getCanvasObjectsForId(mapObject.id);
                else//if no id, we take the whole layer (e.g.: grid)
                    canvasObjects = super.getCanvasObjectsForLayer(mapLayer.mapCategory,mapLayer.mapSubType);
                if(canvasObjects.length==0)
                    continue;                
                drawer.updateStylingOnContextChange(mapObject,canvasObjects,false,this.currentMode,this.currentTool);
                drawer.updateOnZoomChange(mapObject,canvasObjects,this.zoomLevel,this.meterShown,this.fPx2canvasPx);
            }
        }
        this.updateLayersEditability();
        this.fabricCanvas.renderAll();
    }

    updateMapObjectsAfterSelectionChange(oldMapObjectId,mapObjectId) {
        this.updateOneMapObjectAfterSelectionChange(oldMapObjectId,false);
        this.updateOneMapObjectAfterSelectionChange(mapObjectId,true);
    }
    
    updateOneMapObjectAfterSelectionChange(mapObjectId,selected) {
        if(mapObjectId==null)
            return;
        const mapObject = this.map.getMapObjectForId(mapObjectId) ; 
        var canvasObjects=super.getCanvasObjectsForId(mapObjectId);
        if(mapObject==null || canvasObjects.length==0)
            return;
        const mapLayer = new MapLayer(canvasObjects[0].mapCategory,canvasObjects[0].mapSubType) ; 
        const drawer = this.getDrawer(mapLayer);
        drawer.updateStylingOnContextChange(mapObject,canvasObjects,selected,this.currentMode,this.currentTool);
        drawer.updateOnZoomChange(mapObject,canvasObjects,this.zoomLevel,this.meterShown,this.fPx2canvasPx);
        this.fabricCanvas.renderAll();        
    }


    //visibility is separate from editability etc since visibility state can be change and is managed in redux 

    updateShownLayers(shownLayers){
        this.updateAllLayersUsingTreeBasedFunction(shownLayers,(canvasObject,show) => { this.updateShownLayersOneCanvasObject(canvasObject,show); } );
    }
    updateShownLayersOneCanvasObject(canvasObject,show){
         canvasObject.opacity = show?canvasObject.savedNormalOpacity:0 ; 
         //canvasObject.selectable = show?true:false;//should refered to savedSelectability! - make the background selectable lol 
    }

    updateLayersEditability(){
        this.updateAllLayersUsingFunction( (canvasObject,mapLayer) => { 
            const layerModeConfig = this.appConfig.getModeConfigForLayerAndMode(mapLayer,this.currentMode);
            ETCanvasUtils.updateEditabilityOneCanvasObject(canvasObject,layerModeConfig); 
        } );
    }


    updateAllMapLayersAfterZoomChange(zoomLevel,meterShown,fPx2canvasPx) {
        this.zoomLevel = zoomLevel;
        this.meterShown = meterShown;
        this.fPx2canvasPx = fPx2canvasPx;
        for(const mapLayer of this.map.getCurrentMapLayers()){
            const drawer = this.getDrawer(mapLayer);
            if(!drawer)
                continue; 
            var mapObjects = this.map.getMapObjectsForLayer(mapLayer);
            for (var mapObject of mapObjects){
                var canvasObjects = null;
                if("id" in mapObject)
                    canvasObjects=super.getCanvasObjectsForId(mapObject.id);
                else//if no id, we take the whole layer (e.g.: grid)
                    canvasObjects = super.getCanvasObjectsForLayer(mapLayer.mapCategory,mapLayer.mapSubType);
                if(canvasObjects.length==0)
                    continue;
                drawer.updateOnZoomChange(mapObject,canvasObjects,zoomLevel,meterShown,fPx2canvasPx);
            }
        }
        this.fabricCanvas.renderAll();     
    }

    testDraw() {
        // also, to see things quickly at anytime
        // window.epicTourComponent.canvasWrapper.fabricCanvas._objects[27]
        // JSON.stringify(window.epicTourComponent.canvasWrapper.mapContent.items[2].path)
        // JSON.stringify(obj, undefined, 4); and swap directly here

        var shapeWidth = this.fabricCanvas.getWidth() / 2;
        console.log("drawing test with shapeWidth " + shapeWidth);
        this.fabricCanvas.add(new fabric.Rect({
            top: 0,
            left: shapeWidth,
            width: shapeWidth,
            height: shapeWidth,
            fill: 'red'
        }));
        this.fabricCanvas.add(new fabric.Rect({
            top: shapeWidth,
            left: shapeWidth,
            width: shapeWidth,
            height: shapeWidth,
            fill: 'green'
        }));
    }

    updateAllLayersUsingFunction(canvasObjectUpdateFunction){
        for(const mapLayer of this.map.getCurrentMapLayers()){
            for (var canvasObject of super.getCanvasObjectsForLayer(mapLayer.mapCategory,mapLayer.mapSubType))
                canvasObjectUpdateFunction(canvasObject,mapLayer);
        }
        this.fabricCanvas.renderAll();
    }    

    updateAllLayersUsingTreeBasedFunction(layerTree,canvasObjectUpdateFunction){
        for(const mapLayer of this.map.getCurrentMapLayers()){
            const layerTreeItem = mapLayer.mapSubType ? layerTree[mapLayer.mapCategory][mapLayer.mapSubType] : layerTree[mapLayer.mapCategory];
            var canvasObjects = super.getCanvasObjectsForLayer(mapLayer.mapCategory,mapLayer.mapSubType);
            for (var canvasObject of canvasObjects)
                canvasObjectUpdateFunction(canvasObject,layerTreeItem);
        }
        this.fabricCanvas.renderAll();
    }
}

export default CanvasMapDrawer;