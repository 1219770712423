
// @ts-check 

import { fabric } from 'fabric-with-gestures'

import ETDrawer from './ETDrawer'

class ETGridDrawer extends ETDrawer {

    getStyle() {
        return {
            stroke: '#dddddd',
            strokeWidth: 1,
        }
    }

    buildCanvasObjects(inputObject,relatedMpaObjects) {
        var structuralProperties = {
            type: 'line',
            originX: "center",
            originY: "center",
            lockMovementX: true,
            lockMovementY: true,
            hasControls: false
        };

        const mapWidthMeter = inputObject.play_area_width;
        const mapHeightMeter = inputObject.play_area_height;

        var newCanvasObjects = [];
        for (var i = 0; i <= mapWidthMeter; i++)
            newCanvasObjects.push(this.floorplanDrawLine({ x: i, y: 0 }, { x: i, y: mapHeightMeter }, structuralProperties));
        newCanvasObjects.push(this.floorplanDrawLine({ x: mapWidthMeter, y: 0 }, { x: mapWidthMeter, y: mapHeightMeter }, structuralProperties));
        for (var i = 0; i <= mapHeightMeter; i++)
            newCanvasObjects.push(this.floorplanDrawLine({ x: 0, y: i }, { x: mapWidthMeter, y: i }, structuralProperties));
        newCanvasObjects.push(this.floorplanDrawLine({ x: 0, y: mapHeightMeter }, { x: mapWidthMeter, y: mapHeightMeter }, structuralProperties));
        return newCanvasObjects;
    }

    floorplanDrawLine(point1M, point2M, properties) {
        return new fabric.Line(
            [this.canvasCoordinatesConverter.m2fpx(point1M.x),
            this.canvasCoordinatesConverter.m2fpx(point1M.y),
            this.canvasCoordinatesConverter.m2fpx(point2M.x),
            this.canvasCoordinatesConverter.m2fpx(point2M.y)]
            , properties);
    }

    updateOnZoomChange(inputObject,canvasObjects,zoomLevel,meterShown,fPx2canvasPx){
        canvasObjects.forEach(element => {
            element.set({strokeWidth: 1 * fPx2canvasPx});
        });
    }        
};

export default ETGridDrawer;