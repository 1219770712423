// @ts-check

import React from 'react';
import { connect } from 'react-redux';
import Page from 'src/components/Page';
import { withStyles } from '@material-ui/core/styles';
import EpicTour1View from 'src/components/epictour/EpicTour1View';
import { withRouter } from "react-router";
import ExternalConfig from 'src/utils/ExternalConfig';
import axiosNonApi from 'axios';
import SplashScreen from 'src/components/SplashScreen';
import { ETRootUrls } from 'src/components/epictour/EpicTourAppConfig';

const useStyles = (theme) => ({
    root: {

    }
});

class EscapeGame extends React.Component {

    constructor(props) {
        super(props);
        this.state = {tour:null};
    }

    async componentDidMount(){
        const tourId = this.props.match.params.tourId;
        const existingTourContentResponse = await axiosNonApi.get(this.props.externalConfig.getToursRootUrl()+tourId);
                  
        this.setState({tour:existingTourContentResponse.data});    
    }

    render() {
        const { classes } = this.props;
        const tourFile = this.props.match.params.tourId;
        const tourName =  tourFile.substr(0,tourFile.lastIndexOf('.'));
        const rootUrls = new ETRootUrls(this.props.externalConfig.getAssetsDynamicRootUrl(),this.props.externalConfig.getAssetsStaticRootUrl(),this.props.externalConfig.getAssetsToursRootUrl())
        return (
            <
                // @ts-ignore
                Page
                className={classes.root}
                title={tourName}
            >
                {this.state.tour==null?<SplashScreen/>:<EpicTour1View rootUrls={rootUrls} externalConfig={this.props.externalConfig} tour={this.state.tour}/>}
            </Page>
        );
    }
}

const mapStateToProps = state => {
    return {
        externalConfig:new ExternalConfig(state.externalConfig),
    };
}

const mapDispatchToProps = dispatch => {
    return {

     }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(withRouter(EscapeGame)));