// @ts-check
import React, { useRef, useState } from 'react';
import { E3DTOOLMODE, Tool3D } from './Tool3D';
import { ETTwoLevelSelect } from 'src/components/form/CommonFormComponents';
import { PROTECTION_TYPES_OPTIONSMAP } from 'src/constants';

const DEFAULT_PROTECTION_TYPE = "CAM1";

export const Protection3DTool = () => {
    const [protectionType, setProtectionType] = useState(DEFAULT_PROTECTION_TYPE);
    const protectionTypeRef = useRef(DEFAULT_PROTECTION_TYPE);
    const changeCallback = (e)=>{
        // @ts-ignore
        setProtectionType(protectionTypeRef.current.value);
    };
    return (<Tool3D componentType="climbing-protection" mode={E3DTOOLMODE.SINGLE_ADD_SINGLE_POINT} currentRecord={ {protectionType:protectionType} }>
        <ETTwoLevelSelect label="Protection/Gear Type" value={protectionType} changeCallback={changeCallback} refCallback={protectionTypeRef} optionsMap={PROTECTION_TYPES_OPTIONSMAP}/>
    </Tool3D>);
};
