// @ts-check

import apiService from "src/services/apiService";
import ExternalConfig from "src/utils/ExternalConfig";





export const CLOUDFS_LOAD_DYNAMICASSETSANDTOURFOLDERS = '@cloudfs/CLOUDFS_LOAD_DYNAMICASSETSANDTOURFOLDERS';
export const CLOUDFS_LOAD_TOURASSETS= '@cloudfs/CLOUDFS_LOAD_TOURASSETS';

/**
 * @param {ExternalConfig} externalConfig 
 */
export function loadDynamicAssetsAndTourFolders(externalConfig) {
  return async (dispatch) => {
    var dynamicAssets = await apiService.getFileListings(externalConfig.getAssetsDynamicRelativePath(),true,true,false);    
    var tourFolders = await apiService.getFileListings(externalConfig.getAssetsToursRelativePath(),false,false,true);   
      dispatch({
        type: CLOUDFS_LOAD_DYNAMICASSETSANDTOURFOLDERS,
        payload: {
            dynamicAssets,
            tourFolders
        }
      });
  };
}

/**
 * @param {ExternalConfig} externalConfig 
 * @param {string} tourFolder 
 */
export function loadTourAssets(externalConfig,tourFolder) {
    return async (dispatch) => {
      var tourAssets= await apiService.getFileListings(externalConfig.getAssetsToursRelativePath()+tourFolder,false,true,false);   
        dispatch({
          type: CLOUDFS_LOAD_TOURASSETS,
          payload: {
              tourAssets
          }
        });
    };
  }