
// @ts-check

export class MapCategoryInfo {
    constructor(name,subTypes,fieldName){
        this.name = name ;
        this.subTypes = subTypes ;
        this.fieldName = fieldName;
    }
}

export const EMap2DCategory = {
    BACKGROUND: new MapCategoryInfo('BACKGROUND',false,"floorplan"),
    GRID: new MapCategoryInfo('GRID',false,"floorplan"),
    SCENE: new MapCategoryInfo('SCENE',false,"scenes"),
    SEGUE: new MapCategoryInfo('SEGUE',false,"segues"),
//    AREAS: new MapCategoryInfo('AREAS',true,"areas"),
//    PATHS: new MapCategoryInfo('PATHS',true,"paths"),
    ITEMS: new MapCategoryInfo('ITEMS',true,"items")
}

export class MapLayer{
    constructor(mapCategory,mapSubType){
        this.mapCategory = mapCategory;
        this.mapSubType = mapSubType; 
    }   
}

class EpicTourMap{
    constructor(mapData){
        this.setMapData(mapData);
    }

    static emptyMap() {
        return new EpicTourMap({
            "meta":{
                "name":"my new map",
                "tourAssetsRelativePath":null,
                "dynamicAssetReferences":[]                
            },
            "floorplan":{
              "play_area_width":10,
              "play_area_height":10,
              "background_photo_resource_location":null,
              "orientation":"TOPVIEW",
              "global_panoramic_y_rotation":0,
              "initial_scene":null,
              "initial_y_rotation":0
            },
            "scenes":[],
            "segues":[],
            "world_items":{
                "id": "map",
                "components": [],
                "children": []
            },
            "items":[]
        });
    }

    setMapData(mapData){
        //Object.assign(this,mapData); 
        this.meta = mapData.meta;
        this.floorplan = mapData.floorplan;
        this.scenes = mapData.scenes;
        this.segues = mapData.segues;
        this.world_items = mapData.world_items;
        this.items = mapData.items;
    }

    setFloorplan(floorplan){
        this.floorplan = floorplan;
    }

    setScenes(scenes){
        this.scenes = scenes;
    }

    setSegues(segues){
        this.segues = segues;
    }

    setItems(items){
        this.items = items;
    }

    setWorldItems(world_items){
        this.world_items = world_items;
    }    

    isTourAssetPhotoUsed(filename){
        return this.scenes.find(scene => scene.panoramic_photo === filename) || this.floorplan.background_photo_resource_location === filename ; 
    }    

    isPointInPlayArea(pointInMeter){
        //console.log(this);
        return pointInMeter.x >=0 && pointInMeter.x <= this.floorplan.play_area_width && pointInMeter.y >=0 && pointInMeter.y <= this.floorplan.play_area_height ; 
    }   

    getUniqueSubTypesLayers(items,category){
        const subTypes = items.map(i=>i.subtype)
        const uniqueSubTypes = Array.from(new Set(subTypes))
        const layers = uniqueSubTypes.map(i => new MapLayer(category,i));
        return layers;
    }

    //you can't decide based on the map content, might be many missing 

    getCurrentMapLayers(){
        var layers = [] ;

        //layers = layers.concat(this.getUniqueSubTypesLayers(this.areas,EMap2DCategory.AREAS.name));
        //layers = layers.concat(this.getUniqueSubTypesLayers(this.paths,EMap2DCategory.PATHS.name));
        layers = layers.concat(this.getUniqueSubTypesLayers(this.items,EMap2DCategory.ITEMS.name));
        layers.push(new MapLayer(EMap2DCategory.SCENE.name,null));

        //order here shouldn't matter due to zindex support 
        layers.push(new MapLayer(EMap2DCategory.BACKGROUND.name,null));
        layers.push(new MapLayer(EMap2DCategory.GRID.name,null));
        layers.push(new MapLayer(EMap2DCategory.SEGUE.name,null));
        return layers ; 
    }

    getMapObjectsForLayer(mapLayer){
        switch(mapLayer.mapCategory){
            case EMap2DCategory.BACKGROUND.name:
            case EMap2DCategory.GRID.name:
                return [this.floorplan];

            case EMap2DCategory.SCENE.name: return this.scenes;
            case EMap2DCategory.SEGUE.name: return this.segues;

            // case EMap2DCategory.AREAS.name: return this.areas.filter( i => i.subtype === mapLayer.mapSubType );
            // case EMap2DCategory.PATHS.name: return this.paths.filter( i => i.subtype === mapLayer.mapSubType );
            case EMap2DCategory.ITEMS.name: return this.items.filter( i => i.subtype === mapLayer.mapSubType );
        }
    }

    getMapObjectForId(mapObjectId){
        for(const mapLayer of this.getCurrentMapLayers())
            for(const mapObject of this.getMapObjectsForLayer(mapLayer))
                if(mapObject.id === mapObjectId)
                    return mapObject ; 
        return null; 
    }
    getLayerForMapObject(mapObjectId){
        for(const mapLayer of this.getCurrentMapLayers())
            for(const mapObject of this.getMapObjectsForLayer(mapLayer))
                if(mapObject.id === mapObjectId)
                    return mapLayer ; 
        return null; 
    }     

    getRelatedMapObjects(mapObject){
        let relatedObjects = {};
        for(const key in mapObject)
            if(key.endsWith("id") && key !== "id")
                relatedObjects[key] = this.getMapObjectForId(mapObject[key]);
        return relatedObjects;
    }

    getMapObjectsWithReferencesTo(mapObjectId) {
        let objectWithReferences = [];
        for (const mapLayer of this.getCurrentMapLayers()) {
            for (const mapObject of this.getMapObjectsForLayer(mapLayer))
                for (const key in mapObject)
                    if (key !== "id" && mapObject[key] === mapObjectId)
                        objectWithReferences.push({ mapLayer, mapObject });
        }
        return objectWithReferences;
    }   
}

export default EpicTourMap; 

export const emptyMap = EpicTourMap.emptyMap;