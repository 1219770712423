
// @ts-check

const { fabric } = require("fabric-with-gestures");


export default class CanvasResourceManager{

    constructor(config){
        this.config = config;
        this.imageResources = {};
    }

    getDrawerClassesStaticImagesFilenames(){
        let filenames = [];
        for(const drawerClass of this.config.getAllDrawerClasses()){
            var drawerFilenames = [];
            if(drawerClass!=null){
                drawerFilenames = drawerClass.getStaticImagesNames();
            }
            filenames = filenames.concat(drawerFilenames);
        }
        return filenames ; 
    }

    loadImagesForMapPart(tourAssetsRelativePath,mapPart,loadDrawerStaticContentAsWell,callback){
        let filenames = [];
        JSON.stringify(mapPart, (key, value) => {
          if (key.endsWith('_resource_location'))
            filenames.push({name:value,static:false});
          return value;
        });
        if(loadDrawerStaticContentAsWell){
            filenames = filenames.concat(this.getDrawerClassesStaticImagesFilenames().map( filename => {return {name:filename,static:true} }));
        }
        this.loadImages(tourAssetsRelativePath,filenames,callback);
    }

    loadImages(tourAssetsRelativePath,files,callback){
        let counter = 0;
        for (const file of files) {
            const rootUrl = file.static?this.config.rootUrls.assetsStaticRootUrl:this.config.rootUrls.assetsToursRootUrl+tourAssetsRelativePath;
            const url = rootUrl+file.name;
            console.log("loadImages "+url+"...");
            fabric.Image.fromURL(url, (oImg) => {
                console.log("loadImages++");
                oImg.originalWidth = oImg.width;
                oImg.originalHeight = oImg.height;
                this.imageResources[file.name] = oImg ; 
                counter = counter+1;
                if(counter===files.length){
                    console.log("loadImages DONE");
                    callback();//TODO rewrite... async/await/promise all that 
                }
            });
        }
    }

    getImageResource(filename){
        //return JSON.parse(JSON.stringify(this.imageResources[filename]));
        //return this.imageResources[filename];
        //https://stackoverflow.com/questions/16024940/how-do-i-clone-a-javascript-class-instance
        //sync cloning of fabricjs, we are lucky it seems to work, otherwise, we would have to rework things a bit.
        // for example async/await/promise or just have the rest of the post-drawing steps done in a callback.
        // even with draw happening on many object in loop, there can be the same system as in counter to call postLoopLogic() when done with each object. 
        return Object.assign(Object.create(Object.getPrototypeOf(this.imageResources[filename])), this.imageResources[filename]);
    }

}