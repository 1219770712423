// @ts-check

import React from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import Page from 'src/components/Page';
import { useSelector } from 'react-redux';


const useStyles = makeStyles(() => ({
  root: {}
}));

function HomeView() {
  const classes = useStyles();

    const handleLogin = () =>{
        window.location.href = "https://epictourstudio.auth.eu-west-2.amazoncognito.com/login?response_type=token&client_id=6iu40qvrlaq1qc4mjh5rvr82su&redirect_uri=https://epictourstudio.com/login-success";
    };

    // @ts-ignore
    const externalConfig = useSelector((state) => state.externalConfig);
    const info= JSON.stringify(externalConfig);
  /*  
    <div>{info}</div>
    <iframe id="aframeiframe" scrolling="no" frameBorder="1" width="500" height="500" src="https://epictourstudio.com/static/index.html" allowFullScreen="yes" allowvr="yes">
                <p>Your browser does not support iframes.</p>
    </iframe>
  */
  return (
    <
// @ts-ignore
    Page
      className={classes.root}
      title="Home"
    >
                <Typography
          variant="h3"
          color="textPrimary"
        >
          Epic Tour Studio - Welcome
        </Typography>     

        <Box mt={3}>
        <Typography
            variant="body1"
            color="textPrimary"
        >
            Please register/login to access the Epic Tour Studio tools. 
        </Typography>
        </Box>

        <Button color="primary" 
                onClick={handleLogin}>
                  login
                </Button>
    </Page>
  );
}

export default HomeView;
