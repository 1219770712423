// @ts-check

import MapEntity from "./mapEntity";
import { fabric } from "fabric-with-gestures";

export default class Player extends MapEntity{
    constructor(sceneId,contextAccess){
        super("Player",null,contextAccess,299);
        this.sceneId = sceneId;
        this.angles = [0,0,0]; // vector(3) allowing us to add an hypothetical direction arrow that tracks what happens in 360world and display it on map 
    }

    goToScene(newSceneId,external){
        console.log("canvas player going to "+newSceneId);
        this.sceneId = newSceneId;
        this.draw();
        if(!external)
            this.contextAccess.bridge.sendMessagePlayerMove(newSceneId);
    }

    getStyle(){
        return {
            fill: 'white',
            stroke: 'blue'
        }
    }   

    drawGroup() {
        if(this.sceneId==null)
            return null;
        var scene = this.contextAccess.map.getMapObjectForId(this.sceneId);
        if(scene==null)
            return null;
        var pointFPx = this.contextAccess.canvasCoordinatesConverter.meterPointTofpxUnitPoint(scene);
        var zoomRelatedFactor = this.contextAccess.uiControlsContext.fPx2canvasPx;//to stay constant size

        var structuralProperties = {
            radius: 15 * zoomRelatedFactor,
            strokeWidth: 3 * zoomRelatedFactor           
        }; 
        Object.assign(structuralProperties,this.getStyle());

        // var oImg = this.contextAccess.canvasResourceManager.getImageResource("climber.png");
        // oImg.set({
        //     left:5 * zoomRelatedFactor,
        //     top:5 * zoomRelatedFactor,
        // });
        // oImg.scale((20 * zoomRelatedFactor)/oImg.originalWidth);

        var group =  new fabric.Group([new fabric.Circle(structuralProperties)/*,oImg*/],{
            selectable:false,
            left: pointFPx.x ,
            top: pointFPx.y ,
            originX:"center",
            originY:"center"
        });

//{radius: 10 * fPx2canvasPx, strokeWidth: 2 * fPx2canvasPx})

        // var rect = new fabric.Rect({
        //     left: -100,
        //     top: -100,
        //     width: 100,
        //     height: 75,
        //     fill: 'rgba(255,0,0,0.5)',
        // });
        
        // var circle = new fabric.Circle({
        //     left: 200,
        //     top: 300,
        //     radius: 75,
        //     fill: 'rgba(0,255,0,0.5)',
        // });
        
        // var group = new fabric.Group([ circle, rect ], {
        // originX:"center",
        // originY:"center",           
        //   left: 400,
        //   top: 400
        // });

        return group;
    }
}


