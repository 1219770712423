
// @ts-check
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BridgeFacade } from 'src/components/epictour/CanvasToAframeBridge';

//for now, we don't try to differeentiate SINGLE_ADD_SINGLE_POINT and  SINGLE_ADD_MULTIPLE_POINTS
//to stop, edit mode, the button has to be hit or edit mode closed alltogether, also edit will show redish color/border so it will be obvious 
export const E3DTOOLMODE = {
    SINGLE_ADD_SINGLE_POINT: 'SINGLE_ADD_SINGLE_POINT', //info, symbols, protection
    SINGLE_ADD_MULTIPLE_POINTS: 'SINGLE_ADD_MULTIPLE_POINTS', //climbing route
    MULTIPLE_ADD_SINGLE_POINT: 'MULTIPLE_ADD_SINGLE_POINT',  //chalk 
  };
  


/**
 * @return {BridgeFacade} bridge
 */
function getBridgeContentAdderAccess(){
    // @ts-ignore
    return window.epicTourComponent.canvasWrapper.canvasToAframeBridge.contentAdderAccess;
}

/**
 * This is the base tool on which other tools interracting with aframe content are built
 * - it connects to the aframe bridge
 *  - it sends the new record info anytime it is changed and the ADD button is on 
 *  - it also sends the entity being added and wether click-to-add is a one-off (not for chalk and route )
 * - it handles the ADD button
 * - it contains the subform children group 
 * - when subform event occurs, a handler is called preparing the record in top level and then passed down to tool3d via props or ref 
 * 
 * - for chalk and route, there is no form so no need for a add button, maybe let's add colors just to be consistent 
 * maybe no need for any add button, but there should be a sententce to explain click to add is live and pan is secondary 
 * 
 * 
 * @param {*} props 
 */

export const Tool3D = (props) => {
    const {componentType,mode,currentRecord} = props;

    useEffect(() => {
        console.log("Tool3D useEffect "+JSON.stringify(currentRecord));
        getBridgeContentAdderAccess().call("setToolSupport",componentType,mode,currentRecord);        
        return () => {//unmound
            getBridgeContentAdderAccess().call("unsetToolSupport");
        }
    }/*,[currentRecord]*/);

    return (<div>
        {props.children}
        <hr/>
    </div>);
};

Tool3D.propTypes = {
    componentType: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
    currentRecord: PropTypes.object.isRequired,    
    children: PropTypes.node,
};
