// @ts-check

/* eslint-disable react/no-array-index-key */
import React, {
  lazy,
  Fragment
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';

import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/pages/HomeView';
import EscapeGameStudio from 'src/views/escapegame/EscapeGameStudio';
import EscapeGame from './views/escapegame/EscapeGame';
import LoginFailureCallbackPage from './views/pages/LoginFailureCallbackPage';
import LoginSuccessCallbackPage from './views/pages/LoginSuccessCallbackPage';
import AuthGuard from './components/AuthGuard';
import Dashboard from './views/dashboard/Dashboard';
import DashboardLayout from './layouts/DashboardLayout';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/home" />
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/home',//the anonymous page where we land after logging out from axio-token failure, guard breach or logout button press
        layout: DashboardLayout,   
        component: HomeView
      },  
      {
        path: '/login-success',
        component: LoginSuccessCallbackPage
      },      
      {
        exact: true,
        path: '/login-failure',
        component: LoginFailureCallbackPage
      }, 
      {
        path: '/app',
        guard: AuthGuard,
             
        routes: [
          {
            exact: true,
            path: '/app',
            component: () => <Redirect to="/app/dashboard" />
          },
          {
            exact: true,
            path: '/app/dashboard',
            layout: DashboardLayout,   
            component: Dashboard
          },  
          {
            exact: true,
            path: '/app/escapegame/:tourId',
            component: EscapeGame //to try it 'from studio'...
          },  
          {
            exact: true,
            path: '/app/escapegamestudio/:tourId',
            component: EscapeGameStudio
          }                 
        ]
      }
    ]
  }
];

const renderRoutes = (routes) => (routes ? (
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
