
// @ts-check
import React from 'react';
import { connect } from 'react-redux';
import * as actions from 'src/actions/epicTourActions';
import ExternalConfig from 'src/utils/ExternalConfig';
import EpicTourMap, { EMap2DCategory, MapLayer } from '../EpicTourMap';
import { ORIENTATION } from '../ETConstants';
import ETUtils from '../utils/ETUtils';
import ETToolUtils from './ETToolUtils';

const ORIENTATION_OPTIONSMAP = {
    [ORIENTATION.TOPVIEW]: "Top view",
    [ORIENTATION.SIDEVIEW]: "Side view",
};

const USED_SUFFIX = " *"

class ETFloorplanTool extends React.Component {

    newImageCallback = (event, value) => {
        const newVal = value.replace(USED_SUFFIX,"");
        let editedFloorplan = JSON.parse(JSON.stringify(this.props.floorplan));
        editedFloorplan.background_photo_resource_location = newVal;
        var url = this.props.externalConfig.getAssetsToursRootUrl() + this.props.map.meta.tourAssetsRelativePath + editedFloorplan.background_photo_resource_location;
        console.log(ETUtils.getClassName(this) + " newImageCallback "+url);
        var img = new Image();
        img.onload = function () {
            editedFloorplan.background_photo_pixel_width = img.width;
            editedFloorplan.background_photo_pixel_height = img.height;
            const height2widthRatio = editedFloorplan.background_photo_pixel_height / editedFloorplan.background_photo_pixel_width;
            editedFloorplan.play_area_height = editedFloorplan.play_area_width * height2widthRatio;
            this.props.epicTourMapObjectEdit(editedFloorplan);
        }.bind(this);
        img.src = url;
    }


    floorplanChangeCallback = (e) => {
        let editedFloorplan = JSON.parse(JSON.stringify(this.props.floorplan));
        editedFloorplan.global_panoramic_y_rotation = this.refTfGlobalYRot.value;
        editedFloorplan.orientation = this.refSlOrientation.value;
        editedFloorplan.initial_scene = this.refSlInitialScene.value;
        editedFloorplan.initial_y_rotation = this.refTfInitialYRot.value;
        this.props.epicTourMapObjectEdit(editedFloorplan);        
    }

    widthChangeCallback = (e) => {
        if (e.target.value != '') {
            let editedFloorplan = JSON.parse(JSON.stringify(this.props.floorplan));
            const height2widthRatio = !editedFloorplan.background_photo_pixel_height?1:editedFloorplan.background_photo_pixel_height / editedFloorplan.background_photo_pixel_width;
            editedFloorplan.play_area_width = e.target.value;
            editedFloorplan.play_area_height = editedFloorplan.play_area_width * height2widthRatio;
            this.props.epicTourMapObjectEdit(editedFloorplan);
        }
    }
    render() {
        var scenesOptions = {};
        for (const scene of this.props.map.scenes) 
            scenesOptions[scene.id] = scene.name?scene.name:scene.id;

        var tourAssets = this.props.tourAssets.map(asset=>{
            const used = this.props.map.isTourAssetPhotoUsed(asset);
            return used?asset+USED_SUFFIX:asset;
        });
        var currentPhoto = this.props.floorplan?.background_photo_resource_location;
        if( currentPhoto && this.props.map.isTourAssetPhotoUsed(currentPhoto) )
            currentPhoto=currentPhoto+USED_SUFFIX;

        return (
            <div>
                {ETToolUtils.buildStaticToolPanelTitle("Floorplans configuration")}
                {/*ETToolUtils.buildTextfield(this.props.meta?.name, (e) => this.nameChangeCallback(e), null, "Current 2d/3d map name",false)*/}<br/>
                {ETToolUtils.buildTextfield(this.props.floorplan?.play_area_width, (e) => this.widthChangeCallback(e), null, "Play-area Width (meter)",false)}<br/>
                {ETToolUtils.buildTextfield(ETUtils.getMeterValueAsString(this.props.floorplan?.play_area_height), null, null, "Play-area Height (meter)",true)}<br/>
                {ETToolUtils.buildOneLevelSelect(this.props.floorplan?.orientation, this.floorplanChangeCallback, c => (this.refSlOrientation = c), "Plan orientation", ORIENTATION_OPTIONSMAP)}<br/>
                {ETToolUtils.buildTextfield(""+this.props.floorplan?.global_panoramic_y_rotation, (e) => this.floorplanChangeCallback(e), c => (this.refTfGlobalYRot= c), "Global panoramic Y rotation (°)",false,{},"number",{ type:"number", min: "0", max: "10", step: "1" })}<br/>
                
                {ETToolUtils.buildAutocomplete(currentPhoto, this.newImageCallback, c => (this.refAcBackground = c), "Background photo", tourAssets,{width:300})}<br/>                    
                <hr/>
                {ETToolUtils.buildOneLevelSelect(this.props.floorplan?.initial_scene, this.floorplanChangeCallback, c => (this.refSlInitialScene = c), "Initial scene", scenesOptions)}<br/>
                {ETToolUtils.buildTextfield(""+this.props.floorplan?.initial_y_rotation, (e) => this.floorplanChangeCallback(e), c => (this.refTfInitialYRot= c), "Initial Y rotation (°)",false)}<br/>
                <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    type="file"
                    ref={c => (this.refInUpload = c)}
                    onChange={this.newImageCallback}
                />
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        externalConfig:new ExternalConfig(state.externalConfig),        
        tourAssets: state.cloudFs.tourAssets,
        map: new EpicTourMap(state.epicTour.map),
        floorplan: state.epicTour.map.floorplan
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onEpicTourMapChanged: (newMap, newMode, allLayersVisibleState) => dispatch(actions.epicTourMapChanged(newMap, newMode, allLayersVisibleState)),  
        onEpicTourMapMetaChanged: (newMeta) => dispatch(actions.epicTourMapMetaChanged(newMeta)),        
        epicTourMapObjectEdit: (editedObject) => dispatch(actions.epicTourMapObjectEdit(new MapLayer(EMap2DCategory.BACKGROUND.name, null), editedObject)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ETFloorplanTool);
