// @ts-check

import { ELayerEditability } from "../EpicTourAppConfig";

export default class ETCanvasUtils {

    static updateEditabilityOneCanvasObject(canvasObject,layerConfig){
        canvasObject.et_z_index = layerConfig.zindex;

        if( layerConfig.editability != ELayerEditability.NONE ) { // at least selectable  
            canvasObject.selectable=true;
            canvasObject.hoverCursor="move";
        } else {
            canvasObject.selectable=false;
            canvasObject.hoverCursor="default";
        }

        switch(layerConfig.editability){
            case ELayerEditability.NONE:
                canvasObject.lockScalingX= true;
                canvasObject.lockScalingY= true;
                canvasObject.hasRotatingPoint =false;
                canvasObject.lockMovementX= true;
                canvasObject.lockMovementY= true;
                canvasObject.hasControls= false  ;  
                canvasObject.hasBorders= false;
            break;                        
            case ELayerEditability.SELECTION:
                canvasObject.lockScalingX= true;
                canvasObject.lockScalingY= true;
                canvasObject.hasRotatingPoint =false;
                canvasObject.lockMovementX= true;
                canvasObject.lockMovementY= true;
                canvasObject.hasControls= false  ;  
                canvasObject.hasBorders= true;//handy for debugging
            break;            
            case ELayerEditability.POSITION:
                canvasObject.lockScalingX= true;
                canvasObject.lockScalingY= true;
                canvasObject.hasRotatingPoint =false;
                canvasObject.lockMovementX= false;
                canvasObject.lockMovementY= false;
                canvasObject.hasControls= false;
                canvasObject.hasBorders= true;
            break;
            case ELayerEditability.ALL:
                canvasObject.lockScalingX= false;
                canvasObject.lockScalingY= false;
                canvasObject.hasRotatingPoint =false;
                canvasObject.lockMovementX= false;
                canvasObject.lockMovementY= false;
                canvasObject.hasControls= true;   
                canvasObject.hasBorders= true; 
            break;                        
        }
    }    

}