// @ts-check

import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import epicTourReducer from './epicTourReducer';
import externalConfigReducer from './externalConfigReducer';
import cloudFsReducer from './cloudFsReducer';

const rootReducer = combineReducers({
  account: accountReducer,  
  epicTour: epicTourReducer,
  externalConfig : externalConfigReducer,
  cloudFs:cloudFsReducer,
  form: formReducer
});

export default rootReducer;
