
const initialState = {
    //ssetLocationRootUrl:"def"
};

const externalConfigReducer = (state = initialState, action) => {
    switch (action.type) {
        default: {
          return state;
        }
      }
};

export default externalConfigReducer;
