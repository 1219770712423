// @ts-check

export default class ContextAccess {
    constructor(canvasAccess,uiControlsContext,map,game,canvasCoordinatesConverter,canvasResourceManager,bridge,config){
        this.canvasAccess = canvasAccess;
        this.uiControlsContext = uiControlsContext;
        this.map = map;
        this.game = game;
        this.canvasCoordinatesConverter = canvasCoordinatesConverter;
        this.canvasResourceManager = canvasResourceManager;
        this.bridge = bridge;
        this.config = config; 
    }

}