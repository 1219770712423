// @ts-check

import CanvasMapDrawer from './CanvasMapDrawer';
import GameStateManager from './GameStateManager';
import { MapLayer, EMap2DCategory } from './EpicTourMap';
import { guidGenerator } from 'src/utils/utils';

export const EInterframeMessageType = {
    MSGTYPE_DYNAMIC : "MSGTYPE_DYNAMIC",  

    MSGTYPE_MAPLOADED : "MSGTYPE_MAPLOADED",    
    MSGTYPE_PLAYERMOVE : "MSGTYPE_PLAYERMOVE",
    MSGTYPE_INSPECTOR_SYNCWORLDBACK : "MSGTYPE_INSPECTOR_SYNCWORLDBACK",
       
    MSGTYPE_3DTOOLSET : "MSGTYPE_3DTOOLSET",  
    MSGTYPE_3DTOOLUNSET : "MSGTYPE_3DTOOLUNSET",  
}

export class BridgeFacade {
    constructor(bridge,instanceName){
        this.bridge = bridge; 
        this.instanceName = instanceName;
    }

    call(functionName,...args){
        this.bridge.sendDynamicMessage(this.instanceName,functionName,...args);
    }  
}

//TODO nothing to do with canvas, should be renamed and extracted from canvas
//turned into a singleton that can be imported anywhere in app for interaction/sending/listening/setting iframediv, see IT...react/questionsnotes
export default class CanvasToAframeBridge {

    constructor(aframeIframeElement,player) {
        this.identifier = guidGenerator();
        this.aframeIframeElement = aframeIframeElement;
        this.player = player;
        this.callbacks={};

        this.inspectorAccess = new BridgeFacade(this,"inspectorAccess");
        this.mapServiceAccess = new BridgeFacade(this,"mapServiceAccess");
        this.contentAdderAccess = new BridgeFacade(this,"contentAdderAccess");
        this.contentEraserAccess = new BridgeFacade(this,"contentEraserAccess");

        this.handler = function (e) {
            this.receiveMessage(e);
        }.bind(this);
        window.addEventListener("message", this.handler, false);
    }
    cleanup(){
        console.log("CanvasToAframeBridge cleanup");
        window.removeEventListener("message",this.handler);
    }

    sendMessage = (messageType,payload) => {
        const message = /*JSON.stringify*/({
            messageType:messageType,
            payload:payload
        });
        console.log("canvas "+this.identifier+" SENDING ETMSG " + JSON.stringify(message));
        if(this.aframeIframeElement.contentWindow && this.aframeIframeElement.contentWindow.postMessage)
            this.aframeIframeElement.contentWindow.postMessage(message, '*');
    }

    sendDynamicMessage = (instanceName,methodName,...args) => {
        const payload = {
            instanceName:instanceName,
            methodName:methodName,
            args:args
        };
        this.sendMessage(EInterframeMessageType.MSGTYPE_DYNAMIC,payload);
    }    

    sendMessageNewMapLoaded(newMapContent,rootUrls){
        this.sendMessage(EInterframeMessageType.MSGTYPE_MAPLOADED,{mapData:newMapContent,rootUrls:Object.assign({}, rootUrls)});
    }    
    sendMessagePlayerMove(newSceneId){
        this.sendMessage(EInterframeMessageType.MSGTYPE_PLAYERMOVE,{newSceneId:newSceneId});
    }
    sendMessage3DToolSet(componentType,oneOffAdd,currentRecord){
        this.sendMessage(EInterframeMessageType.MSGTYPE_3DTOOLSET,{componentType:componentType,oneOffAdd:oneOffAdd,currentRecord:currentRecord});
    }
    sendMessage3DToolUnset(){
        this.sendMessage(EInterframeMessageType.MSGTYPE_3DTOOLSET,{});
    }    
    

    sendMessageSyncWorldBack(callback){
        this.callbacks[EInterframeMessageType.MSGTYPE_INSPECTOR_SYNCWORLDBACK] = callback;
        this.sendMessage(EInterframeMessageType.MSGTYPE_INSPECTOR_SYNCWORLDBACK,{});
    }        


    receiveMessage = (e) => {
        var message = e.data;
        if(!message.messageType)
            return;
        console.log("canvas "+this.identifier+" RECEIVING ETMSG " + message.messageType+" - "+JSON.stringify(message));     
        switch(message.messageType) {
            case EInterframeMessageType.MSGTYPE_INSPECTOR_SYNCWORLDBACK:
                console.log("MSGTYPE_INSPECTOR_SYNCWORLDBACK...")
                this.callbacks[EInterframeMessageType.MSGTYPE_INSPECTOR_SYNCWORLDBACK](message.payload).then( ()=>{
                    console.log("MSGTYPE_INSPECTOR_SYNCWORLDBACK ok");
                }).catch( (error) =>{
                    console.error("MSGTYPE_INSPECTOR_SYNCWORLDBACK error");
                    debugger;
                });
                break; 
            case EInterframeMessageType.MSGTYPE_PLAYERMOVE: 
                if(this.player) 
                    this.player.goToScene(message.payload.newSceneId,true);
                break;
        }
    }

}