
// @ts-check 

import { fabric } from 'fabric-with-gestures' 

/**
 * TODO 
 * 
 * this has to be reworked to allow sub-parts. 
 * PersonDrawer should be able to write a few shapes and use some subDrawers like HeadDrawer etc.
 * when updating, it should be passed a tree of canvas object organised the same way
 * once flattened and passed to fabric, the shape subId should follow a path appraoch like head.ear.hair
 * 
 * when styling, the tree of shape is visited and each part has an opportunity to do its styling 
 * there a subpart=>subdrawer that makes that visiting easy. 
 * 
 * mix compositon for shape forming and inheritance for handy styling overrides
 * 
 * but:
 * -when a selection occur, the whole object should be selected in one go. 
 * -when a group selection move, there might be multiple canva move event to be turned into one mapobject edit event for redux
 * 
 * fabricjs has the concept of groups of objects
 */


/**
 * building is about structural properties and is derived only from the one map Object: things like shape, size
 * styling depends on selection,tool,mode and is applied to existing potentially multiple canvas objects. 
 * 
 * yet, it most cases, there is only one canva object and style is static or depend only on selection 
 * 
 */



class ETDrawer {

    constructor(canvasCoordinatesConverter,mapCategory,mapSubType,canvasResourceManager,currentImmutableGameState) {
        this.canvasCoordinatesConverter = canvasCoordinatesConverter ; 
        this.mapCategory = mapCategory;
        this.mapSubType = mapSubType;
        this.canvasResourceManager = canvasResourceManager ;
        this.currentImmutableGameState = currentImmutableGameState;
    }

    draw(inputMapObject,relatedMpaObjects){
        var builtCanvasObjects = this.buildCanvasObjects(inputMapObject,relatedMpaObjects);
        const metaProperties = this.buildMetaProperties(inputMapObject);
        for(let canvasObject of builtCanvasObjects){
            Object.assign(canvasObject,metaProperties);
        }
        return builtCanvasObjects; 
    }

    redrawOnMapObjectChange(inputMapObject,relatedMpaObjects,existingCanvasObjects){
        var builtCanvasObjects = this.buildCanvasObjects(inputMapObject,relatedMpaObjects);
        for (let index = 0; index < existingCanvasObjects.length; index++) {
            Object.assign(existingCanvasObjects[index],builtCanvasObjects[index]);
            //when an object move, we must call this to reset its selectability box https://stackoverflow.com/questions/44502367/fabric-js-object-boudaries-wont-update-after-object-moved-programmatically
            existingCanvasObjects[index].setCoords(); 
            existingCanvasObjects[index].dirty = true; 
        }
    }

    updateStylingOnContextChange(inputMapObject,existingCanvasObjects,isSelected,mode,selectedTool){    
        var newStyleProperties = this.pickStylingProperties(inputMapObject,existingCanvasObjects,isSelected,mode,selectedTool);
        if(Array.isArray(newStyleProperties)){
            for (let index = 0; index < existingCanvasObjects.length; index++) {
                newStyleProperties[index].savedNormalOpacity = newStyleProperties[index].opacity?newStyleProperties[index].opacity:1;        
                newStyleProperties[index].dirty = true; 
                Object.assign(existingCanvasObjects[index],newStyleProperties[index]);
            }
        } else {
            newStyleProperties.savedNormalOpacity = newStyleProperties.opacity?newStyleProperties.opacity:1;        
            newStyleProperties.dirty = true; // for redraw despite cache http://fabricjs.com/fabric-object-caching
            for(var canvasObject of existingCanvasObjects)
                Object.assign(canvasObject,newStyleProperties);
        }
    }

    //override this to build the canvasObject corresponding to the inputMapObject
    buildCanvasObjects(inputMapObject,relatedMpaObjects){
        return [];
    }

    //override this to have a complex styling selector 
    pickStylingProperties(inputMapObject,existingCanvasObjects,isSelected,mode,selectedTool){
        return !isSelected?this.getStyle():Object.assign(this.getStyle(),this.getSelectedStyle());
    }

    static getStaticImagesNames(){
        return [];
    }

    getStyle(){
        return {};   
    }

    getSelectedStyle(){
        return {};   
    }    

    buildMetaProperties(inputMapObject){
        var metaProperties = {};
        metaProperties.id = inputMapObject.id;
        if(inputMapObject.note)
            metaProperties.note = inputMapObject.note;
        metaProperties.mapCategory = this.mapCategory;
        metaProperties.mapSubType = this.mapSubType;
        //mergedProperties.savedNormalOpacity = mergedProperties.opacity?mergedProperties.opacity:1;
        return metaProperties ; 
    }


    /**
     * optional 
     * (method applied to one map object at a time)
     * 
     * gives an opportunity to change look when mode/tool or selection change 
     */
    // updateOnControlChange(inputObject,canvasObjects,isSelected,mode,selectedTool){
    //     var newStyleProperties = !isSelected?this.getStyle():Object.assign(this.getStyle(),this.getSelectedStyle());
    //     newStyleProperties.dirty = true; // for redraw despite cache http://fabricjs.com/fabric-object-caching
    //     for(var canvasObject of canvasObjects)
    //         Object.assign(canvasObject,newStyleProperties);
    // }


    /**
     * optional 
     * (method applied to one map object at a time)
     * 
     * gives an opportunity to change look after a zoom change, for example:
     * - hide unimportant local things when full map is shown , use meterShown
     * - keep size constant accross zoom/resize , use fPx2canvasPx
     */
    updateOnZoomChange(inputObject,canvasObjects,zoomLevel,meterShown,fPx2canvasPx){
        //as a convenience the list can be turned into a bunlde {subpartId => subpart} for easy update and tracking of complex object 


    }


    
};

export default ETDrawer;