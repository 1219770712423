// @ts-check

import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import BurstModeIcon from '@material-ui/icons/BurstMode'; //asset viewer
import CropLandscapeIcon from '@material-ui/icons/CropLandscape'; //floorplans
import GavelIcon from '@material-ui/icons/Gavel';
import GpsFixedIcon from '@material-ui/icons/GpsFixed'; //scene
import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link'; //segues
import PanToolIcon from '@material-ui/icons/PanTool';
import TimelineIcon from '@material-ui/icons/Timeline'; //linked scene 
import TuneIcon from '@material-ui/icons/Tune'; // level core config
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import axios from 'axios';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import * as actionsCloudFs from 'src/actions/cloudFsActions';
import * as actions from 'src/actions/epicTourActions';
import EpicTour2Views from 'src/components/epictour/EpicTour2Views';
import { ELayerEditability, EModes, EpicTourAppConfig, ETLayerConfig, ETModeConfig, ETRootUrls } from 'src/components/epictour/EpicTourAppConfig';
import EpicTourMap, { EMap2DCategory, MapLayer } from 'src/components/epictour/EpicTourMap';
import ETEpicTourTool from 'src/components/epictour/tools/ETEpicTourTool';
import ETFloorplanTool from 'src/components/epictour/tools/ETFloorplanTool';
import ETSceneTool from 'src/components/epictour/tools/ETSceneTool';
import ETSegueTool from 'src/components/epictour/tools/ETSegueTool';
import Page from 'src/components/Page';
import SplashScreen from 'src/components/SplashScreen';
import ExternalConfig from 'src/utils/ExternalConfig';
import { EPICTOUR_EDITMODE_TOOLS, MAP_CUSTOM_ITEMS } from '../../constants/index';
import { Chalk3DTool } from './3dtools/Chalk3DTool';
import { ClimbingRoute3DTool } from './3dtools/ClimbingRoute3DTool';
import { Info3DTool } from './3dtools/Info3DTool';
import { Protection3DTool } from './3dtools/Protection3DTool';
import { Symbol3DTool } from './3dtools/Symbol3DTool';
import ClimbingRouteDrawer from './drawers/ClimbingRouteDrawer';
import HoldDrawer from './drawers/HoldDrawer';
import ProtectionDrawer from './drawers/ProtectionDrawer';
import SymbolDrawer from './drawers/SymbolsDrawer';
import StudioMenuBar from './StudioMenuBar';
import AssetViewerTool from './tool/AssetViewerTool';
import ETClimbingRouteTool from './tool/ClimbingRouteTool';
import ETHoldTool from './tool/HoldTool';
import ProtectionTool from './tool/ProtectionTool';
import SymbolTool from './tool/SymbolTool';
import { Eraser3DTool } from './3dtools/Eraser3DTool';

const useStyles = (theme) => ({
    root: {
        
    }
});

const prepareEpicTourConfig = (props) => {
    var config = EpicTourAppConfig.getDefaultEpicTourAppConfig();

    config.setRootUrls(new ETRootUrls(props.externalConfig.getAssetsDynamicRootUrl(),props.externalConfig.getAssetsStaticRootUrl(),props.externalConfig.getAssetsToursRootUrl()));

    config.initialMode = EModes.EDIT;
    //config.layersConfig[EMap2DCategory.GRID.name].drawerClass = EscapeGameGridDrawer;    

    //to test custom layers 
    config.layersConfig[EMap2DCategory.ITEMS.name][MAP_CUSTOM_ITEMS.CLIMBINGROUTE] = new ETLayerConfig(EMap2DCategory.ITEMS.name, MAP_CUSTOM_ITEMS.CLIMBINGROUTE, ClimbingRouteDrawer, {
        [EModes.VIEW]: new ETModeConfig(true,  ELayerEditability.NONE, 199),//below the segues
        [EModes.EDIT]: new ETModeConfig(true,  ELayerEditability.SELECTION, 199)
    });
    config.layersConfig[EMap2DCategory.ITEMS.name][MAP_CUSTOM_ITEMS.HOLD] = new ETLayerConfig(EMap2DCategory.ITEMS.name, MAP_CUSTOM_ITEMS.HOLD, HoldDrawer, {
        [EModes.VIEW]: new ETModeConfig(true, ELayerEditability.NONE, 260),
        [EModes.EDIT]: new ETModeConfig(true, ELayerEditability.POSITION, 260)
    });
    config.layersConfig[EMap2DCategory.ITEMS.name][MAP_CUSTOM_ITEMS.PROTECTION] = new ETLayerConfig(EMap2DCategory.ITEMS.name, MAP_CUSTOM_ITEMS.PROTECTION, ProtectionDrawer, {
        [EModes.VIEW]: new ETModeConfig(true, ELayerEditability.NONE, 260),
        [EModes.EDIT]: new ETModeConfig(true, ELayerEditability.POSITION, 260)
    });    
    config.layersConfig[EMap2DCategory.ITEMS.name][MAP_CUSTOM_ITEMS.SYMBOL] = new ETLayerConfig(EMap2DCategory.ITEMS.name, MAP_CUSTOM_ITEMS.SYMBOL, SymbolDrawer, {
        [EModes.VIEW]: new ETModeConfig(true,  ELayerEditability.NONE, 260),
        [EModes.EDIT]: new ETModeConfig(true,  ELayerEditability.POSITION, 260)
    });   

    return config;
};

const editModeTools = [          
    {
        icon: <TuneIcon/>,
        label:"Tour config",
        tool: EPICTOUR_EDITMODE_TOOLS.EPICTOUR,
        panel: ETEpicTourTool,
        forLayerSelection: null
    },     
    {
        icon: <BurstModeIcon/>,
        label:"Dynamic Asset Manager",
        tool: EPICTOUR_EDITMODE_TOOLS.ASSET_VIEWER,
        panel: AssetViewerTool,
        forLayerSelection: null
    },     
    {
        icon: <CropLandscapeIcon/>,
        label:"Floorplan",
        tool: EPICTOUR_EDITMODE_TOOLS.FLOORPLANS,
        panel: ETFloorplanTool,
        forLayerSelection: null
    },    
    {
        icon: <GpsFixedIcon/>,
        label:"Scene",
        tool: EPICTOUR_EDITMODE_TOOLS.ADD_SCENE,
        panel: ETSceneTool,
        forLayerSelection: new MapLayer(EMap2DCategory.SCENE.name,null)
    },
    {
        icon: <LinkIcon/>,
        label:"Segue",
        tool: EPICTOUR_EDITMODE_TOOLS.ADD_SEGUE,
        panel: ETSegueTool,
        forLayerSelection: new MapLayer(EMap2DCategory.SEGUE.name,null)
    },
  
    //to test custom stuff 
    {
        icon: <AssignmentLateIcon/>,
        label:"Climbing Symbol",
        tool: EPICTOUR_EDITMODE_TOOLS.ADD_SYMBOL,
        panel: SymbolTool,
        forLayerSelection: new MapLayer(EMap2DCategory.ITEMS.name,MAP_CUSTOM_ITEMS.SYMBOL)
    },            
    {
        icon: <GavelIcon/>,
        label:"Climbing Protection",
        tool: EPICTOUR_EDITMODE_TOOLS.ADD_PROTECTION,
        panel: ProtectionTool,
        forLayerSelection: new MapLayer(EMap2DCategory.ITEMS.name,MAP_CUSTOM_ITEMS.PROTECTION)
    },          
    {
        icon: <PanToolIcon/>,
        label:"Climbing Hold",
        tool: EPICTOUR_EDITMODE_TOOLS.ADD_HOLD,
        panel: ETHoldTool,
        forLayerSelection: new MapLayer(EMap2DCategory.ITEMS.name,MAP_CUSTOM_ITEMS.HOLD)
    },    
    {
        icon: <TimelineIcon/>,
        label:"Climbing Route",
        tool: EPICTOUR_EDITMODE_TOOLS.ADD_CLIMBINGROUTE,
        panel: ETClimbingRouteTool,
        forLayerSelection: new MapLayer(EMap2DCategory.ITEMS.name,MAP_CUSTOM_ITEMS.CLIMBINGROUTE)
    },



    {
        icon: <AssignmentLateIcon/>,
        label:"3D Climbing Symbol",
        tool: EPICTOUR_EDITMODE_TOOLS.ADD_3DSYMBOL,
        panel: Symbol3DTool,
        forLayerSelection: null
    },  
    {
        icon: <GavelIcon/>,
        label:"3D Climbing Protection",
        tool: EPICTOUR_EDITMODE_TOOLS.ADD_3DPROTECTION,
        panel: Protection3DTool,
        forLayerSelection: null
    },  
    {
        icon: <PanToolIcon/>,
        label:"3D Climbing Chalk",
        tool: EPICTOUR_EDITMODE_TOOLS.ADD_3DCHALK,
        panel: Chalk3DTool,
        forLayerSelection: null
    },  
    {
        icon: <TimelineIcon/>,
        label:"3D Climbing Route",
        tool: EPICTOUR_EDITMODE_TOOLS.ADD_3DCLIMBINGROUTE,
        panel: ClimbingRoute3DTool,
        forLayerSelection: null
    },                  
    {
        icon: <AssignmentIcon/>,
        label:"3D Info",
        tool: EPICTOUR_EDITMODE_TOOLS.ADD_3DINFO,
        panel: Info3DTool,
        forLayerSelection: null
    },  
    {
        icon: <DeleteIcon/>,
        tool: EPICTOUR_EDITMODE_TOOLS.REMOVE_3DENTITY,
        panel: Eraser3DTool,
        forLayerSelection: null
    },           
];

class EscapeGameStudio extends React.Component {

    constructor(props) {
        super(props);
        this.etConfig = prepareEpicTourConfig(props);
        this.state = {ready:false};
    }

    handleToolSelection = (event, newTool) => {
        this.props.onToolChange(newTool);
    }

    async componentDidMount(){
        const tourId = this.props.match.params.tourId;
        const existingTourContentResponse = await 
        
        await axios.get(this.props.externalConfig.getToursRootUrl()+tourId);
        const looksEmpty = JSON.stringify(existingTourContentResponse.data).length < 10 ; 
        var map = looksEmpty?EpicTourMap.emptyMap():existingTourContentResponse.data;

        //load dynamic assets list etc
        await this.props.onStudioLoad(this.props.externalConfig);
        //also laod tour assets if there is such a folder indicated 
        if(map.meta.tourAssetsRelativePath)
            await this.props.onTourFolderChange(this.props.externalConfig,map.meta.tourAssetsRelativePath);

        // const sleep = m => new Promise(r => setTimeout(r, m));
        // await sleep(3000);
        await this.props.onEpicTourMapChanged(map,
                                            this.etConfig.initialMode,
                                            this.etConfig.getInitialVisibilityLayerTreeForMode(this.etConfig.initialMode));
        await this.props.onToolChange(null);
        this.setState({ready:true});                                            
    }

    componentDidUpdate(prepProps, prepState) {
        //when something gets selected we chose to select the right tool for it for that selection that we keep
        //so selecting an object leads to 2 redux update, the second one will also trigger an internal double-canvas redraw, thus avoiding new-scrollbar bug
        if (!_.isEqual(prepProps.mapSelectedObjectId, this.props.mapSelectedObjectId) && this.props.currentTool == null) {
            const map = new EpicTourMap(this.props.map);
            const selectedObjectMapLayer = map.getLayerForMapObject(this.props.mapSelectedObjectId);
            if(selectedObjectMapLayer==null)
                return;
            const editModeTool = editModeTools.find(t=> _.isEqual(t.forLayerSelection,selectedObjectMapLayer));
            if(editModeTool)
                this.props.onToolChangeForSelection(editModeTool.tool,this.props.mapSelectedObjectId);
        }
    }

    render() {
        //react-component way to handle material ui dynamic style classes, for container, boxes, pages...
        const { classes } = this.props;

        let editToolBar = (<ToggleButtonGroup
            value={this.props.currentTool}
            exclusive
            onChange={this.handleToolSelection}
        >
            {editModeTools.map(toolInfo => {
                {return (
                    <ToggleButton key={toolInfo.tool} value={toolInfo.tool}>
                    <Tooltip title={toolInfo.label} >
                        {toolInfo.icon}
                    </Tooltip>
                    </ToggleButton>
                    )
                }
            })}
        </ToggleButtonGroup>)

        let editModeControlPanel = null; 
        if(this.props.map && this.props.currentMode===EModes.EDIT){
            //panel are shown if their tool is selected or if an object of their map layer is selected
            const map = new EpicTourMap(this.props.map);
            const mapSelectedItem = map.getMapObjectForId(this.props.mapSelectedObjectId);
            if(this.props.currentTool!=null){
                const editModeTool = editModeTools.find(t=>t.tool === this.props.currentTool);
                const panelClass = editModeTool?.panel;
                let panelProperties = {mapLayer:editModeTool?.forLayerSelection,epicTourAppConfig:this.etConfig};
                if ( mapSelectedItem != null ){
                    panelProperties.mapSelectedItem = mapSelectedItem;
                }              
                editModeControlPanel = panelClass?React.createElement(panelClass,panelProperties,[]):null;
            } 
        }

        return (
            <
                // @ts-ignore
                Page
                className={classes.root}
                title={this.props.match.params.tourId}
            >
                <StudioMenuBar epicTourAppConfig={this.etConfig} />
                {this.state.ready ?
                    (<EpicTour2Views epicTourAppConfig={this.etConfig} viewsLabel={false}>
                        {editToolBar}
                        <div style={{ "margin": "10px" }}>
                            {this.state.ready ? editModeControlPanel : null}
                        </div>
                    </EpicTour2Views>) : <SplashScreen />
                }
            </Page>
            );        
    }
}

const mapStateToProps = state => {
    return {
        externalConfig:new ExternalConfig(state.externalConfig),
        currentMode: state.epicTour.mode,
        currentTool: state.epicTour.tool,
        mapSelectedObjectId: state.epicTour.mapSelectedObjectId,
        map: state.epicTour.map,        
    };
}

const mapDispatchToProps = dispatch => {
    return { 
        onToolChange: (newToolId) => dispatch(actions.epicTourToolChanged(newToolId)),
        onToolChangeForSelection: (newToolId,mapObjectId) => dispatch(actions.epicTourToolChangedForSelection(newToolId,mapObjectId)),
        onEpicTourMapChanged: (newMap, newMode, allLayersVisibleState) => dispatch(actions.epicTourMapChanged(newMap, newMode, allLayersVisibleState)),          

        onStudioLoad: (externalConfig) => dispatch(actionsCloudFs.loadDynamicAssetsAndTourFolders(externalConfig)),
        onTourFolderChange: (externalConfig,tourFolder) => dispatch(actionsCloudFs.loadTourAssets(externalConfig,tourFolder)),
    }
}

// paranthesis lis this means connect create a function which is then called with whatever
//export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(EscapeGameStudio));
var connectFunction = connect(mapStateToProps, mapDispatchToProps);
var styleFunction = withStyles(useStyles);
export default connectFunction(styleFunction(withRouter(EscapeGameStudio)));







