// @ts-check 

import { fabric } from 'fabric-with-gestures' 

import ETDrawer from './ETDrawer'


class ETBackgroundPhotoDrawer extends ETDrawer {

    buildCanvasObjects(inputObject,relatedMpaObjects){
        var oImg = this.canvasResourceManager.getImageResource(inputObject.background_photo_resource_location);
        oImg.set({selectable:false,
            left:0,
            top:0
        });
        let imagePixelToMeterRatio = (inputObject.background_photo_pixel_width / inputObject.play_area_width);
        var zoom = 100.0 / imagePixelToMeterRatio ;
        oImg.scale(zoom);
        return [oImg];
    }
};

export default ETBackgroundPhotoDrawer;