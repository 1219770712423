// @ts-check
import * as ACTIONS from 'src/actions/cloudFsActions';
import produce from 'immer';
const initialState = {
    tourFolders:[],
    tourAssets:[],
    dynamicAssets:[]
};

const cloudFsReducer = (state = initialState, action) => {
    switch (action.type) {

        case ACTIONS.CLOUDFS_LOAD_DYNAMICASSETSANDTOURFOLDERS: {
            return produce(state, (draft) => {
              draft.tourFolders = action.payload.tourFolders;
              draft.dynamicAssets = action.payload.dynamicAssets;
            });
          }

          case ACTIONS.CLOUDFS_LOAD_TOURASSETS: {
            return produce(state, (draft) => {
              draft.tourAssets = action.payload.tourAssets;
            });
          }          

        default: {
          return state;
        }
      }
};

export default cloudFsReducer;