
// @ts-check
import React, { useRef, useState } from 'react';
import { E3DTOOLMODE, Tool3D } from './Tool3D';
import { ETTextfield } from 'src/components/form/CommonFormComponents';

export const Info3DTool = () => {
    const [infoText, setInfoText] = useState("");
    const infoTextRef = useRef("");
    const changeCallback = (e)=>{
        // @ts-ignore
        setInfoText(infoTextRef.current.value);
    };
    return (<Tool3D componentType="info-hover" mode={E3DTOOLMODE.SINGLE_ADD_SINGLE_POINT} currentRecord={ {infoText:infoText} }>
        <ETTextfield label="info text" value={infoText} changeCallback={changeCallback} refCallback={infoTextRef} />
    </Tool3D>);
};
