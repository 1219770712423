// @ts-check

import 'react-perfect-scrollbar/dist/css/styles.css';

import { enableES5 } from 'immer';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from 'src/serviceWorker';
import { SettingsProvider } from 'src/context/SettingsContext';
import { configureStore } from 'src/store';
import { restoreSettings } from 'src/utils/settings';

import './index.css';

import App from './App';
import { AccountMockInstaller } from './mock/accountMock';
import axios,{initialSetup} from 'src/utils/axios';

enableES5();



// external config is passed from a file added by CICD and imported by main index.html
// this file sets a global variable with external config which we retrieve here and push to redux for easy access
// there is no redux reducers or actions around it since it is read-only in nature. 
// @ts-ignore
const configGlobalName = "EpicTourFrontEndVisibleExternalConfiguration";
if(!window[configGlobalName])
  throw configGlobalName+" missing'";
var externalConfig = window[configGlobalName];
console.log("index.js externalConfig: "+JSON.stringify(externalConfig,null,2));


//start redux store
const store = configureStore({externalConfig:window[configGlobalName]});


// pure api mock setup, only useful for certain test 
// in most cases, better to get a proper aws token, or even use lambdas running offline 
if(externalConfig.mockApi){
  console.log("install api pure mocks");
  new AccountMockInstaller().install();
}else{
  initialSetup(externalConfig.xApiKey);
}





const settings = restoreSettings();
console.log("index.js settings: "+JSON.stringify(settings,null,2));
ReactDOM.render(
  <Provider store={store}>
    <SettingsProvider settings={settings}>
      <App />
    </SettingsProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
