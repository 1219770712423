
// @ts-check
import React, { Component } from 'react';
import { TextField } from '@material-ui/core';
import ETUtils from '../utils/ETUtils';
import ETToolUtils from './ETToolUtils';

/**
 * some partial forms are constantly reused, X,Y values is a common requirement 
 * with possible its own validation based on level dimention for example 
 * also possibly listening to some canvas event or even double-binding 
 */


export default class ET2DPointFrom extends React.Component {
    render(){
        //https://stackoverflow.com/a/54226170/651465
        return (
            <div>
                {ETToolUtils.buildTextfield(ETUtils.getMeterValueAsString(this.props.point?.x), null, null, "X",true)}
                {ETToolUtils.buildTextfield(ETUtils.getMeterValueAsString(this.props.point?.y), null, null, "Y",true)}
            </div>
        );
    }
}

