
// @ts-check 

import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import CanvasToAframeBridge from './CanvasToAframeBridge';

class EpicTour1View extends React.Component {

    componentDidMount() {
        this.bridge = new CanvasToAframeBridge(this.iframeRef);
        setTimeout(()=>{
            this.bridge.sendMessageNewMapLoaded(this.props.tour,this.props.rootUrls);
        },3000);
    }
    componentWillUnmount(){
        this.bridge.cleanup();
    }        

    handleBridgeEvent = () => {
        //TODO, only send tour to aframe after we received a signal it is ready to be driven
    }

    render() {
        const iframeStyle = {
            position:"fixed",
            top:0,
            left:0,
            bottom:0,
            right:0,
            width:"100%",
            height:"100%",
            border:"none",
            margin:0,
            padding:0,
            overflow:"hidden"
        };

        return (
            <div>
                { /* @ts-ignore */ }
                <iframe id="aframeiframe" ref={c => (this.iframeRef = c)} scrolling="no" style={iframeStyle} frameBorder="0" src={this.props.externalConfig.aframeUrl}  allowFullScreen="yes" allowvr="yes">
                    <p>Your browser does not support iframes.</p>
                </iframe>
            </div>);
    }
}

export default EpicTour1View;
