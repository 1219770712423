/* eslint-disable import/prefer-default-export */
export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const APIS = {
  OAUTH2_USERINFO : "https://epictourstudio.auth.eu-west-2.amazoncognito.com/oauth2/userInfo",
  FILELISTINGS : "https://m4fswdrk4e.execute-api.eu-west-2.amazonaws.com/dev/filelistings",
  FILES : "https://m4fswdrk4e.execute-api.eu-west-2.amazonaws.com/dev/files/"
}


export const MAP_CUSTOM_AREAS = {
  DANGER: 'DANGER'
};


export const MAP_CUSTOM_PATHS = {
};

export const MAP_CUSTOM_ITEMS = {
  PROTECTION: 'PROTECTION',
  HOLD: 'HOLD',
  SYMBOL: 'SYMBOL',  
  CLIMBINGROUTE: 'CLIMBINGROUTE'
};

export const EPICTOUR_EDITMODE_TOOLS = {
  
  EPICTOUR:'EPICTOUR',
  ASSET_VIEWER:'ASSET_VIEWER',
  FLOORPLANS:'FLOORPLANS',
  ADD_SCENE:'ADD_SCENE',
  ADD_SEGUE:'ADD_SEGUE',
  WORLD:'WORLD',

  TOOL_SETTINGS:'TOOL_SETTINGS',

  //climbing 
  ADD_PROTECTION:'ADD_PROTECTION',
  ADD_SYMBOL:'ADD_SYMBOL',
  ADD_HOLD:'ADD_HOLD',
  ADD_CLIMBINGROUTE:'ADD_CLIMBINGROUTE',

  //climbing 3d
  ADD_3DPROTECTION:'ADD_3DPROTECTION',
  ADD_3DSYMBOL:'ADD_3DSYMBOL',
  ADD_3DCHALK:'ADD_3DCHALK',
  ADD_3DINFO:'ADD_3DINFO',
  ADD_3DCLIMBINGROUTE:'ADD_3DCLIMBINGROUTE',
  REMOVE_3DENTITY:'REMOVE_3DENTITY',
}












export const SYMBOLS_TYPES_OPTIONSMAP = {
  "Symbols":{
      LONG_REACH:"Long Reach",
      SLOPPY_HOLDS:"Sloppy Holds",
  }
};

export const SIZES_OPTIONSMAP = {
  "Sizes":{
      S:"Small",
      M:"Medium",
      L:"Large",
  }
};


export const PROTECTION_TYPES_OPTIONSMAP = {
  "Cams":{
      CAM1:"cam size 1",
      CAM2:"cam size 2",
      CAM3:"cam size 3",
  },
  "Permanent":{
      BOLT:"Bolt"
  }    
};











