// @ts-check



export default class UIControlContext {
    construction(mode){
        this.mode = mode ;
        this.selectedTool = null ;
        this.selectedItemId = null ;
        this.zoomLevel = null ;
        this.meterShown = null ;
        this.fPx2canvasPx = null ;
    }

    setModeAndTool(mode,selectedTool){
        this.mode = mode ;
        this.selectedTool = selectedTool ;
    }

    setSelectedItemId(selectedItemId){
        this.selectedItemId = selectedItemId ;
    }

    setZoomInfo(zoomLevel,meterShown,fPx2canvasPx){
        this.zoomLevel = zoomLevel ;
        this.meterShown = meterShown ;
        this.fPx2canvasPx = fPx2canvasPx ;
    }    
}

