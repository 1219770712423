
// @ts-check 

import { fabric } from 'fabric-with-gestures' 

import ETDrawer from './ETDrawer'
import { EGameStateField } from '../GameStateManager';


class ETSceneDrawer extends ETDrawer {

    getStyle(){
        return {
            fill: '#4FC3F7',
            opacity: 0.6,
            stroke: 'blue',
            strokeWidth: 2
        }
    }

    getSelectedStyle(){
        return {
            fill: 'green',
            opacity: 0.001,
            stroke: 'white',
            strokeWidth: 4
        }
    }       

    buildCanvasObjects(inputObject,relatedMpaObjects){
        if(this.currentImmutableGameState && inputObject.id===this.currentImmutableGameState[EGameStateField.PLAYER_SCENE_ID].value ){
            console.log("hello");
        }

        var pointFPx = this.canvasCoordinatesConverter.meterPointTofpxUnitPoint(inputObject);
        var structuralProperties = {
            radius: 8,//inputObject.name.includes('zzz')?50:25,
            left: pointFPx.x ,
            top: pointFPx.y ,
            originX:"center",
            originY:"center",            
            strokeUniform: true
        }; 
        return [new fabric.Circle(structuralProperties)];
    }

    updateOnZoomChange(inputObject,canvasObjects,zoomLevel,meterShown,fPx2canvasPx){
        canvasObjects[0].set({radius: 10 * fPx2canvasPx, strokeWidth: 2 * fPx2canvasPx});
    }    

    pickStylingProperties(inputMapObject,existingCanvasObjects,isSelected,mode,selectedTool){
        var selected = isSelected;
        // if(this.currentImmutableGameState && inputMapObject.id===this.currentImmutableGameState[EGameStateField.PLAYER_SCENE_ID].value ){
            
        //     selected = true;
        // }
        return !selected?this.getStyle():Object.assign(this.getStyle(),this.getSelectedStyle());
    }

};

export default ETSceneDrawer;