
// @ts-check

import ETDrawer from "src/components/epictour/drawers/ETDrawer";
import { fabric } from "fabric-with-gestures";



class ClimbingRouteDrawer extends ETDrawer{

    getStyle(){
        return [{
            fill: 'rgba(0,0,0,0)',            
            stroke: 'black',
            //strokeDashOffset: 25,
            //strokeDashArray: [25, 25],
            strokeWidth: 10
        },{
            fill: 'rgba(0,0,0,0)',            
            stroke: 'orange',
            //strokeDashArray: [25, 25],
            strokeWidth: 6
        }]
    }

    meterPath2fpxPath(inputObject,extraOffset){
        var pathFpx = inputObject.path.map(pointM => {
            const pointFpx = this.canvasCoordinatesConverter.meterPointTofpxUnitPoint(pointM);
            return ["L",pointFpx.x+extraOffset,pointFpx.y+extraOffset];
         });
         //pathFpx.unshift(JSON.parse(JSON.stringify(pathFpx[0])));
         pathFpx[0][0]="M";
         //pathFpx[pathFpx.length-1][0]="L";

         var minX = 1000000;
         var minY = 1000000;
         for (const pointFpx of pathFpx) {
             if(pointFpx[1]<minX) minX = pointFpx[1];
             if(pointFpx[2]<minY) minY = pointFpx[2];
         }
         var offsetX = pathFpx[0][1] - minX ;
         var offsetY = pathFpx[0][2] - minY ;

         var structuralProperties = {
            left: pathFpx[0][1] - offsetX ,
            top: pathFpx[0][2] - offsetY
        }; 
        var path = new fabric.Path(pathFpx);
        path.set(structuralProperties);
        return path ;
    }


    buildCanvasObjects(inputObject,relatedMpaObjects){  
        return [this.meterPath2fpxPath(inputObject,0),this.meterPath2fpxPath(inputObject,2)];//duplicate for bicolor dotted line
    }

}

export default ClimbingRouteDrawer; 

