//@ts-check

import React, { Component } from 'react';

import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles, IconButton, withStyles } from '@material-ui/core';

const useStyles = (theme) => ({
    margin: {
        margin: theme.spacing(1),
    }
});

class ETToolFormDeleteButton extends React.Component {

    render() {
        const { classes } = this.props;
        return (
            <IconButton className={classes.margin} onClick={this.props.deleteClickCallback} >
                <DeleteIcon />
            </IconButton>
        );
    }
}

export default withStyles(useStyles)(ETToolFormDeleteButton);