// @ts-check 

import { fabric } from 'fabric-with-gestures' 
import ETDrawer from 'src/components/epictour/drawers/ETDrawer';




class HoldDrawer extends ETDrawer {

    getStyle(){
        return {
            fill: 'rgba(0,0,0,0)',
            opacity: 0.75,
            stroke: 'white',
            strokeWidth: 10
        }
    }

    buildCanvasObjects(inputObject,relatedMpaObjects){
        var pointFPx = this.canvasCoordinatesConverter.meterPointTofpxUnitPoint(inputObject);
        var structuralProperties = {
            radius: 25,
            left: pointFPx.x ,
            top: pointFPx.y ,
            originX:"center",
            originY:"center",            
            strokeUniform: true
        }; 
        return [new fabric.Circle(structuralProperties)];
    }
};

export default HoldDrawer;