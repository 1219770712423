// @ts-check 

import { fabric } from 'fabric-with-gestures' //https://stackoverflow.com/questions/36984795/webpack-babel-es6-import-vs-require-for-fabric-js

import * as actions from '../../actions/epicTourActions'

import CanvasZoomPanManager from './CanvasZoomPanManager';
import CanvasEventBroadcaster from './CanvasEventBroadcaster';
import CanvasCoordinatesConverter from './CanvasCoordinatesConverter';
import CanvasMapDrawer from './CanvasMapDrawer';
import CanvasSelectionManager from './CanvasSelectionManager';
import CanvasResourceManager from './CanvasResourceManager';
import GameStateManager, { EGameStateField } from './GameStateManager';
import EpicTourMap, { MapLayer, EMap2DCategory } from './EpicTourMap';
import CanvasToAframeBridge from './CanvasToAframeBridge';
import CanvasAccess from './CanvasAccess';
import ContextAccess from './ContextAccess';
import Game from './game/Game';
import UIControlContext from './UIControlsContext';
import Player from './mapEntities/player';

class CanvasWrapper {
    constructor(config,canvasHtmlElementRef,canvasWrappingDivElement,onCanvasSelectionChange,onCanvasDrivenMapObjectChange) {
        this.config = config; 
        this.initCanvas(canvasHtmlElementRef,canvasWrappingDivElement,onCanvasSelectionChange,onCanvasDrivenMapObjectChange) 
    }

    cleanup(){
        this.canvasToAframeBridge.cleanup();
    }


    addToolDelegate(toolDelegateInfo){
        this.canvasEventBroadcaster.addToolDelegate(toolDelegateInfo);
    }
    removeToolDelegate(callbackOwnerId){
        this.canvasEventBroadcaster.removeToolDelegate();
    }
    stopListeningSelectionEvents = () => {
        this.canvasSelectionManager.stopListeningSelectionEvents();
    }
    startListeningSelectionEvents = () => {
        this.canvasSelectionManager.startListeningSelectionEvents();
    }    


    zoomIn() {
        this.canvasZoomPanManager.zoomIn();
    }
    zoomOut() {
        this.canvasZoomPanManager.zoomOut();
    }    

    updateAfterSelectionChange(oldMapSelectedObjectId,mapSelectedObjectId){
        this.canvasMapDrawer.updateMapObjectsAfterSelectionChange(oldMapSelectedObjectId,mapSelectedObjectId) ;
        //also tell canvas to reflect selection 
        this.canvasSelectionManager.updateCanvasSelection(mapSelectedObjectId);
        if(mapSelectedObjectId && this.mapContent.getLayerForMapObject(mapSelectedObjectId).mapCategory == EMap2DCategory.SCENE.name){
            //this.gameStateManager.changeGameState(mapObjectId,EGameStateField.PLAYER_SCENE_ID, Date.now(),false);
            this.game.player.goToScene(mapSelectedObjectId,false);
        }
    }

    updateAfterModeOrToolChange(currentMode,currentTool){
        console.log("### updateAfterModeOrToolChange");
        this.canvasMapDrawer.updateAllMapLayersAfterModeOrToolChange(currentMode,currentTool);
    }

    updateShownLayers(shownLayers) {
        this.canvasMapDrawer.updateShownLayers(shownLayers);
    }

    onMapEvent(mapLastObjectEvent,mapData){
        //just copy over the map content
        this.mapContent.setMapData(mapData);

        //ask drawer to make the canvas changes
        const actionPayload = mapLastObjectEvent.action.payload;
        if(mapLastObjectEvent.action.type === actions.EPICTOUR_MAP_OBJECT_ADD )
            this.canvasMapDrawer.updateOneMapLayerItemChange(actionPayload.newObject.id,actionPayload.mapLayer,null);
        if(mapLastObjectEvent.action.type === actions.EPICTOUR_MAP_OBJECT_EDIT ){
            this.canvasResourceManager.loadImagesForMapPart(mapData.meta.tourAssetsRelativePath,actionPayload.editedObject,false,()=>{
                this.canvasMapDrawer.updateOneMapLayerItemChange(actionPayload.editedObject.id,actionPayload.mapLayer,null);
            });
            //reset the zoom and grid if it's a structural change
            if(!actionPayload.editedObject.id){
                this.canvasMapDrawer.redrawLayer(new MapLayer(EMap2DCategory.GRID.name, null));
                this.canvasZoomPanManager.resetZoomAndPan();
            }
        }
        if(mapLastObjectEvent.action.type === actions.EPICTOUR_MAP_OBJECT_REMOVE )
            this.canvasMapDrawer.updateOneMapLayerItemChange(actionPayload.objectId,actionPayload.mapLayer,null);     
            
        this.game.draw(); //since some are derived... TODO merge the 2 layer/drawer systems, including dependon, isdependedon
        this.updateAframeViaBridge(mapLastObjectEvent.action);
    }

    updateAframeViaBridge(action){
        if(action.type === actions.EPICTOUR_MAP_OBJECT_ADD && action.payload.mapLayer.mapCategory === EMap2DCategory.SCENE.name )
            this.canvasToAframeBridge.mapServiceAccess.call("addScene",action.payload.newObject);
        if(action.type === actions.EPICTOUR_MAP_OBJECT_EDIT && action.payload.mapLayer.mapCategory === EMap2DCategory.SCENE.name )
            this.canvasToAframeBridge.mapServiceAccess.call("editScene",action.payload.editedObject);
        if(action.type === actions.EPICTOUR_MAP_OBJECT_REMOVE && action.payload.mapLayer.mapCategory === EMap2DCategory.SCENE.name ){
            this.canvasToAframeBridge.mapServiceAccess.call("removeScene",action.payload.objectId);
            //if we just deleted the player's location, send him nowhere
            if(this.game.player.sceneId===action.payload.objectId)
                this.game.player.goToScene(null,false);
        }
        if(action.type === actions.EPICTOUR_MAP_OBJECT_ADD && action.payload.mapLayer.mapCategory === EMap2DCategory.SEGUE.name )
            this.canvasToAframeBridge.mapServiceAccess.call("addSegue",action.payload.newObject);
        if(action.type === actions.EPICTOUR_MAP_OBJECT_REMOVE && action.payload.mapLayer.mapCategory === EMap2DCategory.SEGUE.name )
            this.canvasToAframeBridge.mapServiceAccess.call("removeSegue",action.payload.objectId);
        if(action.type === actions.EPICTOUR_MAP_OBJECT_EDIT && action.payload.mapLayer.mapCategory === EMap2DCategory.BACKGROUND.name )
            this.canvasToAframeBridge.mapServiceAccess.call("editFloorplan",action.payload.editedObject);        
    }

    syncDynamicAssets(dynamicAssetReferences){
        this.canvasToAframeBridge.mapServiceAccess.call("syncDynamicAssets",dynamicAssetReferences);
    }

    onCanvasDrivenMapObjectChangeInternal = (mapLayer,internalEditedMapObject) => {
        this.canvasMapDrawer.updateOneMapLayerItemChange(internalEditedMapObject.id,mapLayer,internalEditedMapObject);      
    }

    setMapContent(mapContent,initialMode,shownLayers) {
        this.mapContent = mapContent;
        //Object.assign(this.mapContent,mapContent);//keep reference alive
        console.log("map " + this.mapContent.meta.name); 

        //new approach
        // @ts-ignore
        this.uiControlsContext = new UIControlContext(initialMode);
        this.canvasAccess = new CanvasAccess(this.fabricCanvas );
        this.game = new Game();
        this.contextAccess = new ContextAccess(this.canvasAccess,this.uiControlsContext,this.mapContent,this.game,this.canvasCoordinatesConverter,this.canvasResourceManager,this.canvasToAframeBridge,this.config);
        this.game.player = new Player(mapContent.floorplan.initial_scene,this.contextAccess);
        this.canvasToAframeBridge.player = this.game.player ;
        console.log("new approach setup done");

        this.canvasMapDrawer.setMap(mapContent);
        this.canvasEventBroadcaster.setMap(mapContent);
        this.canvasZoomPanManager.setMap(mapContent);
        this.canvasSelectionManager.setMap(mapContent);
        console.log("map setup done");

        //give a chance to resource loader to get all images and then only draw the new map 
        //also, for convenience, load generic images associated with drawers at the same time 
        this.canvasResourceManager.loadImagesForMapPart(mapContent.meta.tourAssetsRelativePath,mapContent,true,()=>{
            this.canvasMapDrawer.draw(initialMode,null); 
            this.updateShownLayers(shownLayers);
            console.log("### map is loaded and drawn, propagating zoom");
            this.canvasZoomPanManager.resetZoomAndPan();
            this.game.draw();
            //TODO, have a queue is slave ready signal hasn't arrived yet
            setTimeout(()=>{
                this.canvasToAframeBridge.sendMessageNewMapLoaded(mapContent,this.config.rootUrls);
            },3000);
        });


    }

    initCanvas = (canvasHtmlElementRef,iframeDivElement,onCanvasSelectionChange,onCanvasDrivenMapObjectChange) => {
        this.fabricCanvas = new fabric.Canvas(canvasHtmlElementRef, {
            fireRightClick: true,
            fireMiddleClick: true,
            stopContextMenu: true,
            selection: false
        });
        this.canvasCoordinatesConverter = new CanvasCoordinatesConverter(this.fabricCanvas);
        this.canvasResourceManager = new CanvasResourceManager(this.config);
        this.canvasMapDrawer = new CanvasMapDrawer(this.fabricCanvas,this.canvasCoordinatesConverter,this.config,this.canvasResourceManager);
        this.canvasZoomPanManager = new CanvasZoomPanManager(this.fabricCanvas,this.canvasCoordinatesConverter,(zoomLevel,meterShown,fPx2canvasPx)=>{
            console.log("### zoom change "+zoomLevel);
            this.canvasMapDrawer.updateAllMapLayersAfterZoomChange(zoomLevel,meterShown,fPx2canvasPx);
            if(!this.mapContent)
                return;
            this.uiControlsContext.setZoomInfo(zoomLevel,meterShown,fPx2canvasPx);
            this.game.draw();
        });
        this.canvasSelectionManager = new CanvasSelectionManager(this.fabricCanvas,this.canvasCoordinatesConverter,onCanvasSelectionChange);
        this.canvasEventBroadcaster = new CanvasEventBroadcaster(this.fabricCanvas,this.canvasCoordinatesConverter,onCanvasDrivenMapObjectChange,this.onCanvasDrivenMapObjectChangeInternal);
        this.canvasToAframeBridge = new CanvasToAframeBridge(iframeDivElement,null);
    }
}

export default CanvasWrapper;