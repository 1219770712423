// @ts-check 

import { fabric } from 'fabric-with-gestures'
import { CANVAS_USER_EVENTS } from './utils/ETConstants';


const ZOOM_STEP_FACTOR = 1.1; //each mouse middle zoom pushes the zoom by this factor
const ZOOM_MAX_FACTOR = 20; // max is reset zoom x this



class CanvasZoomPanManager {
    constructor(fabricCanvas, canvasCoordinatesConverter,zoomChangeCallback) {
        this.fabricCanvas = fabricCanvas;
        this.canvasCoordinatesConverter = canvasCoordinatesConverter;
        this.zoomChangeCallback = zoomChangeCallback;

        //single button/touch panning 
        this.panning = false;
        this.pausePanning = false;
        this.lastX = 0;
        this.lastY = 0;

        //touch-screen pinch zoom 
        this.pinchZoomInitialZoom = 1;
        this.pinchZoomInitialDistance = -1;
        this.pinchZoomPreviousPoint = null;

        this.resetZoomMinMax(1);
        this.setup();
    }

    setMap(map) {
        this.map = map;
    }

    log(message) {
        console.log("CanvasZoomPanManager: " + message);
    }

    distance(x1, y1, x2, y2) {
        return Math.sqrt(Math.pow((x1 - x2), 2) + Math.pow((y1 - y2), 2));
    }

    zoomIn() {
        this.zoom(true, this.canvasCoordinatesConverter.getCanvasPixelCenterPoint());
    }

    zoomOut() {
        this.zoom(false, this.canvasCoordinatesConverter.getCanvasPixelCenterPoint());
    }

    /**
     * 
     * @param {*} zoomFurther 
     * @param {*} centerPoint in canvasPixel!
     */
    zoom(zoomFurther, centerPoint) {
        var zoomStep = zoomFurther ? ZOOM_STEP_FACTOR : (1.0 / ZOOM_STEP_FACTOR);
        var zoom = this.fabricCanvas.getZoom();
        var newZoom = this.forceToZoomRange(zoom * zoomStep);
        let change = (newZoom !== zoom);
        if (!change)
            return;
        this.fabricCanvas.zoomToPoint(centerPoint, newZoom);
        this.forceToPlayArea();
        this.afterZoomChangeCallback();
    }

    forceToPlayArea(){
        var [resetTopLeft,resetZoom] = this.getResetLocationAndZoom();

        var verticalMap = resetTopLeft.x!==0;//if x!=0 => means black stripes on left/right
        const play_area_width_fpx = this.canvasCoordinatesConverter.m2fpx(this.map.floorplan.play_area_width);
        const play_area_height_fpx = this.canvasCoordinatesConverter.m2fpx(this.map.floorplan.play_area_height);

        var minX = resetTopLeft.x;
        var maxX = verticalMap?play_area_width_fpx-resetTopLeft.x:play_area_width_fpx;
        var minY = resetTopLeft.y;
        var maxY = verticalMap?play_area_height_fpx:play_area_height_fpx-resetTopLeft.y;

        var vpt = this.fabricCanvas.viewportTransform;
        var zoom = this.fabricCanvas.getZoom();
        if (vpt[4] >= -minX*zoom ) {
            vpt[4] = -minX*zoom;
        } else if (vpt[4] < this.fabricCanvas.getWidth() - maxX * zoom) {
            vpt[4] = this.fabricCanvas.getWidth() - maxX * zoom;
        }
        if (vpt[5] >= -minY*zoom) {
            vpt[5] = -minY*zoom;
        } else if (vpt[5] < this.fabricCanvas.getHeight() - maxY * zoom) {
            vpt[5] = this.fabricCanvas.getHeight() - maxY * zoom;
        }
    }

    forceToZoomRange(newZoom) {
        if (newZoom > this.maxZoom)
            newZoom = this.maxZoom;
        if (newZoom < this.minZoom)
            newZoom = this.minZoom;
        return newZoom;
    }

    pinchZoom(event) {
        /**
         * mobile touch gesture pinch zoom 
         * - Handle zoom only if 2 fingers are touching the screen
         * - this zoom doesn't work the same way as with the mouse middle button, it takes the zoom at start of gesture. compare the distance between fingers 
         *   at start of gesture, at this distance, no extra zoom applied, it compares the current distance with that initial distance to see what extra zoom is applied
         */
        if (event.e.touches && event.e.touches.length == 2) {
            this.log("touch:gesture zoom " + event.self.state);
            if (event.self.state == "start") {
                this.log("zoom step 1 ");
                this.pinchZoomInitialDistance = -1;
            } else if (event.self.state == "change") {
                var currentDistance = this.distance(event.self.touches[0].x, event.self.touches[0].y, event.self.touches[1].x, event.self.touches[1].y);
                if (this.pinchZoomInitialDistance == -1) {
                    this.pinchZoomInitialZoom = this.fabricCanvas.getZoom();
                    this.pinchZoomInitialDistance = currentDistance;
                    this.pinchZoomPreviousPoint = new fabric.Point((event.self.touches[0].x + event.self.touches[1].x) / 2, (event.self.touches[0].y + event.self.touches[1].y) / 2);
                    //{x:event.self.y,y:event.self.y};
                    this.log("zoom step 2 " + this.pinchZoomInitialZoom + " - " + currentDistance);
                } else {
                    var extraZoomFactor = currentDistance / this.pinchZoomInitialDistance;
                    var newZoom = this.forceToZoomRange(this.pinchZoomInitialZoom * extraZoomFactor);
                    this.log("zoom step 3 " + newZoom + " " + currentDistance + " " + extraZoomFactor);
                    var centerPoint = new fabric.Point((event.self.touches[0].x + event.self.touches[1].x) / 2, (event.self.touches[0].y + event.self.touches[1].y) / 2);
                    this.fabricCanvas.zoomToPoint(centerPoint, newZoom);
                    var delta = new fabric.Point(centerPoint.x - this.pinchZoomPreviousPoint.x, centerPoint.y - this.pinchZoomPreviousPoint.y);
                    console.log("pan delta " + JSON.stringify(delta));
                    this.fabricCanvas.relativePan(delta);
                    this.pinchZoomPreviousPoint = centerPoint;
                }
            }
        }
    }

    resetZoomMinMax(resetZoomValue) {
        this.minZoom = resetZoomValue;
        this.maxZoom = this.minZoom * ZOOM_MAX_FACTOR;
    }

    setup() {
        this.fabricCanvas.on({
            'touch:gesture': function (event) {
                this.pinchZoom(event);
            }.bind(this),
            'mouse:wheel': function (opt) {
                this.zoom((opt.e.deltaY < 0), { x: opt.e.offsetX, y: opt.e.offsetY });
                opt.e.preventDefault();
                opt.e.stopPropagation();
            }.bind(this),
            // 'object:selected': function () {
            //     this.pausePanning = true;
            // }.bind(this),
            // 'selection:cleared': function () {
            //     this.pausePanning = false;
            // }.bind(this),
            'mouse:down': function (e) {
                //this.log("mouse:down "+e.button);
                if (e.button === CANVAS_USER_EVENTS.DESTOP_PAN_BUTTON) {
                    this.panning = true;
                }
            }.bind(this),
            // 'mouse:out': function (e) {
            //     if(!e.target){
            //         console.log("mouse:out");
            //         this.panning = false;
            //     }
            // }.bind(this),            
            'mouse:up': function (e) {
                if (e.button === CANVAS_USER_EVENTS.DESTOP_PAN_BUTTON) {
                    this.panning = false;
                }
            }.bind(this),
            'mouse:move': function (e) {
                if (this.panning == true && this.pausePanning == false && undefined != e.e.layerX && undefined != e.e.layerY) {
                    var currentX = e.e.layerX;
                    var currentY = e.e.layerY;
                    var xChange = currentX - this.lastX;
                    var yChange = currentY - this.lastY;

                    if ((Math.abs(currentX - this.lastX) <= 50) && (Math.abs(currentY - this.lastY) <= 50)) {
                        var delta = new fabric.Point(xChange, yChange);
                        this.fabricCanvas.relativePan(delta);
                        this.forceToPlayArea();
                    }

                    this.lastX = e.e.layerX;
                    this.lastY = e.e.layerY;
                }

                if(this.panning && !this.canvasCoordinatesConverter.isInsideCanvas(e.pointer))
                    this.panning = false;                
            }.bind(this)
        });
    }

    afterZoomChangeCallback(){
        var zoomLevel = this.fabricCanvas.getZoom();
        var meterShown = this.canvasCoordinatesConverter.getCurrentMeterWidth();
        var fPx2canvasPx = this.canvasCoordinatesConverter.getCurrentFabricjsPixelWidth()  / this.canvasCoordinatesConverter.getCanvasHtmlElementCurrentPixelWidth() ;
        //console.log("zoomLevel:"+zoomLevel+" meterShown:"+meterShown+" fPx2canvasPx:"+fPx2canvasPx);
        this.zoomChangeCallback(zoomLevel,meterShown,fPx2canvasPx);
    }


    getResetLocationAndZoom(){
        var canvas = this.fabricCanvas;

        var mapWidthMeter = this.map.floorplan.play_area_width;
        var mapHeightMeter = this.map.floorplan.play_area_height;

        var viewWidthPx = canvas.getWidth();
        var viewHeightPx = canvas.getHeight();

        var mapWidthFabPx = this.canvasCoordinatesConverter.m2fpx(mapWidthMeter);
        var mapHeightFabPx = this.canvasCoordinatesConverter.m2fpx(mapHeightMeter);

        var resetZoomWidth = viewWidthPx / mapWidthFabPx;
        var resetZoomHeight = viewHeightPx / mapHeightFabPx;

        var resetZoom = 1;
        var resetTopLeft = {};
        if (resetZoomWidth > resetZoomHeight) {
            //resetZoomHeight is lower, meaning, bigger height, meaning black stripes on left and right
            //the height is fully shown - so we have the viewPx equivalense
            var meterInViewPx = viewHeightPx / mapHeightMeter; //e.g.: 1 meter=20px of canva
            //we can derive how many meter we show in width;
            var shownWidthMeter = viewWidthPx / meterInViewPx;
            // now we now by how many meter we must offset on X
            var xOffsetMeter = (shownWidthMeter - mapWidthMeter) / 2;
            // we can therefore move the resetTopLeftPoint to (xOffsetPx,0)
            resetTopLeft = { x: this.canvasCoordinatesConverter.m2fpx(-1 * xOffsetMeter) , y: 0 };
            resetZoom = resetZoomHeight;

        } else {
            //resetZoomWidth is lower, meaning, bigger width, meaning black stripes on top and bottom
            //the width is fully shown - so we have the viewPx equivalense
            var meterInViewPx = viewWidthPx / mapWidthMeter; //e.g.: 1 meter=20px of canva
            //we can derive how many meter we show in height;
            var shownHeightMeter = viewHeightPx / meterInViewPx;
            // now we now by how many meter we must offset on Y
            var yOffsetMeter = (shownHeightMeter - mapHeightMeter) / 2;
            // we can therefore move the resetTopLeftPoint to (0,yOffsetPx)
            resetTopLeft = { x: 0, y: this.canvasCoordinatesConverter.m2fpx(-1 * yOffsetMeter)  };
            resetZoom = resetZoomWidth;
        }
        return [resetTopLeft,resetZoom];
    }

    resetZoomAndPan() {
        if(!this.map)
            return;
        var [resetTopLeft,resetZoom] = this.getResetLocationAndZoom();
        this.fabricCanvas.setZoom(resetZoom);
        //converted as pan use ViewPx...
        // @ts-ignore
        const resetZoomCanvasPx = {x:resetZoom*resetTopLeft.x,y:resetZoom*resetTopLeft.y};
        this.fabricCanvas.absolutePan(resetZoomCanvasPx);
        this.resetZoomMinMax(resetZoom);
        this.afterZoomChangeCallback();
    }

}

export default CanvasZoomPanManager;