
//@ts-check

//add derived data methods to things stored in redux
//https://redux.js.org/recipes/computing-derived-data

export default class ExternalConfig {
    constructor(rawExternalConfig){
        Object.assign(this, rawExternalConfig);

        //specify this one just to help ide error highlighting
        this.assetLocationRootUrl = rawExternalConfig.assetLocationRootUrl;
    }

    getAssetsDynamicRootUrl(){
        return this.assetLocationRootUrl+this.getAssetsDynamicRelativePath();
    }

    getAssetsStaticRootUrl(){
        return this.assetLocationRootUrl+this.getAssetsStaticRelativePath();
    }

    getAssetsToursRootUrl(){
        return this.assetLocationRootUrl+this.getAssetsToursRelativePath();
    }

    getToursRootUrl(){
        return this.assetLocationRootUrl+this.getToursRelativePath();
    }


    getAssetsDynamicRelativePath(){
        return "assets_dynamic/";
    }

    getAssetsStaticRelativePath(){
        return "assets_static/";
    }

    getAssetsToursRelativePath(){
        return "assets_tours/";
    }

    getToursRelativePath(){
        return "tours/";
    }    
}