// @ts-check

export const EGameStateField = {
    PLAYER_SCENE_ID : "PLAYER_SCENE_ID"
}

export class GameStateFullValue{
    constructor(value,timestamp,originAframe){
        this.value = value ; 
        this.timestamp = timestamp;
        this.originAframe = originAframe;        
    }
}

export class GameState{
    constructor(){
        this[EGameStateField.PLAYER_SCENE_ID] = null ; 
    }
}

export default class GameStateManager {
    constructor(internalRedrawCallback,externalListenerCallback){
        this.gameState = new GameState();

        this.internalRedrawCallback = internalRedrawCallback ; 
        this.externalListenerCallback = externalListenerCallback ; 

        //if there are events that can change different part of the state, the dates should be on a per field basis otherwise thigns can be lost
        this.lastChangeDate = null;
        this.lastChangeIsOriginAframe = null;
    }

    changeGameState(newValue,gameStateField,timestamp,isOriginAframe){
        const acceptable = this.isEventAcceptable(gameStateField,timestamp,isOriginAframe);
        const currentFullValue = this.gameState[gameStateField];
        const actualChange = currentFullValue?.value !== newValue;
        console.log("GameStateManager change? "+currentFullValue?.value +">"+newValue+" ("+actualChange+") - @"+timestamp+" fromAframe:"+isOriginAframe+" - acceptable:"+acceptable);
        if(!acceptable)
            return;
        //we always save unless time-irrelevant
        const newFullValue = new GameStateFullValue(newValue,timestamp,isOriginAframe); 
        this.gameState[gameStateField] = newFullValue ; 

        const currentImmutableGameState = JSON.parse(JSON.stringify(this.gameState));
        //if value has change, we trigger a local redraw
        if(actualChange)
            this.internalRedrawCallback(gameStateField,currentFullValue,newFullValue,currentImmutableGameState);
        //if change is internal, we export it 
        if(!isOriginAframe)
            this.externalListenerCallback(gameStateField,currentFullValue,newFullValue,currentImmutableGameState);
    }


    /**
     * event is rejected if 
     * - older than previous one 
     * - same time but causing an aframe even to be overridden 
     */
    isEventAcceptable(gameStateField,timestamp,isOriginAframe){
        if(this.gameState[gameStateField]==null)
            return true;

        if(timestamp < this.gameState[gameStateField].timestamp ){
            return false;
        } else if(timestamp == this.gameState[gameStateField].timestamp  && !isOriginAframe && this.gameState[gameStateField].isOriginAframe){
            return false;
        } else
            return true; 
    }
}