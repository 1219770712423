
// @ts-check

export const EPICTOUR_MAPCHANGE = 'EPICTOUR_MAPCHANGE';

export const EPICTOUR_METACHANGE = 'EPICTOUR_METACHANGE';

export const EPICTOUR_MAP_SELECTION_CHANGE = "EPICTOUR_MAP_SELECTION_CHANGE" ;

export const EPICTOUR_MAP_OBJECT_ADD = 'EPICTOUR_MAP_OBJECT_ADD';
export const EPICTOUR_MAP_OBJECT_EDIT = 'EPICTOUR_MAP_OBJECT_EDIT';
export const EPICTOUR_MAP_OBJECT_REMOVE = 'EPICTOUR_MAP_OBJECT_REMOVE';

export const EPICTOUR_TOOLCHANGE = 'EPICTOUR_TOOLCHANGE';
export const EPICTOUR_TOOLCHANGEFORSELECTION = 'EPICTOUR_TOOLCHANGEFORSELECTION';
export const EPICTOUR_MODECHANGE = 'EPICTOUR_MODECHANGE';

export const EPICTOUR_SETALLLAYERSVISIBLESTATE = 'EPICTOUR_SETALLLAYERSVISIBLESTATE';
export const EPICTOUR_SETONELAYERVISIBLESTATE = 'EPICTOUR_SETONELAYERVISIBLESTATE';

export const EPICTOUR_DYNAMICASSETSCHANGE = 'EPICTOUR_DYNAMICASSETSCHANGE';

export const EPICTOUR_WORLDCHANGE = 'EPICTOUR_WORLDCHANGE';


// action builder method with type and payload if necessary 
// if there is async involved, you typically have 3 methods, the success/failures sync ones called from the 3rd one, in callback of the axios call

function wrapForLogging(actionBuildingFunction){
    return (...args) => {
        const action = actionBuildingFunction(...args);
        console.log("ACTION: "+action.type+" "+JSON.stringify(action));
        return action ; 
    }
}

export const epicTourMapChanged = wrapForLogging(( newMap,newMode,layersVisibleState ) => {
    return {
        type: EPICTOUR_MAPCHANGE,
        newMap: newMap,
        newMode: newMode,
        layersVisibleState: layersVisibleState
    };
});

export const epicTourMapMetaChanged = wrapForLogging(( newMeta ) => {
    return {
        type: EPICTOUR_METACHANGE,
        newMeta:newMeta
    };
});

export const epicTourMapSelectionChanged = wrapForLogging(( mapObjectId ) => {
    return {
        type: EPICTOUR_MAP_SELECTION_CHANGE,
        mapObjectId:mapObjectId
    };
});

export const epicTourMapObjectAdd = wrapForLogging(( mapLayer,newObject ) => {
    return {
        type: EPICTOUR_MAP_OBJECT_ADD,
        payload:{
            mapLayer: mapLayer,
            newObject: newObject,
        }
    };
});
export const epicTourMapObjectEdit = wrapForLogging(( mapLayer,editedObject ) => {
    return {
        type: EPICTOUR_MAP_OBJECT_EDIT,
        payload:{
            mapLayer: mapLayer,
            editedObject: editedObject,
        }
    };
});
export const epicTourMapObjectRemove = wrapForLogging(( mapLayer,objectId ) => {
    return {
        type: EPICTOUR_MAP_OBJECT_REMOVE,
        payload:{
            mapLayer: mapLayer,
            objectId: objectId,
        }
    };
});




export const epicTourToolChanged = wrapForLogging(( newTool ) => {
    return {
        type: EPICTOUR_TOOLCHANGE,
        newTool: newTool
    };
});

export const epicTourToolChangedForSelection = wrapForLogging(( newTool,mapObjectId ) => {
    return {
        type: EPICTOUR_TOOLCHANGEFORSELECTION,
        newTool: newTool,
        mapObjectId:mapObjectId
    };
});



export const epicTourModeChanged = wrapForLogging(( newMode ) => {
    return {
        type: EPICTOUR_MODECHANGE,
        newMode: newMode
    };
});

export const setAllLayersVisibleState = wrapForLogging((layersVisibleState) => {
    return {
        type: EPICTOUR_SETALLLAYERSVISIBLESTATE,
        layersVisibleState: layersVisibleState
    };
});

export const setOneLayerVisibleState = wrapForLogging((mapCategory,mapSubType) => {
    return {
        type: EPICTOUR_SETONELAYERVISIBLESTATE,
        mapCategory: mapCategory,
        mapSubType: mapSubType
    };
});

export const epicTourDynamicAssetsChanged = wrapForLogging(( updatedDynamicAssets ) => {
    return {
        type: EPICTOUR_DYNAMICASSETSCHANGE,
        updatedDynamicAssets: updatedDynamicAssets
    };
});

export const epicTourWorldChanged = wrapForLogging(( world_items ) => {
    return {
        type: EPICTOUR_WORLDCHANGE,
        world_items: world_items
    };
});

