// @ts-check

import React from "react";
import PropTypes from 'prop-types';
import { TextField, FormControl, InputLabel, Select, Tooltip, IconButton, Typography, Button } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";

/**
 * A set of material-ui-based function-based controlled UI-components
 * - always controlled, that is attached to a managed state/props/redux variable
 * - function-based, using hooks etc, easier with material-UI docs
 * - no static function with 20 parameters to be kept in right order, react components and props, some props are required
 * - use prop type and ts doc, has to be solid 
 * - if we do things right, textfield should work with all the input type, number, etc. 
 * - there should be validation/errorText support 
 */

/**
 * 
 * @param {*} stylingOverrides 
 */
function getCommonStyle(stylingOverrides) {
    return Object.assign({
        minWidth: 300, //if width is 50% but that would mean lower than minWidth, minWidth takes over 
        margin: 12
    }, stylingOverrides);
}

function getCommonProps(value, changeCallback, refCallback, label) {
    var props = {};
    props.key = label;//something that shouldn't changes...
    props.label = label;
    props.onChange = changeCallback;
    props.inputRef = refCallback;
    //we only want controlled now , never null but probably not the place to change it 
    //if (value != null)//https://stackoverflow.com/questions/31163693/how-do-i-conditionally-add-attributes-to-react-components
    props.value = value;
    return props;
}


export const ETToolTitle = (props) => {
    return (<div><Typography variant="h3" gutterBottom>{props.title}</Typography><hr /></div>);
};
ETToolTitle.propTypes = {
    title: PropTypes.string.isRequired,
};

export const ETButton = (props) => {
    return (<Button variant="contained"
        onClick={props.clickCallback}
        style={getCommonStyle(props.stylingOverrides)}>
        {props.label}
    </Button>);
};
ETButton.propTypes = {
    label: PropTypes.string.isRequired,
    clickCallback: PropTypes.func.isRequired,
    stylingOverrides: PropTypes.object,
};

export const ETIconButton = (props) => {
    return (<Tooltip title={props.tooltipLabel}>
        <IconButton onClick={props.clickCallback} >{props.icon}</IconButton>
    </Tooltip>);
};
ETIconButton.propTypes = {
    tooltipLabel: PropTypes.string.isRequired,
    clickCallback: PropTypes.func.isRequired,
    icon: PropTypes.element.isRequired,
};

export const ETTextfield = (props) => {
    var commonProps = getCommonProps(props.value, props.changeCallback, props.refCallback, props.label);
    return (<TextField  {...commonProps}
                        disabled={props.disabled} 
                        style={getCommonStyle(props.stylingOverrides)} 
                        variant="outlined" size="small" margin="dense"  
                        type={props.type} inputProps={props.inputProps}
    />);
};
ETTextfield.propTypes = {
    value: PropTypes.any.isRequired,
    changeCallback: PropTypes.func.isRequired,
    refCallback: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    stylingOverrides: PropTypes.object,
    /**
     * things like inputProps={ min: "0", max: "10", step: "1" }) for type:"number", 
     * https://material-ui.com/api/text-field/
     * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes
     * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types
     */
    inputProps: PropTypes.object, 
    type: PropTypes.string,    
};
ETTextfield.defaultProps = {
    type: "text",
    inputProps:{},
};

export const ETAutocomplete = (props) => {
    var commonProps = getCommonProps(props.value, props.changeCallback, null, props.label);
    return (
        <Autocomplete
            {...commonProps}
            freeSolo
            options={props.options}

            renderInput={(params) => (
                <TextField label={props.label} inputRef={props.refCallback} style={getCommonStyle(props.stylingOverrides)} {...params} variant="outlined" size="small" margin="dense" />
            )}
        />
    );
};
ETAutocomplete.propTypes = {
    value: PropTypes.any.isRequired,
    options: PropTypes.array.isRequired,
    changeCallback: PropTypes.func.isRequired,
    refCallback: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    stylingOverrides: PropTypes.object,
};

export const ETOneLevelSelect = (props) => {
        //could probably turn the select into a textfield with options group too: https://material-ui.com/components/text-fields/
        var commonProps = getCommonProps(props.value, props.changeCallback, props.refCallback, props.label);
        return (
            <FormControl style={getCommonStyle(props.stylingOverrides)}>
                {props.label ? <InputLabel>{props.label}</InputLabel> : null}
                <Select native {...commonProps} >
                    {Object.keys(props.optionsMap).map(v => <option key={v} value={v}>{props.optionsMap[v]}</option>)}
                </Select>
            </FormControl>
        );
};
ETOneLevelSelect.propTypes = {
    value: PropTypes.any.isRequired,
    optionsMap: PropTypes.object.isRequired,
    changeCallback: PropTypes.func.isRequired,
    refCallback: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    stylingOverrides: PropTypes.object,
};

export const ETTwoLevelSelect = (props) => {
    //could probably turn the select into a textfield with options group too: https://material-ui.com/components/text-fields/
    var commonProps = getCommonProps(props.value, props.changeCallback, props.refCallback, props.label);
    var optionGroups = [];
    for (const optionGroupLabel in props.optionsMap) {
        optionGroups.push((
            <optgroup label={optionGroupLabel} key={optionGroupLabel}>
                {   Object.keys(props.optionsMap[optionGroupLabel]).map(v => <option key={v} value={v}>{props.optionsMap[optionGroupLabel][v]}</option>)}
            </optgroup>
        ));
    }
    return (
        <div><FormControl style={getCommonStyle(props.stylingOverrides)} >
            {props.label ? <InputLabel >{props.label}</InputLabel> : null}
            <Select native {...commonProps} >
                {optionGroups}
            </Select>
        </FormControl></div>
    );
};
ETTwoLevelSelect.propTypes = {
    value: PropTypes.any.isRequired,
    optionsMap: PropTypes.object.isRequired,
    changeCallback: PropTypes.func.isRequired,
    refCallback: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    stylingOverrides: PropTypes.object,
};
