// @ts-check
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from 'src/actions/epicTourActions'
import ETToolUtils from './ETToolUtils';
import * as actionsCloudFs from 'src/actions/cloudFsActions'
import BuildIcon from '@material-ui/icons/Build';
import { IconButton, Tooltip } from '@material-ui/core';
import ExternalConfig from 'src/utils/ExternalConfig';

class ETEpicTourTool extends React.Component {

    onTourFolderChange = async (event,value) => {
        const newFolder = this.refSlfolder.value;
        //first reload the list of tour assets which will now be available. 
        await this.props.onTourFolderChange(this.props.externalConfig,newFolder);

        //trigger a full map reload which will take care of things lika <assets>
        var updatadMap = _.cloneDeep(this.props.map);
        updatadMap.meta.tourAssetsRelativePath=newFolder;//not this.refSlfolder.value; which has changed back during previous await
        this.props.onEpicTourMapChanged(updatadMap,
            this.props.epicTourAppConfig.initialMode,
            this.props.epicTourAppConfig.getInitialVisibilityLayerTreeForMode(this.props.epicTourAppConfig.initialMode));
    }

    render() {
        const TourAssetFolderSelectorData = {};
        this.props.tourFolders.forEach(folder => {
            TourAssetFolderSelectorData[folder]=folder
        });

        //(changes here lose non-synced 360-world content)
        return (
            <div>
            {ETToolUtils.buildStaticToolPanelTitle("Epic Tour configuration")}
            {ETToolUtils.buildOneLevelSelect((this.props.meta.tourAssetsRelativePath || null), this.onTourFolderChange, c => (this.refSlfolder = c), "Tour asset folder",TourAssetFolderSelectorData)}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        map: state.epicTour.map,            
        meta: state.epicTour.map.meta,
        tourFolders: state.cloudFs.tourFolders,
        externalConfig:new ExternalConfig(state.externalConfig),          
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onEpicTourMapChanged: (newMap, newMode, allLayersVisibleState) => dispatch(actions.epicTourMapChanged(newMap, newMode, allLayersVisibleState)),  
        onTourFolderChange: (externalConfig,tourFolder) => dispatch(actionsCloudFs.loadTourAssets(externalConfig,tourFolder)),        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ETEpicTourTool);
