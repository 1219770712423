// @ts-check

import jwtDecode from 'jwt-decode';
import { APIS } from 'src/constants';
import axios from 'src/utils/axios';

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        alert("api error! "+error);
        //if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        //}

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  

  loginInWithToken = () => new Promise((resolve, reject) => {
    console.log("loginInWithToken..."+APIS.OAUTH2_USERINFO);
    axios.get(APIS.OAUTH2_USERINFO)
      .then((response) => {
        if (response.data.error) {
          console.log("loginInWithToken...error");
          reject(response.data.error);
        } else {
          console.log("loginInWithToken...ok");
          resolve(response.data);
        }
      })
      .catch((error) => {
        console.log("loginInWithToken...catch");
        reject(error);
      });
  })

  logout = () => {
    this.setSession(null);
  }

  //this must now be called from implicit callback page
  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  }

  getAccessToken = () => localStorage.getItem('accessToken');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    return true; 
    // const decoded = jwtDecode(accessToken);
    // const currentTime = Date.now() / 1000;
    // return decoded.exp > currentTime;
  }

  isAuthenticated = () => !!this.getAccessToken()


}

const authService = new AuthService();
console.log("exporting authService");
export default authService;
