// @ts-check

export default class CanvasAccess{

    constructor(fabricCanvas){
        this.fabricCanvas = fabricCanvas;
    }

    getGroupFor(entityType,entityId){
        var matches = this.fabricCanvas.getObjects().filter(o=> o.entityType===entityType && (entityId==null || o.mapSubType === entityId) );
        return matches.length==1?matches[0]:null;
    }

    swapGroupFor(entityType,entityId,newGroup){
        //just remove and add.
        var existingGroup = this.getGroupFor(entityType,entityId);
        var activeObject = this.fabricCanvas.getActiveObject();
        var isSelected = activeObject!=null && (activeObject.entityType==entityType && activeObject.entityId==entityId );
        if(existingGroup)
            this.fabricCanvas.remove(existingGroup);

        if(!newGroup)
            return;

        //add new one
        newGroup.entityType = entityType;
        newGroup.entityId = entityId;

        const insertPosition = this.findInsertPosition(newGroup.et_z_index);
        //console.log("inseting mapEntity at "+insertPosition+" / "+this.fabricCanvas.getObjects().length);
        if(insertPosition==-1)
            this.fabricCanvas.add(newGroup);
        else
            this.fabricCanvas.insertAt(newGroup,insertPosition);

        //maintain select state
        if(isSelected)
            this.fabricCanvas._setActiveObject(newGroup);// 1, no event firing - http://fabricjs.com/v2-breaking-changes-2

        //TODO solve z-index issues
        //newGroup.bringToFront();
    }
    
    findInsertPosition(zindex){
        var objects = this.fabricCanvas.getObjects();
        for (let index = 0; index < objects.length; index++) {
            if(objects[index].et_z_index > zindex)
                return index;
        }
        return -1;
    }

}