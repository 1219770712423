
// @ts-check 

import _ from 'lodash';

import React from 'react';
import { connect } from 'react-redux';

import { IconButton,  Typography} from '@material-ui/core';
import { ZoomIn, ZoomOut, EmojiPeople } from '@material-ui/icons/'; 
import CanvasWrapper from './CanvasWrapper';
import * as actions from '../../actions/epicTourActions'; //will need to move in lib
import EpicTourMap from './EpicTourMap';
import { EMap2DCategory } from './EpicTourMap';
import ETToolUtils from './tools/ETToolUtils';
import ExternalConfig from 'src/utils/ExternalConfig';

/**
 * the main component containing the floorplan canvas and UI controls immediately related to it such as +/- zoom buttons
 *
 * - state that is impacted by UI controls outside the canvaswrapper but is of no interest beyond this component is kept in local state (e.g.: Zoom)
 * - state that is of interest to all sorts of custom components anywhere in the UI such is 'is in edit mode' is pushed to Redux  
 */

const VIEWS_OPTIONSMAP = {
    VIEW2D3D: "Show both views",
    VIEW2D: "Show 2D map",
    VIEW3D: "Show 360° view",
    VIEW2DX: "Show 2D map +",
    VIEW3DX: "Show 360° view +"    
};

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
});

const staticStyles = {
    hide:{ display: "none" } ,
    inMapButtonStyle: { margin: "4px", padding: "4px", color: 'white', "backgroundColor": 'rgba(100,100,100,0.5)' }
};


class EpicTour2Views extends React.Component {
    constructor(props) {
        super(props);
        this.state = { viewMode: "VIEW2D3D", width: 0, height: 0 };

        // @ts-ignore - global reference for unrelated tools who want to register to canvas event for example, might be better to have EPICTOUR and point to canvas
        window.epicTourComponent = this;
    }

    /*
     * SECTION initialisation code 
     */

    componentDidMount() {
        this.canvasWrapper = new CanvasWrapper(this.props.epicTourAppConfig,this.canvasRef, this.iframeRef, this.props.onMapSelectionChanged, this.props.epicTourMapObjectEdit);
        this.canvasWrapper.setMapContent(new EpicTourMap(this.props.map), this.props.currentMode, this.props.layersVisbleState);
        this.setupManualDynamicViewsResizing();
    }
    componentWillUnmount(){
        this.canvasWrapper.cleanup();
        window.onresize=null;
    }    

    /*
     * SECTION handle manual dynamic resizing of both views 
     */    

     setupManualDynamicViewsResizing = ()=>{
        //anythign that change the layout such as resize, switch to landscape, new scrollbar appearing should cause a redraw
        // @ts-ignore
        window.onresize = this.resizeCanvasToFitParent ;
        this.resizeCanvasToFitParent();
     }

    resizeCanvasToFitParent = () => {
        if(!this.fullRowRef)
            return;
        const fullRowDivWidth = this.fullRowRef.offsetWidth - 25; // room for annotying scrollbars that appears
        const isMobilePortrait = fullRowDivWidth<=500;// like the css media rule allowing to stack up both views in mobile portrait mode 
        const bothViewsVisible = this.state.viewMode.startsWith("VIEW2D3D") ;

        var viewWidth = (isMobilePortrait || !bothViewsVisible) ? fullRowDivWidth : (fullRowDivWidth/2) - 2 ;
        const ratio = this.state.viewMode.endsWith("X")?0.9:0.66
        var viewHeight = (viewWidth>window.innerHeight*ratio)?window.innerHeight*ratio:viewWidth;
        if(viewHeight>window.innerHeight-110)
            viewHeight=window.innerHeight-110;//always some space for top and bottom bar + some margins for OS.

        viewWidth = Math.floor(viewWidth);
        viewHeight = Math.floor(viewHeight);
        console.log("resize to " + viewWidth + "x" + viewHeight+" (fullRowDivWidth:"+fullRowDivWidth +" isMobilePortrait:"+isMobilePortrait+" bothViewsVisible:"+bothViewsVisible+") ");

        // @ts-ignore
        this.iframeRef.width = viewWidth;
        // @ts-ignore
        this.iframeRef.height = viewHeight;

        this.canvasWrapper.fabricCanvas.setWidth(viewWidth);
        this.canvasWrapper.fabricCanvas.setHeight(viewHeight);
        this.canvasWrapper.canvasZoomPanManager.resetZoomAndPan();
    }

    /*
     * SECTION update from redux/parent via props
     */

    componentDidUpdate(prepProps, prepState) {
        //console.log("EpicTour componentDidUpdate");

        //todo - instead of those sagas, might want to update both mode and visible when the mode changes
        //something like map should reset to initialMode and also set visibility
        //review, read doc

        if (!_.isEqual(prepProps.mapLastLoadingEvent, this.props.mapLastLoadingEvent)) {
            this.canvasWrapper.setMapContent(new EpicTourMap(this.props.map), this.props.currentMode, this.props.layersVisbleState);
        } else if (!_.isEqual(prepProps.map.meta.dynamicAssetReferences, this.props.map.meta.dynamicAssetReferences)) {//e.g.: dynamic asset sync but NOT event that triggers the full tour reload as just above (tourAssetsRelativePath)
            this.canvasWrapper.syncDynamicAssets(this.props.map.meta.dynamicAssetReferences);
        }


        if (!_.isEqual(prepProps.mapLastObjectEvent, this.props.mapLastObjectEvent)) {
            this.canvasWrapper.onMapEvent(this.props.mapLastObjectEvent, this.props.map);
        }


        if (prepProps.currentMode !== this.props.currentMode) {
            //trigger a redraw based on new mode
            this.canvasWrapper.updateAfterModeOrToolChange(this.props.currentMode, this.props.currentTool);
            //also update visibility - reset at default for this mode which shoudl also reset whatever component are connected to it
            var allLayersVisibleInitialState = this.props.epicTourAppConfig.getInitialVisibilityLayerTreeForMode(this.props.currentMode);
            this.props.setAllLayersVisibleState(allLayersVisibleInitialState);
        }
        if (!_.isEqual(prepProps.currentTool, this.props.currentTool)) {
            //this.resizeCanvasToFitParent();//ideally, there is a better solution than this. since it cause a zoom reset just from selecting object
            this.canvasWrapper.updateAfterModeOrToolChange(this.props.currentMode, this.props.currentTool);
        }
        if (!_.isEqual(prepProps.mapSelectedObjectId, this.props.mapSelectedObjectId)) {
            this.canvasWrapper.updateAfterSelectionChange(prepProps.mapSelectedObjectId, this.props.mapSelectedObjectId);
        }

        if (!_.isEqual(prepProps.layersVisbleState, this.props.layersVisbleState))
            this.canvasWrapper.updateShownLayers(this.props.layersVisbleState);
    }

    /*
     * SECTION in-canvas buttons
     */

    zoomIn = () => {
        this.canvasWrapper.zoomIn();
    }
    zoomOut = () => {
        this.canvasWrapper.zoomOut();
    }
    goTo3d = () => {
        this.props.setOneLayerVisibleState();
    }

    /*
     * SECTION handle UI common buttons - some now handled on project side
     */

    // modeToggle = (e) => {
    //     const newMode = e.target.checked ? EModes.EDIT : EModes.VIEW;
    //     this.props.onEpicTourModeChanged(newMode);
    // }

    // handleToolSelection = (event, newTool) => {
    //     this.props.onToolChange(newTool);
    // }

    handleViewSelection = () => {
        console.log("handleViewSelection");
        this.setState({ viewMode: this.refSlViewMode.value }, () => {
                console.log("handleViewSelection postStateChange");
                this.resizeCanvasToFitParent();
        })
    }

    render() {
        var viewSelector = ETToolUtils.buildOneLevelSelect(this.state.viewMode, this.handleViewSelection, c => (this.refSlViewMode = c), null, VIEWS_OPTIONSMAP, { minWidth: 10, margin: 4 });
        // http://epictour360browser.s3-website.eu-west-2.amazonaws.com
        // https://epictour360browser.s3.eu-west-2.amazonaws.com/index.html
        // http://localhost:8080/
        return (
            <div>
                <div className="myrow" ref={c => (this.fullRowRef = c)} >
                    <div className="mycolumn" id="mapColumn" ref={c => (this.canvasContainerRef = c)} style={ (this.state.viewMode == "VIEW3D"||this.state.viewMode == "VIEW3DX") ?staticStyles.hide:{}} >
                        {this.props.viewsLabel?<Typography variant="body2" gutterBottom>2D Map</Typography>:null}
                        <div style={{ position: "relative" }}>
                            <div className="canvasbuttons">
                                <IconButton onClick={this.zoomIn} style={staticStyles.inMapButtonStyle}>
                                    <ZoomIn />
                                </IconButton>
                                <IconButton onClick={this.zoomOut} style={staticStyles.inMapButtonStyle}>
                                    <ZoomOut />
                                </IconButton>
                                <IconButton onClick={this.goTo3d} style={staticStyles.inMapButtonStyle}>
                                    <EmojiPeople />
                                </IconButton>
                            </div>
                        </div>
                        <canvas id="mycanvas" width="300" height="300" ref={c => (this.canvasRef = c)} />


                    </div>
                    <div className="mycolumn" style={(this.state.viewMode == "VIEW2D"||this.state.viewMode == "VIEW2DX") ?staticStyles.hide:{}} >
                        {this.props.viewsLabel?<Typography variant="body2" gutterBottom>360° view</Typography>:null}
                        <div ref={c => (this.iframeWrappingDivRef = c)}  >
                            { /* @ts-ignore */  }
                            <iframe id="myIframe" ref={c => (this.iframeRef = c)} scrolling="no" style={{borderLeft:"1px solid #ffffff"}} frameBorder="0" src={this.props.externalConfig.aframeUrl} allowFullScreen="yes" allowvr="yes">
                                <p>Your browser does not support iframes.</p>
                            </iframe>
                        </div>
                    </div>
                </div>
                <div style={{ position: "relative" }}>
                    {viewSelector}
                    {this.props.children}
                </div>
            </div>);
    }
}

const mapStateToProps = state => {
    return {
        map: state.epicTour.map,
        mapLastLoadingEvent: state.epicTour.mapLastLoadingEvent,
        mapLastObjectEvent: state.epicTour.mapLastObjectEvent,
        mapSelectedObjectId: state.epicTour.mapSelectedObjectId,
        currentMode: state.epicTour.mode,
        currentTool: state.epicTour.tool,
        layersVisbleState: state.epicTour.layersVisbleState,
        externalConfig:new ExternalConfig(state.externalConfig),
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onMapSelectionChanged: (mapObjectId) => dispatch(actions.epicTourMapSelectionChanged(mapObjectId)),
        onEpicTourMapChanged: (newMap, newMode, allLayersVisibleState) => dispatch(actions.epicTourMapChanged(newMap, newMode, allLayersVisibleState)),
        setAllLayersVisibleState: (layersVisibleState) => dispatch(actions.setAllLayersVisibleState(layersVisibleState)),
        setOneLayerVisibleState: () => dispatch(actions.setOneLayerVisibleState(EMap2DCategory.SCENE.name,null)),
        epicTourMapObjectEdit: (mapLayer, editedObject) => dispatch(actions.epicTourMapObjectEdit(mapLayer, editedObject)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EpicTour2Views);
