// @ts-check 

import { fabric } from 'fabric-with-gestures' 
import ETDrawer from 'src/components/epictour/drawers/ETDrawer';

const IMAGES_MAP = {
    LONG_REACH:"long_reach_green.png",
    SLOPPY_HOLDS:"sloppy_holds_green.png",
}

const ICON_SIZE = 40 ;

class SymbolDrawer extends ETDrawer {

    static getStaticImagesNames(){
        return Object.values(IMAGES_MAP);
    }

    getStyle(){
        return {
            stroke: 'black',
            strokeWidth: 3
        }
    }

    buildCanvasObjects(inputObject,relatedMpaObjects){
        var pointFPx = this.canvasCoordinatesConverter.meterPointTofpxUnitPoint(inputObject);
        var oImg = this.canvasResourceManager.getImageResource(IMAGES_MAP[inputObject.symbol_type]);
        oImg.set({
            left: pointFPx.x ,
            top: pointFPx.y ,
            originX:"center",
            originY:"center",    
        });
        oImg.scale(ICON_SIZE/oImg.originalWidth);
        return [oImg];
    }
};

export default SymbolDrawer;