// @ts-check 

import { fabric } from 'fabric-with-gestures' 

const METER_TO_FABRICPIXEL = 100 ; 

class CanvasCoordinatesConverter{

    constructor(fabricCanvas) {
        this.fabricCanvas = fabricCanvas;
    }

    getCanvasPixelCenterPoint(){
        return {x:this.getCanvasHtmlElementCurrentPixelWidth()/2,y:this.getCanvasHtmlElementCurrentPixelHeight()/2};
    }

    isInsideCanvas(canvasPxPoint){
        const inside = canvasPxPoint.x >= 0 && canvasPxPoint.x <= this.getCanvasHtmlElementCurrentPixelWidth() && canvasPxPoint.y >= 0 && canvasPxPoint.y <= this.getCanvasHtmlElementCurrentPixelHeight()  ; 
        //console.log("inside "+inside+" "+canvasPxPoint.x+" "+canvasPxPoint.y);
        return inside ; 
    }

    getCanvasHtmlElementCurrentPixelWidth(){
        return this.fabricCanvas.getWidth();
    }
    getCanvasHtmlElementCurrentPixelHeight(){
        return this.fabricCanvas.getHeight();
    }

    getCurrentFabricjsPixelWidth(){
        return this.getCanvasHtmlElementCurrentPixelWidth() / this.fabricCanvas.getZoom();
    }
    getCurrentFabricjsPixelHeight(){
        return this.getCanvasHtmlElementCurrentPixelHeight() / this.fabricCanvas.getZoom();
    }

    getCurrentMeterWidth(){
        return this.getCurrentFabricjsPixelWidth() / METER_TO_FABRICPIXEL;
    }
    getCurrentMeterHeight(){
        return this.getCurrentFabricjsPixelHeight() / METER_TO_FABRICPIXEL;
    }

    fpx2m(fpx){
        return fpx / METER_TO_FABRICPIXEL;
    }
    m2fpx(meters){
        return meters * METER_TO_FABRICPIXEL;
    }

    fpxUnitPointToMeterPoint(pointInFpxUnit){
        return {x:this.fpx2m(pointInFpxUnit.x),y:this.fpx2m(pointInFpxUnit.y)}
    }
    meterPointTofpxUnitPoint(pointInMeters){
        return {x:this.m2fpx(pointInMeters.x),y:this.m2fpx(pointInMeters.y)}
    }
};

export default CanvasCoordinatesConverter; 