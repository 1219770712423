// @ts-check

import React, { Component } from 'react';
import ETPointItemTool from 'src/components/epictour/tools/ETPointItemTool';
import ETToolUtils from 'src/components/epictour/tools/ETToolUtils';
import { PROTECTION_TYPES_OPTIONSMAP } from 'src/constants';



class ProtectionTool extends React.Component {

    constructor(props) {
        super(props);
        this.child = React.createRef();
    }

    getCustomFormContent = () => {
        return { note: this.refTfNote.value, protection_type: this.refSlProtectionType.value };
    }

    buildCustomForm = (edit) => {
        this.formChangeCallback = () => { this.child.current.modifyMapObject(this.getCustomFormContent()) };
        return (<div>
            {ETToolUtils.buildTwoLevelSelect((this.props.mapSelectedItem?.protection_type || null), this.formChangeCallback, c => (this.refSlProtectionType = c), "Protection/Gear type",PROTECTION_TYPES_OPTIONSMAP)}
            {ETToolUtils.buildTextfield((this.props.mapSelectedItem?.note || null), this.formChangeCallback, c => (this.refTfNote = c), "note")}
        </div>);
    }

    render() {
        return (<ETPointItemTool ref={this.child} topic="Protection"
            mapSelectedItem={this.props.mapSelectedItem}
            getCustomFormContent={this.getCustomFormContent}
            formNew={this.buildCustomForm(false)}
            formEdit={this.buildCustomForm(true)}
            mapLayer={this.props.mapLayer}
            epicTourAppConfig={this.props.epicTourAppConfig}
        />);
    }
}
export default ProtectionTool;