//@ts-check

import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

function AuthGuard({ children }) {
  // @ts-ignore
  const account = useSelector((state) => state.account);

  if (!account.user) {
    //note there are 3 ways to log out: the logout button, axios-token-failure and guard breach
    console.log("AuthGuard - account.user not defined, redirecting to anonymous section");
    return <Redirect to="/home" />;
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any
};

export default AuthGuard;
