
// @ts-check

import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from 'src/actions/epicTourActions'
import { makeStyles, IconButton, withStyles, Typography, Tooltip, TextField, Checkbox, FormControlLabel, Link } from '@material-ui/core';
import ETToolUtils from 'src/components/epictour/tools/ETToolUtils';

import PlusOneIcon from '@material-ui/icons/PlusOne';
import ExposureNeg1Icon from '@material-ui/icons/ExposureNeg1';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import EnhancedTable from 'src/views/dashboard/GenericTable';
import ExternalConfig from 'src/utils/ExternalConfig';
import _ from 'lodash';

const levelBaseUrl = "https://epictour360.s3.eu-west-2.amazonaws.com/core_adventure/" ; 

const ORIGIN_OPTIONSMAP = {
    library: "Assets from library",
    tour: "Assets referenced in tour",
};

const TYPE_OPTIONSMAP = {
    all: "All",
    image: "Images",
    sound: "Sounds",
    model: "Models",
    video: "Videos",
};

const TYPE_FORMATS= {
    image: "png,jpg,jpeg",
    sound: "wav,mp3",
    model: "glb",
    video: "mp4",
};

function AssetNameCellDrawer (props) {
    //return (<div>{props.row.path}</div>);
    return (<Tooltip title={props.row.path}><div>{props.row.id}</div></Tooltip>)
}
function AssetTypeCellDrawer (props) {
    return (<div>{props.row.type}</div>)
  }
  function AssetStatusCellDrawer (props) {
    return (<div>{props.row.status}</div>)
  }
  function AssetLinkCellDrawer (props) {
    return (<div><a href={props.row.link} target="_blank">link</a></div>)
  }
  function AssetPreviewCellDrawer (props) {
    switch(props.row.type){
        case "image": return (<img src={props.row.link} width={150} height={150}/>);
        case "sound": return (<audio controls>
                                    <source src={props.row.link} ></source>
                            </audio>);
        case "video": return (<video width="150" height="150" controls>
                                <source src={props.row.link} type="video/mp4"/>
                            </video>);
    }

    return (<div>-</div>)
  }


class Diagnostic {
    constructor(dynamicAssetTourReferences,dynamicAssets,world_items){
        this.dynamicAssetTourReferences = dynamicAssetTourReferences;
        this.dynamicAssets = dynamicAssets;
        this.world_items_str = JSON.stringify(world_items);

        this.assetReferencesInfoList = [];
        for (const dynamicAssetTourReference of this.dynamicAssetTourReferences) {
            const matches = this.world_items_str.match(new RegExp("\"#"+dynamicAssetTourReference.name+"\"", "g"));
            const useCount = matches?matches.length:0;
            const exists = dynamicAssets.includes(dynamicAssetTourReference.path);
            this.assetReferencesInfoList.push({
                name:dynamicAssetTourReference.name,
                path:dynamicAssetTourReference.path,
                useCount:useCount,
                exists:exists
            });
        }

        this.assetActualUsagesInfoList = [];
        // [""src":"#button-black-1-normal"", ""src":"#hotspotimage"", ""src":"#hotspotimage""]
        var assetsUsed = this.world_items_str.match(new RegExp("\"src\":\"#.*?\"","g"));
        if(!assetsUsed)
            return;
        assetsUsed = assetsUsed.map(a=>a.substr(8,a.length-9)).filter(a=>a!=="hotspotimage");
        var countedAssetsUsed = _.countBy(assetsUsed);
        //debugger;
        for (const assetName in countedAssetsUsed) {
            const referenced = this.dynamicAssetTourReferences.filter(r=>r.name===assetName).length>0;
            const useCount = countedAssetsUsed[assetName];
            const exists = this.dynamicAssets.filter(a=>a.includes("/"+assetName+".")).length>0;
            this.assetActualUsagesInfoList.push({
                name:assetName,
                referenced:referenced,
                useCount:useCount,
                exists:exists
            });
        }
    }

    getDynamicAssetsReferencedTotalCount = () => {
        return this.assetReferencesInfoList.length;
    }
    getDynamicAssetsReferencedUsedCount = () => {
        return this.assetReferencesInfoList.filter(a=>a.useCount>0).length;
    }
    getDynamicAssetsReferencedExistsCount = () => {
        return this.assetReferencesInfoList.filter(a=>a.exists).length;
    }

    getDynamicAssetsUsageTotalCount = () => {
        return this.assetActualUsagesInfoList.length;
    }
    getDynamicAssetsUsageReferencedCount = () => {
        return this.assetActualUsagesInfoList.filter(a=>a.referenced).length;
    }
    getDynamicAssetsUsageExistsCount = () => {
        return this.assetActualUsagesInfoList.filter(a=>a.exists).length;
    }    

    getProblemList = ()=> {
        var problems = [];
        for (const assetReference of this.assetReferencesInfoList) {
            if(assetReference.useCount==0 || !assetReference.exists)
                problems.push("the refereence asset '"+assetReference.name+"' is "+(assetReference.useCount==0?" UNUSED":"")+""+(!assetReference.exists?" NON-EXISTENT":""))
        }
        for (const assetActualUsages of this.assetActualUsagesInfoList) {
            if(!assetActualUsages.referenced || !assetActualUsages.exists)
                problems.push("the used asset '"+assetActualUsages.name+"' ("+assetActualUsages.useCount+") is "+(!assetActualUsages.referenced?" UNREFERENCED":"")+""+(!assetActualUsages.exists?" NON-EXISTENT":""))
        }
        return problems;
    }
}


class AssetViewerTool extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            origin:"library",
            type:"all",
            filter:""
        };
      }

      showProblems = (diagnostic) => {
        alert( diagnostic.getProblemList().map(p=>"* "+p+"\n").join("") ); 
      }

    generateProblemReportFromDiagnosticDate = (diagnostic) => {
        const darTotalCount = diagnostic.getDynamicAssetsReferencedTotalCount();
        const darUsedCount = diagnostic.getDynamicAssetsReferencedUsedCount();
        const darExistsCount = diagnostic.getDynamicAssetsReferencedExistsCount();

        const dauTotalCount = diagnostic.getDynamicAssetsUsageTotalCount();
        const dauReferencedCount = diagnostic.getDynamicAssetsUsageReferencedCount();
        const dauExistsCount = diagnostic.getDynamicAssetsUsageExistsCount();        
        return (<div>
                <Typography variant="caption">
                <span>DIAGNOSTIC: {darTotalCount} assets referenced in current tour (</span>
                <span style={{color:((darUsedCount==darTotalCount)?"black":"orange")}}>{darUsedCount+"/"+darTotalCount}</span>
                <span>&nbsp;used and&nbsp;</span>
                <span style={{color:((darExistsCount==darTotalCount)?"black":"red")}}>{darExistsCount+"/"+darTotalCount}</span>
                <span>&nbsp;exist).&nbsp;</span>
                </Typography>

                <Typography variant="caption">
                <span>DIAGNOSTIC: {dauTotalCount} assets actually currently used in current tour (</span>
                <span style={{color:((dauReferencedCount==dauTotalCount)?"black":"red")}}>{dauReferencedCount+"/"+dauTotalCount}</span>
                <span>&nbsp;referenced and&nbsp;</span>
                <span style={{color:((dauExistsCount==dauTotalCount)?"black":"red")}}>{dauExistsCount+"/"+dauTotalCount}</span>
                <span>&nbsp;exist).&nbsp;</span>
                <Link onClick={()=>this.showProblems(diagnostic)}>-MORE-</Link>
                <Link onClick={()=>this.showProblems(diagnostic)}>-RESYNC-</Link>
                </Typography>                
                </div>
        );
    }

    getFullAssetList = () => {
        return this.state.origin==="library"?
            this.props.dynamicAssets.filter(path => this.getFileType(path)!=null)
                                    .map(da=>{return{path:da,name:da.split("/").pop().split(".")[0]}})://from path to name without extension 
            this.props.dynamicAssetTourReferences;
    }

    getFileType = (filepath) => {
        const extension = filepath.split(".")[1] ;
        for(const type in TYPE_FORMATS){
            if(TYPE_FORMATS[type].includes(extension))
                return type;
        }
        return null; 
    }

    getFilteredAssetList = () =>{
        var rawList = this.getFullAssetList();
        //in our files, no '.', no '/', no ' ', no duplicate name accross folders
        var filteredList = rawList.filter( function (e) {
            return      (this.state.type=="all" || this.state.type === this.getFileType(e.path)) 
                    &&  (this.state.filter==null || e.name.includes(this.state.filter));
        }.bind(this) );
        return filteredList;
    }

    formChangeCallback = () => {
        console.log("formChangeCallback "+this.refTffilter.value);
        this.setState( {
            origin:this.refSlOrigin.value,
            type:this.refSlType.value,
            filter:this.refTffilter.value
        });
    }

    copyToClipBoard = (str) =>{
        const el = document.createElement('textarea');
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    handleInclude = (rowIds) => {
        var updatedDynamicAssets = _.cloneDeep(this.props.dynamicAssetTourReferences);
        for (const rowId of rowIds) {
            var correspondingResource = this.getFilteredAssetList().find(row=>row.name===rowId);
            updatedDynamicAssets.push(correspondingResource);
        }
        this.props.epicTourDynamicAssetsChanged(updatedDynamicAssets);
    }
    handleDoNotInclude = (rowIds) => {
        var updatedDynamicAssets = _.cloneDeep(this.props.dynamicAssetTourReferences);
        updatedDynamicAssets = updatedDynamicAssets.filter( asset => !rowIds.includes(asset.name ));
        this.props.epicTourDynamicAssetsChanged(updatedDynamicAssets);
    }
    handleIncludeAndCopyToClipboard = (rowId) => {
        const alreadyReferenced = this.props.dynamicAssetTourReferences.map(da=>da.name).find(name => name===rowId)!=null;
        if(!alreadyReferenced){
            var updatedDynamicAssets = _.cloneDeep(this.props.dynamicAssetTourReferences);
            var correspondingResource = this.getFilteredAssetList().find(row=>row.name===rowId);
            updatedDynamicAssets.push(correspondingResource);
            this.props.epicTourDynamicAssetsChanged(updatedDynamicAssets);
        }
        this.copyToClipBoard("#"+rowId);
    }        

    render() {
        const diagnostic = new Diagnostic(this.props.dynamicAssetTourReferences,this.props.dynamicAssets,this.props.world_items);

        const resources = this.getFilteredAssetList();
        const cellsInfos = [
            {id:"id",label:"Name",numeric:false,drawerBuilder: (row)=> {return (<AssetNameCellDrawer row={row}/>)} },
            {id:"type",label:"Type",numeric:false,drawerBuilder: (row)=> {return (<AssetTypeCellDrawer row={row}/>)} },
            {id:"status",label:"Referenced?",numeric:false,drawerBuilder: (row)=> {return (<AssetStatusCellDrawer row={row}/>)} },
            {id:"link",label:"Link",numeric:false,drawerBuilder: (row)=> {return (<AssetLinkCellDrawer row={row}/>)} },
            {id:"preview",label:"Preview",numeric:false,drawerBuilder: (row)=> {return (<AssetPreviewCellDrawer row={row}/>)} },
          ];
          const tableActions = [];
          const selectedRowsActions = [
            { label:"include",handler:this.handleInclude,icon:<PlusOneIcon/>},
            { label:"do not include",handler:this.handleDoNotInclude,icon:<ExposureNeg1Icon/>},
          ];
          const rowActions = [
            { label:"Include and copy #ID to clipboard",handler:this.handleIncludeAndCopyToClipboard,icon:<DoubleArrowIcon/>},
          ];
          const rows = resources.map(r=>{
              const referencedInTour = this.props.dynamicAssetTourReferences.map(r=>r.path).includes(r.path);
              const type = this.getFileType(r.path);
              const link = this.props.externalConfig.getAssetsDynamicRootUrl()+r.path;
            return {
                id:r.name,
                name:r.name,
                path:r.path,
                type:type,
                status:(referencedInTour?"yes":"no"),
                link:link,
            };
          });

        return (
            <div>
                {ETToolUtils.buildStaticToolPanelTitle("Dynamic Asset Manager")}
                {this.generateProblemReportFromDiagnosticDate(diagnostic)}
                <hr/>
                {ETToolUtils.buildOneLevelSelect(this.state.origin, this.formChangeCallback, c => (this.refSlOrigin = c), "from", ORIGIN_OPTIONSMAP,{minWidth:140})}
                {ETToolUtils.buildOneLevelSelect(this.state.type, this.formChangeCallback, c => (this.refSlType = c), "type", TYPE_OPTIONSMAP,{minWidth:140})}
                {ETToolUtils.buildTextfield(this.state.filter, this.formChangeCallback, c => (this.refTffilter = c), "filter",false)}
                <hr/>
                <EnhancedTable title="Dynamic assets" 
                    dense={false}
                    rows={rows}
                    tableActions={tableActions}
                    selectedRowsActions={selectedRowsActions}
                    rowActions={rowActions}
                    cellsInfos={cellsInfos}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        dynamicAssetTourReferences: state.epicTour.map.meta.dynamicAssetReferences,
        world_items: state.epicTour.map.world_items,
        dynamicAssets: state.cloudFs.dynamicAssets,        
        externalConfig:new ExternalConfig(state.externalConfig),        
    };
}

const mapDispatchToProps = dispatch => {
    return {
        epicTourDynamicAssetsChanged: (updatedDynamicAssets) => dispatch(actions.epicTourDynamicAssetsChanged(updatedDynamicAssets)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetViewerTool);
