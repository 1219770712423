/* eslint-disable no-param-reassign */
// @ts-check

import produce from 'immer';
import * as ACTIONS from 'src/actions/epicTourActions';
import { map } from 'lodash';
import EpicTourMap, { EMap2DCategory } from 'src/components/epictour/EpicTourMap'; 

const initialState = {
  map: null,
  mapLastLoadingEvent: null,
  mapLastObjectEvent: null,
  mapSelectedObjectId: null, 
  mode: null,
  tool:null,
  layersVisbleState: {}
};

function guidGenerator() {
  var S4 = function() {
      return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
  };
  return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}

const getFieldNameForLayer = (action) => { return EMap2DCategory[action.payload.mapLayer.mapCategory].fieldName ; }

const epicTourReducer = (state = initialState, action) => {
  switch (action.type) {

    case ACTIONS.EPICTOUR_MAPCHANGE: {
      return produce(state, (draft) => {
        draft.map = action.newMap;
        draft.mode = action.newMode;
        draft.layersVisbleState = action.layersVisibleState;

        draft.mapLastLoadingEvent = {
          counter: state.mapLastLoadingEvent == null ? 1 : (state.mapLastLoadingEvent.counter + 1),
          name: action.newMap.meta.name
        }
      });
    }

    case ACTIONS.EPICTOUR_METACHANGE: {
      var draft = JSON.parse(JSON.stringify(state));
      draft.map.meta = action.newMeta;
      return draft;
      // return produce(state, (draft) => {
      //   //draft.map.meta = JSON.parse(JSON.stringify(action.newMeta));
      //   draft.map.meta.name = action.newMeta.name;
      // });
    }

    case ACTIONS.EPICTOUR_DYNAMICASSETSCHANGE: {
      return produce(state, (draft) => {
        draft.map.meta.dynamicAssetReferences = JSON.parse(JSON.stringify(action.updatedDynamicAssets));
      });
    }        

    case ACTIONS.EPICTOUR_WORLDCHANGE: {
      return produce(state, (draft) => {
        draft.map.world_items= action.world_items;
      });
    }        


    case ACTIONS.EPICTOUR_MAP_SELECTION_CHANGE: {
      return produce(state, (draft) => {
        draft.mapSelectedObjectId = action.mapObjectId;
        //a selection kills the current adding-tool
        //if(action.mapObjectId)
        draft.tool = null; 
      });
    }

    

    case ACTIONS.EPICTOUR_MAP_OBJECT_ADD: {
      return produce(state, (draft) => {
        action.payload.newObject.id = guidGenerator() ;
        const field = getFieldNameForLayer(action);
        const modifiedContent = JSON.parse(JSON.stringify(state.map[field]));
        if(action.payload.mapLayer.mapSubType)
          action.payload.newObject['subtype'] = action.payload.mapLayer.mapSubType;
        modifiedContent.push(action.payload.newObject);
        draft.map[field] = modifiedContent ;
        draft.mapLastObjectEvent = {
          counter: state.mapLastLoadingEvent == null ? 1 : (state.mapLastLoadingEvent.counter + 1),
          action: action
        }
      });
    }

    case ACTIONS.EPICTOUR_MAP_OBJECT_EDIT: {
      //return produce(state, (draft) => {
      var draft = JSON.parse(JSON.stringify(state));

        const field = getFieldNameForLayer(action);
        if(Array.isArray(state.map[field])){
          const modifiedContent = JSON.parse(JSON.stringify(state.map[field])).filter(o=> action.payload.editedObject.id!==o.id);
          modifiedContent.push(action.payload.editedObject);
          draft.map[field] = modifiedContent ;
        }else
          draft.map[field] = action.payload.editedObject ;
        draft.mapLastObjectEvent = {
          counter: state.mapLastLoadingEvent == null ? 1 : (state.mapLastLoadingEvent.counter + 1),
          action: action
        }
//      });
        return draft;
    }

    case ACTIONS.EPICTOUR_MAP_OBJECT_REMOVE: {
      return produce(state, (draft) => {
        const field = getFieldNameForLayer(action);
        const modifiedList = state.map[field].filter(o=> action.payload.objectId!==o.id);
        draft.map[field] = modifiedList ;
        draft.mapLastObjectEvent = {
          counter: state.mapLastLoadingEvent == null ? 1 : (state.mapLastLoadingEvent.counter + 1),
          action: action
        }
      });
    }

    case ACTIONS.EPICTOUR_MODECHANGE: {
      return produce(state, (draft) => {
        draft.mode = action.newMode;
        //a mode change kills tool and selection
        draft.tool = null; 
        draft.mapSelectedObjectId = null; 
      });
    }

    case ACTIONS.EPICTOUR_TOOLCHANGE: {
      return produce(state, (draft) => {
        draft.tool = action.newTool;
        //a change in tool kills all selection
        draft.mapSelectedObjectId = null; 
      });
    }    
    case ACTIONS.EPICTOUR_TOOLCHANGEFORSELECTION: {
      return produce(state, (draft) => {
        draft.tool = action.newTool;
        //contrary to previous, here we keep both!
        draft.mapSelectedObjectId = action.mapObjectId; 
      });
    }        

    case ACTIONS.EPICTOUR_SETALLLAYERSVISIBLESTATE: {
      return produce(state, (draft) => {
        draft.layersVisbleState = action.layersVisibleState;
      });
    }
    case ACTIONS.EPICTOUR_SETONELAYERVISIBLESTATE: {
      return produce(state, (draft) => {
        //var newLayersVisbleState = JSON.parse(JSON.stringify());
        if(action.mapSubType)
          draft.layersVisbleState[action.mapCategory][action.mapSubType] = !state.layersVisbleState[action.mapCategory][action.mapSubType];
        else 
          draft.layersVisbleState[action.mapCategory] = !state.layersVisbleState[action.mapCategory];
      });
    }

    default: {
      return state;
    }
  }
};

export default epicTourReducer;
