
// @ts-check

export default class ETUtils {

    static getClassName(obj){
        return obj.__proto__.constructor.name;
    }

    static getClassNameFromClass(clazz){
        return clazz.name;
    }    

    static getMeterValueAsString(v) {
        return v!=null?Number.parseFloat(v).toFixed(2):''; 
    }

}