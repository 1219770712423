
// @ts-check

import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from 'src/actions/epicTourActions'


import { TextField, Typography } from '@material-ui/core';
import ETToolFormDeleteButton from 'src/components/epictour/tools/ETToolFormDeleteButton';
import ET2DPointForm from 'src/components/epictour/tools/ET2DPointForm';
import { MapLayer, EMap2DCategory } from 'src/components/epictour/EpicTourMap';
import { ETCanvasEventEnum, ToolDelegateInfo } from 'src/components/epictour/CanvasEventBroadcaster';
import ETUtils from 'src/components/epictour/utils/ETUtils';
import { MAP_CUSTOM_ITEMS } from 'src/constants';
import ETToolUtils from './ETToolUtils';


/**
 * 
 * 
 * child ref/redux/all that
 * https://stackoverflow.com/questions/37949981/call-child-method-from-parent
 * https://stackoverflow.com/questions/53819335/react-redux-v6-withref-is-removed-to-access-the-wrapped-instance-use-a-ref-on
 */


class ETPointItemTool extends React.Component {

    componentDidMount() {
        if(this.isInSelectionControlledMode())
            return;
        const toolDelegateInfo = new ToolDelegateInfo(ETCanvasEventEnum.SINGLE_CLICK_TOUCH,this.canvasClickCallback.bind(this),null,null);
        // @ts-ignore
        window.epicTourComponent.canvasWrapper.addToolDelegate(toolDelegateInfo);
    }

    componentWillUnmount(){
        if(this.isInSelectionControlledMode())
            return;
        // @ts-ignore
        window.epicTourComponent.canvasWrapper.removeToolDelegate();
    }

    isInSelectionControlledMode(){
        return this.props.mapSelectedItem != null ;
    }    

    canvasClickCallback(e,pointM) {
        if(!this.isInSelectionControlledMode()){
            //if the form is not in selection Mode and also validated/clean, take the current form values adn add a new scene in the redux level 
            var newMapObject = this.props.getCustomFormContent(pointM);
            Object.assign(newMapObject,{x:pointM.x,y:pointM.y})
            this.props.epicTourMapObjectAdd(this.props.mapLayer,newMapObject);
        }
    }

    modifyMapObject = (editedObjectModifications) => {
        if(this.isInSelectionControlledMode()){
            let editedObject = JSON.parse(JSON.stringify(this.props.mapSelectedItem));
            Object.assign(editedObject,editedObjectModifications);
            this.props.epicTourMapObjectEdit(this.props.mapLayer,editedObject) ;
        }
    }

    deleteClickCallback = async () => {
        if(this.isInSelectionControlledMode()){
            const id = this.props.mapSelectedItem.id;
            console.log("deleteClickCallback "+id);
            //debugger;
            await this.props.epicTourMapObjectRemove(this.props.mapLayer,id);
            if(this.props.postDeletionCallback)
                this.props.postDeletionCallback(id);
        }
    }

    render(){
        //defaultValue changes does not trigger a re-render so we can use the key hack https://github.com/mui-org/material-ui/issues/1328#issuecomment-198087347

        return this.isInSelectionControlledMode()?(
            <div>
                {ETToolUtils.buildDynamicToolPanelTitle(true,this.props.topic)}  
                {this.props.formEdit}
                <ET2DPointForm point={this.props.mapSelectedItem}/>
                <ETToolFormDeleteButton deleteClickCallback={this.deleteClickCallback}/>
            </div>
        ):(
            <div>
                {ETToolUtils.buildDynamicToolPanelTitle(false,this.props.topic)}  
                {this.props.formNew}
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {  };
}


const mapDispatchToProps = dispatch => {
    return {
        epicTourMapObjectAdd: (mapLayer,newObject) => dispatch(actions.epicTourMapObjectAdd(mapLayer,newObject)),
        epicTourMapObjectEdit: (mapLayer,editedObject) => dispatch(actions.epicTourMapObjectEdit(mapLayer,editedObject)),
        epicTourMapObjectRemove: (mapLayer,objectId) => dispatch(actions.epicTourMapObjectRemove(mapLayer,objectId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(ETPointItemTool);
