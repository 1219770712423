// @ts-check 

import { fabric } from 'fabric-with-gestures' 
import ETDrawer from 'src/components/epictour/drawers/ETDrawer';


const IMAGES_MAP = {
    CAM1:"cam_red.png",
    CAM2:"cam_purple.png"
}

const ICON_SIZE = 40 ;

class ProtectionDrawer extends ETDrawer {

    static getStaticImagesNames(){
        return Object.values(IMAGES_MAP);
    }

    getStyle(){
        return {
            stroke: 'black',
            strokeWidth: 3
        }
    }

    buildCanvasObjects(inputObject,relatedMpaObjects){
        var pointFPx = this.canvasCoordinatesConverter.meterPointTofpxUnitPoint(inputObject);
        var oImg = this.canvasResourceManager.getImageResource(IMAGES_MAP[inputObject.protection_type]);
        oImg.set({
            left: pointFPx.x ,
            top: pointFPx.y ,
            originX:"center",
            originY:"center",    
        });
        oImg.scale(ICON_SIZE/oImg.originalWidth);
        return [oImg];
    }
};

export default ProtectionDrawer;
