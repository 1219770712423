// @ts-check

import React from 'react';
import ETPointItemTool from 'src/components/epictour/tools/ETPointItemTool';
import ETToolUtils from 'src/components/epictour/tools/ETToolUtils';
import { connect } from 'react-redux';
import EpicTourMap, { EMap2DCategory } from '../EpicTourMap';
import * as actions from 'src/actions/epicTourActions'

const USED_SUFFIX = " *"

class ETSceneTool extends React.Component {

    constructor(props){
        super(props);
        this.child = React.createRef();
    }

    getCustomFormContent = () => {
        //the one last picked in list or the edited item one or null
        const photoValue = this.savedPhotoValue?this.savedPhotoValue:this.props.mapSelectedItem?.panoramic_photo;
        return {name:this.refTfName.value,panoramic_photo:photoValue};
    }

    postDeletionCallback = async (id) => {
        //we drive the deletion of related segues from here, will be propagate to redux > canvasWrapper > fabric + aframe 
        const badSegues = this.props.map.getMapObjectsWithReferencesTo(id)
                                        .filter(o=>o.mapLayer.mapCategory === EMap2DCategory.SEGUE.name);
        for(const badSegue of badSegues)
            await this.props.epicTourMapObjectRemove(badSegue.mapLayer,badSegue.mapObject.id);
    }
    
    buildCustomForm = (edit) => {
        var tourAssets = this.props.tourAssets.map(asset=>{
            const used = this.props.map.isTourAssetPhotoUsed(asset);
            return used?asset+USED_SUFFIX:asset;
        });
        var currentPhoto = this.props.mapSelectedItem?.panoramic_photo ;
        if( currentPhoto && this.props.map.isTourAssetPhotoUsed(currentPhoto) )
            currentPhoto=currentPhoto+USED_SUFFIX;
        //pick latest unused if in add mode. 
        if(!this.props.mapSelectedItem){
            const firstUnused = this.props.tourAssets.find(asset=>!this.props.map.isTourAssetPhotoUsed(asset));
            currentPhoto= firstUnused?firstUnused:null; //local used for component
            this.savedPhotoValue=currentPhoto; //class attribute used when adding, would be better to rewrite and be always a controlled component...
        }

        this.formChangeCallback = () => { this.child.current.modifyMapObject(this.getCustomFormContent()) };
        this.formChangeCallbackSpecial = (event,value) => { 
            this.savedPhotoValue = value.replace(USED_SUFFIX,"");//because we cannot get to autocomplete by ref, only be event it seems
            this.child.current.modifyMapObject(this.getCustomFormContent()) 
        };
        return (<div>
                    { ETToolUtils.buildTextfield((this.props.mapSelectedItem?.name || null ),this.formChangeCallback,c => (this.refTfName = c),"scene name") }<br/>
                    { ETToolUtils.buildAutocomplete(currentPhoto, this.formChangeCallbackSpecial, c => (this.refTfPhoto = c), "scene 360° photo", tourAssets,{width:300})}<br/>                    
                </div>);
    }

    render(){
        return (    
            <ETPointItemTool  ref={this.child} topic="Scene"
                    mapSelectedItem={this.props.mapSelectedItem}
                    getCustomFormContent={this.getCustomFormContent}
                    formNew={this.buildCustomForm(false)}
                    formEdit={this.buildCustomForm(true)}
                    mapLayer={this.props.mapLayer}
                    epicTourAppConfig={this.props.epicTourAppConfig}
                    postDeletionCallback={this.postDeletionCallback}
            />);
    }
}

const mapStateToProps = state => {
    return {
        map: new EpicTourMap(state.epicTour.map),        
        tourAssets: state.cloudFs.tourAssets,
    };
}

const mapDispatchToProps = dispatch => {
    return { 
        epicTourMapObjectRemove: (mapLayer,objectId) => dispatch(actions.epicTourMapObjectRemove(mapLayer,objectId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ETSceneTool);

