// @ts-check 

import { fabric } from 'fabric-with-gestures' 

import CanvasService from './CanvasService'

/**
 * handle selection,deselection,selectionability 
 *  but not the color drawing associated with it
 * 
 * selectionability depend on app, edit mode, etc. 
 * 
 */


class CanvasSelectionManager extends CanvasService{
    constructor(fabricCanvas, canvasCoordinatesConverter,onCanvasSelectionChange) {
        super(fabricCanvas, canvasCoordinatesConverter) ;
        this.onCanvasSelectionChange = onCanvasSelectionChange;

        this.setup();
        this.startListeningSelectionEvents();
    }

    stopListeningSelectionEvents = () => {
        this.isListeningSelectionEvents = false;
    }
    startListeningSelectionEvents = () => {
        this.isListeningSelectionEvents = true;
    }



    setup() {
        this.fabricCanvas.on({
            //TODO wont work on touchscreen! can have timeout on click, same idea really
            'mouse:over': function(e) {


                if( (e?.target?.id)  ){
                    var mapObject = this.map.getMapObjectForId(e.target.id);
                    if(!mapObject.note)
                        return;

                    var text = new fabric.Text(" "+mapObject.note+" ", {
                        fontFamily: 'Arial',
                        fontSize: 30,
                        fill:"white",
                        'backgroundColor':"black",
                        originX: 'left',
                        originY: 'top'
                    });
                    this.group = new fabric.Group([  text ], {
                         left: e.target.left+30,
                         top: e.target.top+30,
                         selectable : false,
                         visible: true,
                    });
                    this.fabricCanvas.add(this.group);
                    this.fabricCanvas.renderAll();
                }
              }.bind(this), 
              'mouse:out': function(e) {
                if( (e?.target?.id)  ){
                    var mapObject = this.map.getMapObjectForId(e.target.id);
                    if(!mapObject.note)
                        return;
                    this.fabricCanvas.remove(this.group);
                    this.fabricCanvas.renderAll();
                }
              }.bind(this), 


            'before:selection:cleared': function(e) {
                if(!this.isListeningSelectionEvents)
                    return;
                console.log("CanvasSelectionManager before:selection:cleared");
                this.onCanvasSelectionChange(null);
            }.bind(this),       
            'selection:updated': function(e) {
                if(!this.isListeningSelectionEvents)
                    return;
                console.log("CanvasSelectionManager selection:updated");
                e.target.set({'borderColor':'#00ff00','borderScaleFactor':3});
                this.handlePositiveSelectionEvent(e);
            }.bind(this), 
            'selection:created': function(e) {
                if(!this.isListeningSelectionEvents)
                    return;               
                console.log("CanvasSelectionManager selection:created");
                e.target.set({'borderColor':'#00ff00','borderScaleFactor':3});
                this.handlePositiveSelectionEvent(e);
            }.bind(this),                
        });
    }

    handlePositiveSelectionEvent(e){
        const selectedMapElementId = e.selected.length==1?e.selected[0].id:null; 
        if(selectedMapElementId)
            this.onCanvasSelectionChange(selectedMapElementId);
        else{
            //ideally should not happen 
            console.log("aborted selection");
            this.updateCanvasSelection(null);//we refuse background selection and the like 
        }
    }

    updateCanvasSelection(mapObjectId){
        if(mapObjectId==null){
            this.fabricCanvas._discardActiveObject();
            this.fabricCanvas.renderAll();
        }else{
            var selected = this.fabricCanvas.getObjects().filter(o=>o.id===mapObjectId);
            this.fabricCanvas._setActiveObject(selected[0]);// 1, no event firing - http://fabricjs.com/v2-breaking-changes-2
        }   
    }
}

export default CanvasSelectionManager;