
// @ts-check
import { APIS } from 'src/constants';
import axiosApi from 'src/utils/axios';


class ApiService {

    getFileListings = async (prefix,recursive,files,folders) => {
        const response = await axiosApi.get(APIS.FILELISTINGS+"?prefix="+prefix+"&recursive="+recursive);    
        return response.data.filter(f => (f.endsWith("/") && folders) || (!f.endsWith("/") && files)  );
    } 

    postTour = async (newFileName,content) => {
        console.log("postTour1");
        const response = await axiosApi.post(APIS.FILES+newFileName,content);
        console.log("postTour2");
        return response.status==200 || response.status==201 ;
    }
        
    deleteTour = async (tourName) => {
        const response = await axiosApi.delete(APIS.FILES+tourName);
        return response.status==200 || response.status==201 ;
    }
        
}

const apiService = new ApiService();
console.log("exporting apiService");
export default apiService;