// @ts-check 

import { fabric } from 'fabric-with-gestures' 

import ETDrawer from './ETDrawer'


class ETSegueDrawer extends ETDrawer {

    getStyle(){
        return {
            stroke: 'red',
            strokeWidth: 2
        }
    }

    buildCanvasObjects(inputObject,relatedMpaObjects){
        const point1M = this.canvasCoordinatesConverter.meterPointTofpxUnitPoint(relatedMpaObjects.scene1id);
        const point2M = this.canvasCoordinatesConverter.meterPointTofpxUnitPoint(relatedMpaObjects.scene2id);
        var structuralProperties = {
            originX:"center",
            originY:"center",            
            strokeUniform: true
        }; 
        return [new fabric.Line([ point1M.x, point1M.y, point2M.x,point2M.y], structuralProperties)];
    }   
};

export default ETSegueDrawer;