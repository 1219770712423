// @ts-check
import React, { useRef, useState } from 'react';
import { E3DTOOLMODE, Tool3D } from './Tool3D';
import { ETTwoLevelSelect } from 'src/components/form/CommonFormComponents';
import { SYMBOLS_TYPES_OPTIONSMAP } from 'src/constants';

const DEFAULT_SYMBOL_TYPE = "LONG_REACH";

export const Symbol3DTool = () => {
    const [symbolType, setSymbolType] = useState(DEFAULT_SYMBOL_TYPE);
    const symbolTypeRef = useRef(DEFAULT_SYMBOL_TYPE);
    const changeCallback = (e)=>{
        // @ts-ignore
        setSymbolType(symbolTypeRef.current.value);
    };
    return (<Tool3D componentType="climbing-symbol" mode={E3DTOOLMODE.SINGLE_ADD_SINGLE_POINT}currentRecord={ {symbolType:symbolType} }>
        <ETTwoLevelSelect label="Symbol Type" value={symbolType} changeCallback={changeCallback} refCallback={symbolTypeRef} optionsMap={SYMBOLS_TYPES_OPTIONSMAP}/>
    </Tool3D>);
};
