//@ts-check

import React, {
  useEffect,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import SplashScreen from 'src/components/SplashScreen';
import { setUserData, logout } from 'src/actions/accountActions';
import authService from 'src/services/authService';
import { useHistory } from 'react-router';

function Auth({ children }) {
  //to trigger redux action from function-based component 
  const dispatch = useDispatch();
  //declare a new state variable, set it to true and provide a setter that will presumably trigger a rerender if called. 
  const [isLoading, setLoading] = useState(true);
  const history = useHistory();

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    const initAuth = async () => {
      console.log("initAuth after landing on website");
      //pass a logout action, nice way to trigger it on all request which fails with 401/403
      authService.setAxiosInterceptors({
        onLogout: () => {
          console.log("Auth - axios interceptor, call fails, token problem, logging out and redirecting to anonymous section");
          dispatch(logout())
          history.push('/home');
        }
      });

      //this doesn't call it as such, is just check localstorage and possible add it to axios interceptor as bearer
      authService.handleAuthentication();

      //this uses the token we have already just to make a call to a /profile api and push profile response to redux
      //should trigger a logout if it fails with an expired token 
      if (authService.isAuthenticated() && window.location.href.indexOf("/app")!=-1) {//don't do that on a login callback or anonymous page...
        console.log("Auth loginInWithToken");
        const user = await authService.loginInWithToken();
        console.log("Auth dispatch(setUserData(user)");
        await dispatch(setUserData(user));
      }

      //just remove the spinner which was there for the OPTIONAL /profile call really
      setLoading(false);
    };

    //so presumably, this is called only once when landing on a page 
    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;
