
// @ts-check
import React, { useRef, useState } from 'react';
import { E3DTOOLMODE, Tool3D } from './Tool3D';
import { SIZES_OPTIONSMAP } from 'src/constants';
import { ETTwoLevelSelect } from 'src/components/form/CommonFormComponents';

const DEFAULT_SIZE = "M";

export const Chalk3DTool = () => {
    const [size, setSize] = useState(DEFAULT_SIZE);
    const sizeRef = useRef(DEFAULT_SIZE);
    const changeCallback = (e)=>{
        // @ts-ignore
        setSize(sizeRef.current.value);
    };

    return (<Tool3D componentType="climbing-chalk" mode={E3DTOOLMODE.MULTIPLE_ADD_SINGLE_POINT}currentRecord={ {size:size} }>
                <ETTwoLevelSelect label="Size" value={size} changeCallback={changeCallback} refCallback={sizeRef} optionsMap={SIZES_OPTIONSMAP}/>
    </Tool3D>);
};
