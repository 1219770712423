//@ts-check


import { EMap2DCategory,MapCategoryInfo } from './EpicTourMap'

import ETSceneDrawer from './drawers/ETSceneDrawer'
import ETGridDrawer from './drawers/ETGridDrawer'
import ETSegueDrawer from './drawers/ETSegueDrawer'
import ETBackgroundPhotoDrawer from './drawers/ETBackgroundPhotoDrawer'
import ETUtils from './utils/ETUtils'
import Player from './mapEntities/player'

/**
 * general permanent settings that configure the use of EpicTour 2d3d lib
 * for a given website, purpose, such as 'climbing website' 'tourism virtual tour', etc. 
 * 
 * it defines things such as the map layers allowed
 * 
 */

export const EModes = {
    VIEW: 'VIEW',
    EDIT: 'EDIT'
}

export const ELayerEditability = {
    NONE: 'NONE',
    SELECTION: 'SELECTION',    
    POSITION: 'POSITION',
    ALL: 'ALL',
}



export class ETModeConfig {
    constructor(initialVisibility, editability, zindex) {
        this.initialVisibility = initialVisibility;
        this.editability = editability;
        this.zindex = zindex;
    }
}




export class ETLayerConfig {
    constructor(mapCategory,mapSubType, drawerClass, modeConfigs) {
        this.mapCategory = mapCategory;
        this.mapSubType = mapSubType;
        this.drawerClass = drawerClass;
        this.modeConfigs = modeConfigs;
    }
}

//for transient/non-redux stuff
export class ETTransientLayerConfig {
    constructor(entityType,modeConfig) {
        this.entityType = entityType;
        this.modeConfig = modeConfig;
    }
}

export class ETRootUrls {
    constructor(assetsDynamicRootUrl, assetsStaticRootUrl, assetsToursRootUrl) {
        this.assetsDynamicRootUrl = assetsDynamicRootUrl;
        this.assetsStaticRootUrl = assetsStaticRootUrl;
        this.assetsToursRootUrl = assetsToursRootUrl;
    }
}


export class EpicTourAppConfig {

    constructor(initialMode, layersConfig,transientLayersConfig) {
        this.initialMode = initialMode; 
        this.layersConfig = layersConfig;
        this.transientLayersConfig = transientLayersConfig;
    }

    setRootUrls(rootUrls){
        this.rootUrls = rootUrls;  
    }

    getModeConfigForLayerAndMode(mapLayer,mode){
        return this.getLayerConfig(mapLayer).modeConfigs[mode];
    }

    getDrawerClassForLayer(mapLayer){
        return this.getLayerConfig(mapLayer).drawerClass;
    }
    getAllDrawerClasses(){
        return this.buildLayerFlatListFor((layerConfig)=>layerConfig.drawerClass);
    }

    getInitialVisibilityLayerTreeForMode(mode) {
        return this.buildLayerTreeFor( (layerConfig)=>layerConfig.modeConfigs[mode].initialVisibility );
    }

    getLayerConfig(mapLayer){
        return mapLayer.mapSubType ? this.layersConfig[mapLayer.mapCategory][mapLayer.mapSubType] : this.layersConfig[mapLayer.mapCategory];
    }    

    buildLayerFlatListFor(fieldSelector) {
        var l = [];
        for (const mapCategoryInfo of Object.values(EMap2DCategory)){
            if(!mapCategoryInfo.subTypes)
                l.push( fieldSelector(this.layersConfig[mapCategoryInfo.name]) );
            else
                for(const subType in this.layersConfig[mapCategoryInfo.name])
                    l.push( fieldSelector(this.layersConfig[mapCategoryInfo.name][subType]) );
        }
        return l;
    }

    buildLayerTreeFor(fieldSelector) {
        var visibleToggle = {};
        for (const mapCategoryInfo of Object.values(EMap2DCategory)){
            if(!mapCategoryInfo.subTypes){
                const layerConfig = this.layersConfig[mapCategoryInfo.name];
                visibleToggle[mapCategoryInfo.name] = fieldSelector(layerConfig);
            }
            else{
                var subTypesInfo = {} ;
                for(const subType in this.layersConfig[mapCategoryInfo.name]){
                    const subTypeLayerConfig = this.layersConfig[mapCategoryInfo.name][subType];
                    subTypesInfo[subType] = fieldSelector(subTypeLayerConfig);
                }
                visibleToggle[mapCategoryInfo.name] = subTypesInfo ; 
            }
        }
        return visibleToggle;
    }

    static getDefaultEpicTourAppConfig() {
        var layersConfig = {};

        layersConfig[EMap2DCategory.BACKGROUND.name] = new ETLayerConfig(EMap2DCategory.BACKGROUND.name, null, ETBackgroundPhotoDrawer, {
            [EModes.VIEW]: new ETModeConfig(true, ELayerEditability.NONE,0),
            [EModes.EDIT]: new ETModeConfig(true, ELayerEditability.NONE,0)
        });
        layersConfig[EMap2DCategory.GRID.name] = new ETLayerConfig(EMap2DCategory.GRID.name, null, ETGridDrawer, {
            [EModes.VIEW]: new ETModeConfig(false, ELayerEditability.NONE,100),
            [EModes.EDIT]: new ETModeConfig(true, ELayerEditability.NONE,100)
        });

        layersConfig[EMap2DCategory.SCENE.name] = new ETLayerConfig(EMap2DCategory.SCENE.name, null, ETSceneDrawer, {
            [EModes.VIEW]: new ETModeConfig(true,  ELayerEditability.POSITION,300),
            [EModes.EDIT]: new ETModeConfig(true,  ELayerEditability.POSITION,300)
        });
        layersConfig[EMap2DCategory.SEGUE.name] = new ETLayerConfig(EMap2DCategory.SEGUE.name, null, ETSegueDrawer, {
            [EModes.VIEW]: new ETModeConfig(false,  ELayerEditability.SELECTION,200),
            [EModes.EDIT]: new ETModeConfig(true,  ELayerEditability.SELECTION,200)
        });

        layersConfig[EMap2DCategory.ITEMS.name] = {}; 

        const playerClassName = ETUtils.getClassNameFromClass(Player);
        const transientLayersConfig = {};
        transientLayersConfig[playerClassName] = new ETTransientLayerConfig(playerClassName,new ETModeConfig(true, ELayerEditability.NONE,299));

        return new EpicTourAppConfig(EModes.VIEW, layersConfig, transientLayersConfig);
    }
}


