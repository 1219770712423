// @ts-check

import EpicTourMap from "./EpicTourMap";
import CanvasCoordinatesConverter from "./CanvasCoordinatesConverter";

import { fabric } from 'fabric-with-gestures' 


/**
 * a handy super class for the service class that
 * require access to fabricsCanvas, map and coordinate Service
 * 
 */
class CanvasService {

    /**
     * @param {any} fabricCanvas 
     * @param {CanvasCoordinatesConverter} canvasCoordinatesConverter
     */    
    constructor(fabricCanvas, canvasCoordinatesConverter) {
        this.fabricCanvas = fabricCanvas;
        this.canvasCoordinatesConverter = canvasCoordinatesConverter;
    }

    /**
     * @param {EpicTourMap} map - the level map
     */
    setMap(map){ 
        this.map = map ; 
    }    

    getCanvasObjectsForLayer(mapCategory,mapSubType){
        return this.fabricCanvas.getObjects().filter(o=> o.mapCategory===mapCategory && (mapSubType==null || o.mapSubType === mapSubType) );
    }

    getCanvasObjectsForId(id){
        return this.fabricCanvas.getObjects().filter(o=> o.id === id );
    }    

}

export default CanvasService;