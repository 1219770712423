
// @ts-check
import React, { useEffect } from 'react';
import { BridgeFacade } from 'src/components/epictour/CanvasToAframeBridge';


/**
 * @return {BridgeFacade} bridge
 */
function getBridgeContentEraserAccess(){
    // @ts-ignore
    return window.epicTourComponent.canvasWrapper.canvasToAframeBridge.contentEraserAccess;
}

export const Eraser3DTool = () => {
    useEffect(() => {
        console.log("Eraser3DTool useEffect ");
        getBridgeContentEraserAccess().call("on");        
        return () => {//unmount
            getBridgeContentEraserAccess().call("off");
        }
    },[]);

    return (<div>
        <hr/>
    </div>);
};
