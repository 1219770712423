// @ts-check

import React, { useState } from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import Page from 'src/components/Page';
import { logout } from 'src/actions/accountActions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import EnhancedTable from './GenericTable';
import { APIS } from 'src/constants';
import apiService from 'src/services/apiService'
import axiosNonApi from 'axios';
import { SettingsBluetoothSharp } from '@material-ui/icons';
import { useEffect } from 'react';
import _ from 'lodash';
import ExternalConfig from 'src/utils/ExternalConfig';

import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

const useStyles = makeStyles((theme) => ({
  root: {},
  important:{ 
      color:theme.palette.secondary.main,
      fontWeight:900
    }
}));

function TourNameCellDrawer (props) {
  return (<div>{props.row.name}</div>)
}


function Dashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  // @ts-ignore
  const user = useSelector((state) => state.account.user);
  // @ts-ignore
  const externalConfig = useSelector((state) => new ExternalConfig(state.externalConfig));

  const [tours, setTours] = useState([]);

  useEffect(() => {
    handleRefresh();
  },[]);//[] only on mount, not update!, be very careful to avoid pricey lambda calls infinite loops!!!!

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      //window.location.href = "https://epictourstudio.auth.eu-west-2.amazoncognito.com/login?response_type=token&client_id=6iu40qvrlaq1qc4mjh5rvr82su&redirect_uri=https://epictourstudio.com/escapegamestudio";
    } catch (error) {
      console.log("error "+error);
    }
    console.log("login widget - logout button pressed, redirecting to anonymous section");
    history.push('/');
  };

  const handleRefresh = async () => {
      console.log("handleRefresh");
    var files = await apiService.getFileListings("tours/",false,true,false);    
    setTours(files);
  }

  const handleAdd = async () => {
    var newFileName = prompt("Please enter the new tour name");
    if(!newFileName)
      return;    
    console.log("handleAdd1");
    const success = await apiService.postTour(newFileName,"{}");
    console.log("handleAdd2"+success);
    //await axiosApi.post(APIS.FILES+newFileName,"{}");
    var newTours = _.cloneDeep(tours);
    newTours.push(newFileName);
    setTours(newTours);
    alert("new Tour created");
  }

  const handleEdit = async (existingTourName) => {
    history.push("/app/escapegamestudio/"+existingTourName)
  }  

  const handlePlay = async (existingTourName) => {
    history.push("/app/escapegame/"+existingTourName)
  }  

  const handleDuplicate = async (existingTourName) => {
    const existingTourContentResponse = await axiosNonApi.get(externalConfig.getToursRootUrl()+existingTourName);
    var newFileName = prompt("Please enter the new tour name for the duplicate of "+existingTourName);
    if(!newFileName)
      return;
    await apiService.postTour(newFileName,existingTourContentResponse.data);
    var newTours = _.cloneDeep(tours);
    newTours.push(newFileName);
    setTours(newTours);
    alert("tour duplicated");
  }

  const handleDelete = async (tourNames) => {
    var confirmation = prompt("Are you sure you want to delete '"+tourNames+"'? Please type YES to confirm");
    if(confirmation!=="YES")
        return;
    var newTours = _.cloneDeep(tours);
    for(const tourName of tourNames){
        await apiService.deleteTour(tourName);
        newTours.splice(newTours.indexOf(tourName), 1);
    }
    setTours(newTours);        
    alert("tours deleted");
  }

  const cellsInfos = [
    {id:"name",label:"name",numeric:false,drawerBuilder: (row)=> {return (<TourNameCellDrawer row={row}/>)} }
  ];
  const tableActions = [
    { label:"Add",handler:handleAdd,icon:<AddBoxIcon/>},
  ];
  const selectedRowsActions = [
    { label:"Delete",handler:handleDelete,icon:<DeleteIcon/>},
  ];
  const rowActions = [
    { label:"Play",handler:handlePlay,icon:<PlayCircleFilledIcon/>},
    { label:"Edit",handler:handleEdit,icon:<EditIcon/>},
    { label:"Duplicate",handler:handleDuplicate,icon:<FileCopyIcon/>},
  ];

  const rows = tours.map((tour)=>{return {name:tour,id:tour}});

  return (
    <
// @ts-ignore
    Page
      
      title="Dashboard!"
    >
        <div style={{float:"right",display:"flex",alignItems: 'center'}}>
       <Typography>Logged in as&nbsp;</Typography><Typography className={classes.important}>{user.username}</Typography>
      <Button color="primary" 
                onClick={handleLogout}
                  >
                  logout
                </Button>
           
        </div>

                <Typography
          variant="h3"
          color="textPrimary"
        >
          Epic Tour Studio - Dashboard
        </Typography>     

        <Box mt={3}>
        <Typography
            variant="body1"
            color="textPrimary"
        >
            Here is the list of existing tours. You can add more or edit, duplicate or delete the existing ones. 
        </Typography>
        </Box>

        <EnhancedTable title="Tours" 
            dense={false}
            rows={rows}
            tableActions={tableActions}
            selectedRowsActions={selectedRowsActions}
            rowActions={rowActions}
            cellsInfos={cellsInfos}
        />
    </Page>
  );
}

export default Dashboard;
