

// @ts-check

import React, { Component } from 'react';
import ETPointItemTool from 'src/components/epictour/tools/ETPointItemTool';
import ETToolUtils from 'src/components/epictour/tools/ETToolUtils';
import ETPathItemTool from 'src/components/epictour/tools/ETPathItemTool';

class ETClimbingRouteTool extends React.Component {

    constructor(props){
        super(props);
        this.child = React.createRef();
    }

    getCustomFormContent = () => {
        return {note:this.refTfNote.value};
    }
    
    buildCustomForm = (edit) => {
        this.formChangeCallback = () => { this.child.current.modifyMapObject(this.getCustomFormContent()) };
        return (<div>
                    { ETToolUtils.buildTextfield((this.props.mapSelectedItem?.note || null ),this.formChangeCallback,c => (this.refTfNote = c),"note")}
                </div>);
    }

    render(){
        const toolDrawingProperties = {
            temp:true,
            color:'orange',
            width:8
        };
        return (<ETPathItemTool  ref={this.child} topic="Climbing route"
                    mapSelectedItem={this.props.mapSelectedItem}
                    getCustomFormContent={this.getCustomFormContent}
                    formNew={this.buildCustomForm(false)}
                    formEdit={this.buildCustomForm(true)}
                    mapLayer={this.props.mapLayer}
                    epicTourAppConfig={this.props.epicTourAppConfig}
                    toolDrawingProperties={toolDrawingProperties}
            />);
    }
}
export default ETClimbingRouteTool;